import { DateTimeFormatter, Yup, components, useServices } from 'cng-web-lib'
import React, { useContext, useEffect, useRef } from 'react'

import Box from '@material-ui/core/Box'
import DeleteButton from 'src/components/button/DeleteButton.js'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import EditContactDetailsContext from './EditContactDetailsContext.js'
import EditButton from 'src/components/button/EditButton.js'
import { Grid } from '@material-ui/core'
import check from 'check-types'
import moment from 'moment'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import ResetButton from 'src/components/button/ResetButton'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'
import AddressBookDialog from '../../../shared/dialog';
import ViewCompanyContactDetailsComponent from './ViewCompanyContactDetailsComponent';
import ConnectedPartyTemplateComponent from '../ConnectedPartyTemplateComponent';
import useAddCompanyContactValidationSchema from './useAddCompanyContactValidationSchema'


const {
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  button: { CngSecondaryButton }
} = components

function ViewContactForm(props) {
  const { setFieldValue } = useFormikContext()
  const dialogContext = useContext(EditContactDetailsContext)

  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting },
    currentContact,
    editContact,
    openDialog,
    setPreviousDialog
  } = dialogContext

  function onSubmit(data) {
    console.log('submit ')
    console.log(data)
    console.log(currentContact)

    closeDialog()
  }

  const validationSchema = useAddCompanyContactValidationSchema()

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          currentContact={currentContact}
          editContact={editContact}
          openDialog={openDialog}
          setPreviousDialog={setPreviousDialog}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({ closeDialog, isSubmitting, currentContact, editContact, openDialog, setPreviousDialog }) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const freightBookingTranslationObject = FreightBookingTranslationText()
  const companyPreferenceTranslationObject = CompanyPreferenceTranslationText()
  const { setFieldValue } = useFormikContext()
  const isEdit = useRef(false)
  const resetForm = () => {
    console.log('reset')
    console.log(DEFAULT_INITIAL_VALUES)

    const dataArr = Object.entries(DEFAULT_INITIAL_VALUES)
    dataArr.forEach(([key, val]) => {
      console.log("key: " + key + " val: " + val)
      setFieldValue(key, val)
    })
  }

  useEffect(() => {
    console.log('in dialog now')
    console.log(currentContact)
    if (currentContact != null) {
      populateViewFields()
    }
  }, [currentContact])

  function populateViewFields() {
    const dataArr = Object.entries(currentContact)
    dataArr.forEach(([key, val]) => {
      // console.log("key: " + key + " val: " + val)
      setFieldValue(key, val)
    })

    isEdit.current = true
  }

  function renderConnectedParty() {
    if (currentContact) {
      if (currentContact.category.categoryCode == 'CONSIGNEE' ||
        currentContact.category.categoryCode == 'NOTIFY PARTY' ||
        currentContact.category.categoryCode == 'SHIPPER')
        return (
          <Box pt={5}>
            <ConnectedPartyTemplateComponent.FormBody
              disabled
              currentContact={currentContact} />
          </Box>

        );
    }
  }

  const { errors } = useFormikContext()
  return (
    <Box m={1.5}>

      <ViewCompanyContactDetailsComponent.FormBody
        currentContact={currentContact}
      />

      {renderConnectedParty()}


      <Box display='flex' mt={2}>
        <Grid xs={6} sm={6} justify='flex-start'>
          <CngSecondaryButton onClick={() =>{
            setPreviousDialog(null);
            closeDialog(false);
          }}>
            {uiTranslatedTextsObject.goBack}
          </CngSecondaryButton>
        </Grid>

        <Grid container xs={6} sm={6} justify='flex-end'>
          <Box pr={2}>
            <DeleteButton onClick={(e) => {
              closeDialog(true);
              openDialog(AddressBookDialog.DELETE_CONTACT_CONFIRM, e, currentContact)
              setPreviousDialog(AddressBookDialog.VIEW_CONTACT_DIALOG);

            }} disabled={isSubmitting} />
          </Box>
          <Box>
            <EditButton onClick={editContact} disabled={isSubmitting} />

            {/* <SubmitButton isSubmitting={isSubmitting}>
              {companyPreferenceTranslationObject.saveContact}
            </SubmitButton> */}
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  // ...CompanyContactDetailsComponent.initialValues,
  ...ConnectedPartyTemplateComponent.initialValues
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

export default ViewContactForm
