import React, { useContext, useEffect, useState } from 'react'
import { Grid, Box, Card, Typography } from '@material-ui/core'
import { useServices, components } from 'cng-web-lib'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

import CircularProgress from '@material-ui/core/CircularProgress'
import DiscardButton from 'src/components/button/DiscardButton.js'

import CountrySelectAutoComplete from 'src/views/common/ui/CountrySelectAutoComplete'

import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls'
import EditContactDetailsContext from './EditContactDetailsContext.js'
import LabelValueVertical from '../LabelValueVertical.js'

import CompanyContactDetailsComponent from './CompanyContactDetailsComponent'
import ConnectedPartyTemplateComponent from '../ConnectedPartyTemplateComponent'
import useAddCompanyContactValidationSchema from './useAddCompanyContactValidationSchema'
import { useFormContext } from 'react-hook-form'

const {
  table: { useDefaultNotification },
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField }
  },
  button: { CngPrimaryButton },
  CngGridItem
} = components

function AddContactSIForm(props) {
  const dialogContext = useContext(EditContactDetailsContext)

  const { createRecord, fetchRecords } = useServices()

  const { closeDialog, showNotification, contactType, doRefresh } =
    dialogContext

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmitAddContact(data) {
    console.log('submitData', data)

    fetchRecords.execute(
      CompanyPreferenceApiUrls.COMPANY_CONTACT_SEARCH_CRITERIA,
      {
        filterText: '',
        dateRange: 0,
        statusCode: [1],
        addressStatus: [],
        category: [],
        namePrefix: ''
      },
      (searchData) => {
        console.log('data', searchData)
        data.partyId = searchData.partyId

        createRecord.execute(
          CompanyPreferenceApiUrls.ADD_CONTACT,
          data,
          onSuccess,
          onError
        )
      },
      onError
    )
    closeDialog()

    function onSuccess(response) {
      console.log(response)
      showSuccessNotification('Contact Saved')
      props.setSubmitting(false)
      doRefresh()
    }

    function onError(error) {
      console.log(error)
      showErrorNotification('Error - ' + error)
      props.setSubmitting(false)
    }
  }

  const validationSchema = useAddCompanyContactValidationSchema()

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues
        },
        validationSchema: validationSchema,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={props.isSubmitting}
          setSubmitting={props.setSubmitting}
          onSubmitAddContact={onSubmitAddContact}
          contactType={contactType}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({
  closeDialog,
  setSubmitting,
  isSubmitting,
  onSubmitAddContact,
  contactType
}) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const companyPreferenceTranslationObject = CompanyPreferenceTranslationText()
  const { setFieldValue, errors } = useFormikContext()
  const { getValues, trigger } = useFormContext()
  const [validateDone, setValidateDone] = useState(false)

  const [countryCodeField, ,] = useField('countryCode')

  useEffect(() => {
    setFieldValue('category', contactType)
    setFieldValue('addressStatus', 'Active')
  }, [])

  useEffect(() => {
    if (validateDone) {
      if (errors && Object.keys(errors).length > 0) {
        setSubmitting(false)
        setValidateDone(false)
      } else {
        let fieldValues = { ...getValues() }
        onSubmitAddContact(fieldValues)
        setValidateDone(false)
      }
    }
  }, [validateDone])

  function validateFields() {
    console.log('validate fields')
    setSubmitting(true)
    trigger()
    setTimeout(() => {
      setValidateDone(true)
    }, 500)
  }

  function sortContactType() {
    if (contactType == 'CONSIGNEE') {
      return companyPreferenceTranslationObject.addNewConsignee
    } else if (contactType == 'SHIPPER') {
      return companyPreferenceTranslationObject.addNewShipper
    } else if (contactType == 'NOTIFY PARTY') {
      return companyPreferenceTranslationObject.addNewNotifyParty
    } else if (contactType == 'REPRESENTATIVE') {
      return companyPreferenceTranslationObject.addNewRepresentative
    }
  }

  return (
    <Box m={1.5}>
      <Card className='inner-card-group'>
        <Box mb={1}>
        <Grid container xs={12} sm={12} spacing={2}>
          <CngGridItem>
            <Typography style={{ color: '#000', fontWeight: 'bold' }}>
              {sortContactType()}
            </Typography>
            <Typography variant='caption' className='page-content-groupLabel'>
              {uiTranslatedTextsObject.mandatoryFields}
            </Typography>
          </CngGridItem>

          <Grid container xs={12} sm={12} spacing={2} justify='space-between'>
            <CngGridItem xs={4} sm={4}></CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <LabelValueVertical
                label={companyPreferenceTranslationObject.category}
                value={contactType}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <LabelValueVertical
                boxClass={'ab-bg-active'}
                label={uiTranslatedTextsObject.status}
                value={<div className={'ab-text-green'}>Active</div>}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}></CngGridItem>
            <CngGridItem xs={8} sm={8}>
              <CngTextField
                type='text'
                name='name'
                label={companyPreferenceTranslationObject.contactName + ' *'}
                inputProps={{ maxLength: 46 }}
              />
            </CngGridItem>

            <CngGridItem xs={4} sm={4}></CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngTextField
                type='text'
                name='addressEmail'
                label={companyPreferenceTranslationObject.emailId}
                inputProps={{
                  maxLength: 100
                }}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngTextField
                type='text'
                name='addressContactNo'
                inputProps={{ maxLength: 100 }}
                label={companyPreferenceTranslationObject.contactNo}
              />
            </CngGridItem>

            <CngGridItem xs={4} sm={4}></CngGridItem>
            <CngGridItem xs={8} sm={8}>
              <CngTextField
                type='text'
                name='address'
                label={companyPreferenceTranslationObject.address}
                inputProps={{
                  maxLength: 512
                }}
                multiline
                rows={4}
              />
            </CngGridItem>

            <CngGridItem xs={4} sm={4}></CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngTextField
                type='text'
                name='state'
                inputProps={{ maxLength: 100 }}
                label={companyPreferenceTranslationObject.state}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CountrySelectAutoComplete
                name='countryCode'
                label={companyPreferenceTranslationObject.country}
                //keyRef={countryCodeRef}
                value={countryCodeField.value}
              />
            </CngGridItem>

            <CngGridItem xs={8} sm={8}></CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngTextField
                type='text'
                name='postalCode'
                inputProps={{ maxLength: 20 }}
                label={companyPreferenceTranslationObject.postalCode}
              />
            </CngGridItem>
          </Grid>
        </Grid>
        </Box>
      </Card>

      <Box display='flex' mt={2} mr={2}>
        <Grid container justify='flex-end'>
          <Box pr={2}>
            <DiscardButton onClick={closeDialog} disabled={isSubmitting} />
          </Box>
          <Box>
            <CngPrimaryButton disabled={isSubmitting} onClick={validateFields}>
              {uiTranslatedTextsObject.submit}
              {isSubmitting && (
                <Box mx={0.5}>
                  <CircularProgress color='primary' size={10} />
                </Box>
              )}
            </CngPrimaryButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...CompanyContactDetailsComponent.initialValues,
  ...ConnectedPartyTemplateComponent.initialValues
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

export default AddContactSIForm
