import React, { } from 'react'
import { useHistory } from 'react-router-dom'

import AddressBookNewForm from './AddressBookNewForm';
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_CompanyPreference'

const {
  form: { CngViewForm }
} = components

function AddressBookPage({ showNotification }) {
  const history = useHistory()

  function onSubmit(data){
    // history.push('/')
    history.replace({ pathname: pathMap.ADDRESS_BOOK_PATH })
  }

  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <AddressBookNewForm.FormFields
          disabled={false}
          showNotification={showNotification}
          isCarrier={false}
        />
      }
      formikProps={{
        ...AddressBookNewForm.formikProps,
        onSubmit: onSubmit
      }}
    />
  )
}

export default AddressBookPage
