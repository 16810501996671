import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls';

function fetchCompanyContactSearchCriteria(
    fetchRecords,
    searchCriteria,
    onSuccess,
    onError
) {
    console.log('fetchCompanyContactSearchCriteria')
    console.log(searchCriteria)
    fetchRecords.execute(
        CompanyPreferenceApiUrls.COMPANY_CONTACT_SEARCH_CRITERIA,
        {
            customData: searchCriteria
        },
        (data) => {
            onSuccess(data)
        },
        (error) => {
            onError(error)
        }
    )
}

function fetchCompanyContactSearch(
    fetchPageableRecords,
    searchCriteria,
    onSuccess,
    onError
) {
    console.log('fetchCompanyContactSearch')
    console.log(searchCriteria)
    fetchPageableRecords.execute(
        CompanyPreferenceApiUrls.COMPANY_CONTACT_SEARCH,
        searchCriteria,
        (data) => {
            onSuccess(data)
        },
        (error) => {
            onError(error)
        }
    )
}

function fetchBulkUploadSearchCriteria(
    fetchRecords,
    searchCriteria,
    onSuccess,
    onError
) {
    console.log('fetchBulkUploadSearchCriteria')
    console.log(searchCriteria)
    fetchRecords.execute(
        CompanyPreferenceApiUrls.BULK_UPLOAD_SEARCH_CRITERIA,
        {
            customData: searchCriteria
        },
        (data) => {
            onSuccess(data)
        },
        (error) => {
            onError(error)
        }
    )
}

function fetchBulkUploadSearch(
    fetchRecords,
    searchCriteria,
    onSuccess,
    onError
) {
    console.log('fetchBulkUploadSearch')
    console.log(searchCriteria)
    fetchRecords.execute(
        CompanyPreferenceApiUrls.BULK_UPLOAD_SEARCH,
        {
            customData: searchCriteria
        },
        (data) => {
            onSuccess(data)
        },
        (error) => {
            onError(error)
        }
    )
}

function fetchPartyTemplateSearchCriteria(
    fetchRecords,
    searchCriteria,
    onSuccess,
    onError
) {
    console.log('fetchPartyTemplateSearchCriteria')
    console.log(searchCriteria)
    fetchRecords.execute(
        CompanyPreferenceApiUrls.PARTY_TEMPLATE_SEARCH_CRITERIA,
        {
            customData: searchCriteria
        },
        (data) => {
            onSuccess(data)
        },
        (error) => {
            onError(error)
        }
    )
}

function fetchPartyTemplateSearch(
    fetchRecords,
    searchCriteria,
    onSuccess,
    onError
) {
    console.log('fetchPartyTemplateSearch')
    console.log(searchCriteria)
    fetchRecords.execute(
        CompanyPreferenceApiUrls.PARTY_TEMPLATE_SEARCH,
        {
            customData: searchCriteria
        },
        (data) => {
            onSuccess(data)
        },
        (error) => {
            onError(error)
        }
    )
}

function fetchPartyTemplateDropdownList(
    fetchRecords,
    searchCriteria,
    onSuccess,
    onError
) {
    console.log('fetchPartyTemplateDropdownList')
    console.log(searchCriteria)
    fetchRecords.execute(
        CompanyPreferenceApiUrls.PARTY_TEMPLATE_GET_DROPDOWNS,
        {
            customData: searchCriteria
        },
        (data) => {
            onSuccess(data)
        },
        (error) => {
            onError(error)
        }
    )
}

const AddressBookApi = Object.freeze({
    fetchCompanyContactSearchCriteria,
    fetchCompanyContactSearch,
    fetchBulkUploadSearchCriteria,
    fetchBulkUploadSearch,
    fetchPartyTemplateSearchCriteria,
    fetchPartyTemplateSearch,
    fetchPartyTemplateDropdownList
})

export default AddressBookApi;