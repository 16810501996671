import { Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'

import CompanyPreferenceTranslationText from '../../../shared/CompanyPreferenceTranslationText';
import { components } from 'cng-web-lib'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngRadioGroupField }
  },
  CngGridItem
} = components

const DateRangeFilterComponent = (props) => {
  const [, , { setValue: setDateRange }] = useField('dateRange')
  //  const [, , { setValue: setPollingId }] = useField('pollingId')
  const translatedTextsObject = CompanyPreferenceTranslationText()
  const dateRangeOptions = [
    { label: translatedTextsObject.dateFilterAll, value: '0' },
    { label: translatedTextsObject.dateFilterDay15, value: '15' },
    { label: translatedTextsObject.dateFilterDay30, value: '30' },
    { label: translatedTextsObject.dateFilterDay60, value: '60' }
  ]

  const { submitForm } = useFormikContext()

  useEffect(() => {
    console.log(props.dateRange)
    setDateRange(props.dateRange)
  }, [])

  return (
    <Grid container='true' item class={'rs-filter-card'}>
      <div className={'rs-bg-grey'}>
        <Grid container='true' item md={12}>
          <CngGridItem md={6}>
            <Typography className={'rs-filter-subheader'}>
              {props.title}
            </Typography>
          </CngGridItem>
        </Grid>
      </div>
      <CngRadioGroupField
        name='dateRange'
        options={dateRangeOptions}
        direction='column'
        onChange={(e) => {
          //  setPollingId('')
          console.log(e);
          props.dateRangeChange(e)
          //submitForm()
        }}
      />
    </Grid>
  )
}

export default DateRangeFilterComponent
