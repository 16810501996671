import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef
} from 'react'
import {
  Card,
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import moment from 'moment'

import CompanyPreferenceTranslationText from '../../../shared/CompanyPreferenceTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls'

import DiscardButton from 'src/components/button/DiscardButton.js'

import EditPartyTemplateContext from './EditPartyTemplateContext'
import AlphanumericFilterComponent from '../filters/AlphanumericFilterComponent'
import TabSearchResultFilter from '../filters/TabSearchResultFilter'
import FilterNoResult from '../filters/FilterNoResult'
import FilterClearButtonComponent from '../filters/FilterClearButtonComponent'

const {
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  table: { useDefaultNotification },
  button: { CngPrimaryButton },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  selectedTemplate: 0,
  selectedConsignee: 0,
  selectedShipper: 0,
  selectedNotifyParty: 0
})

const initialValues = Object.freeze({
  ...DEFAULT_INITIAL_VALUES
})

function PartyTemplateSelectionForm(props) {
  const dialogContext = useContext(EditPartyTemplateContext)

  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting },
    handleDialogSubmit
  } = dialogContext

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues
        },
        //onSubmit: handleDialogSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          handleDialogSubmit={handleDialogSubmit}
          showNotification={showNotification}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}
function FormBody({
  closeDialog,
  isSubmitting,
  handleDialogSubmit,
  showNotification
}) {
  const { setFieldValue } = useFormikContext()
  const { error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const translatedTextsObject = CompanyPreferenceTranslationText()
  const calistaUiTextObject = CalistaUiComponentTranslationText()

  const [loading, setLoading] = useState(false)
  const observer = useRef()
  const [hasMore, setHasMore] = useState(false)
  const [innerLoading, setInnerLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const pageSize = 10

  const { fetchPageableRecords } = useServices()

  const [nameIndex, setNameIndex] = useState(0)
  const [alphaFilterEnable, setAlphaFilterEnable] = useState([])

  const [currentCount, setCurrentCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [partyTemplateList, setPartyTemplateList] = useState([]) // stores records currently shown to user

  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [, , { setValue: setSelectedTemplateField }] =
    useField('selectedTemplate')

  const [filterTextField, , { setValue: setFilterTextField }] =
    useField('filterText')

  const [searchCriteria, setSearchCriteria] = useState({})

  const [selectedTemplateData, setSelectedTemplateData] = useState(null)

  const [sortList] = useState([
    {
      value: 'Name_A-Z',
      text: 'Name (A-Z)'
    },
    {
      value: 'Name_Z-A',
      text: 'Name (Z-A)'
    },
    {
      value: 'Date_N-O',
      text: 'Newest to Oldest'
    },
    {
      value: 'Date_O-N',
      text: 'Oldest to Newest'
    }
  ])

  useEffect(() => {
    console.log(searchCriteria)

    if (pageNumber > 0) {
      setInnerLoading(true)
      ProgressiveLoading()
    }
  }, [pageNumber])

  useEffect(() => {
    if (Object.keys(searchCriteria).length > 0) {
      getFilterContacts()
    }
  }, [searchCriteria])

  useEffect(() => {
    console.log('enter First Load')

    setLoading(true)
    setFieldValue('sortCode', 'Name_A-Z')

    let tempSearch = {
      filterText: '',
      dateRange: 0,
      namePrefix: '',
      isPopulated: true,
      activeOnly: true,
      sortType: 'Name_A-Z'
    }

    setSearchCriteria(tempSearch)

    fetchPageableRecords.execute(
      CompanyPreferenceApiUrls.PARTY_TEMPLATE_SEARCH,
      {
        filters: [],
        sorts: [],
        page: 0,
        pageSize: 1000000,
        customData: tempSearch
      },
      (data) => {
        console.log(data)
        let alphaFilter = []

        data.content.map((e, i) => {
          if (e.tmpltName != null) {
            if (!alphaFilter.includes(e.tmpltName.toUpperCase().charAt(0))) {
              alphaFilter.push(e.tmpltName.toUpperCase().charAt(0))
            }
          }
        })
        console.log('alphafilte: ' + alphaFilter)
        setAlphaFilterEnable(alphaFilter)
      },
      (error) => {
        console.log(error)
        showErrorNotification(error)
        setLoading(false)
      }
    )
  }, [])

  function getFilterContacts() {
    setInnerLoading(true)
    setPageNumber(0)

    fetchPageableRecords.execute(
      CompanyPreferenceApiUrls.PARTY_TEMPLATE_SEARCH,
      {
        filters: [],
        sorts: [],
        page: 0,
        pageSize: pageSize,
        customData: searchCriteria
      },
      (data) => {
        let partyList = data.content
        console.log('data returned: ' + JSON.stringify(partyList))

        if (partyList != null && partyList.length < pageSize) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }

        setPartyTemplateList(partyList)
        setCurrentCount(partyList.length)
        setTotalCount(data.totalElements)
        setInnerLoading(false)
        setLoading(false)
      },
      (error) => {
        console.log(error)
        showErrorNotification(error)
        setInnerLoading(false)
        setLoading(false)
      }
    )
  }

  function ProgressiveLoading() {
    console.log('ProgressiveLoading')
    fetchPageableRecords.execute(
      CompanyPreferenceApiUrls.PARTY_TEMPLATE_SEARCH,
      {
        filters: [],
        sorts: [],
        page: pageNumber,
        pageSize: pageSize,
        customData: searchCriteria
      },
      (data) => {
        console.log(data.content)

        if (data.content.length < pageSize) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }

        let temp = [...partyTemplateList]
        console.log(temp)
        let temp2 = temp.concat(data.content)
        console.log(temp2)

        setPartyTemplateList(temp2)
        setCurrentCount(temp2.length)
        setInnerLoading(false)
      },
      (error) => {
        console.log(error)
        showErrorNotification('Error loading more records.')
      }
    )
  }

  const switchAlphabet = (e) => () => {
    setNameIndex(e)
    let tempCriteria = { ...searchCriteria }
    tempCriteria.namePrefix = alphabetProcessing(e)
    setSearchCriteria(tempCriteria)
  }

  function alphabetProcessing(index) {
    let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    if (index == 0) {
      return ''
    } else {
      return alphabet[index - 1]
    }
  }

  const handleFilterTextChange = (e, isButtonClick) => {
    if (isButtonClick) {
      console.log('FilterTextField: ' + JSON.stringify(filterTextField))
    } else {
      let newFilterText = e.target.value
      console.log('enter else')
      let tempCriteria = { ...searchCriteria }
      tempCriteria.filterText = newFilterText
      setSearchCriteria(tempCriteria)
      setFilterTextField(newFilterText)
    }
  }

  const clearFilterText = () => {
    let tempCriteria = { ...searchCriteria }
    tempCriteria.filterText = ''
    setSearchCriteria(tempCriteria)
    setFilterTextField('')
  }

  function handleSortByChange(e) {
    if (e.target.value != null && e.target.value != 0) {
      let tempCriteria = { ...searchCriteria }
      tempCriteria.sortType = e.target.value
      setSearchCriteria(tempCriteria)
    }
  }

  function handleSelectTemplate(e, template) {
    console.log('handlehandleRowClickSelectTemplate')
    if (template == undefined) {
      return
    }
    setSelectedTemplate(template.id)
    setSelectedTemplateField(template.id)

    let tempInfo =
      selectedTemplateData == null ? {} : { ...selectedTemplateData }

    tempInfo.selectedConsignee = template.conId
    tempInfo.selectedConsigneeName = template.conName
    tempInfo.selectedConsigneeAddress = template.conAddress
    tempInfo.selectedShipper = template.shpId
    tempInfo.selectedShipperName = template.shpName
    tempInfo.selectedShipperAddress = template.shpAddress
    tempInfo.selectedNotifyParty = template.dstId
    tempInfo.selectedNotifyPartyName = template.dstNotifyName
    tempInfo.selectedNotifyPartyAddress = template.dstNotifyAddress

    setSelectedTemplateData(tempInfo)
  }

  const lastBookElementRef = useCallback(
    (node) => {
      if (innerLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          console.log(hasMore)
        }
      })
      if (node) observer.current.observe(node)
    },
    [innerLoading, hasMore]
  )

  if (loading) {
    return (
      <div style={{ display: loading ? 'inline' : 'none' }}>
        <Grid container justify='center'>
          <CngGridItem>
            <CircularProgress />
          </CngGridItem>
        </Grid>
      </div>
    )
  } else {
    return (
      <Box>
        <Card>
          <Box p={2}>
            <Box className='modal-sticky-top '>
              <TabSearchResultFilter
                resultCount={currentCount}
                totalCount={totalCount}
                title={translatedTextsObject.partyTemplate}
                filterText={filterTextField.value}
                handleFilterTextChange={handleFilterTextChange}
                clearFilterText={clearFilterText}
                sortByList={sortList}
                handleSortByChange={handleSortByChange}
              />
              <AlphanumericFilterComponent.FormBody
                loading={loading}
                switchAlphabet={switchAlphabet}
                enableAlphabet={alphaFilterEnable}
                nameIndex={nameIndex}
              />

              {partyTemplateList != null && partyTemplateList.length > 0 && (
                <Box mt={1}>
                  <Grid container xs={12}>
                    <Grid item xs={1} className={'ab-table-cell-header'}></Grid>
                    <Grid item xs={7} className={'ab-table-cell-header'}>
                      {calistaUiTextObject.templateName.toUpperCase()}
                    </Grid>
                    <Grid item xs={3} className={'ab-table-cell-header'}>
                      {calistaUiTextObject.creationDate.toUpperCase()}
                    </Grid>
                    <Grid item xs={1} className={'ab-table-cell-header'}></Grid>
                  </Grid>
                </Box>
              )}
            </Box>
            {partyTemplateList == null || partyTemplateList.length < 1 ? (
              <FilterNoResult
                title={translatedTextsObject.noResultsFilterTitle}
                desc={translatedTextsObject.noResultsFilterDesc}
                buttonPanel={
                  <FilterClearButtonComponent
                    handleResetFilter={clearFilterText}
                  />
                }
              />
            ) : (
              <Box className='modal-content'>
                <RadioGroup
                  name='selectedTemplate'
                  id='selectedTemplate'
                  value={selectedTemplate}
                >
                  <Grid container>
                    {partyTemplateList !== null &&
                      partyTemplateList.map((c, index) => (
                        <div
                          key={index}
                          ref={lastBookElementRef}
                          class='div-flex'
                        >
                          <PartyTemplateRow
                            key={c.name}
                            row={c}
                            rowClick={(e) => handleSelectTemplate(e, c)}
                            selected={selectedTemplate}
                          />
                        </div>
                      ))}
                  </Grid>
                  <div style={{ display: innerLoading ? 'inline' : 'none' }}>
                    <Grid container justify='center'>
                      <CngGridItem>
                        <CircularProgress />
                      </CngGridItem>
                    </Grid>
                  </div>
                </RadioGroup>
              </Box>
            )}
          </Box>
        </Card>
        <Box display='flex' mt={2}>
          <Grid container xs={12} sm={12} justify='flex-end'>
            <Box pr={2}>
              <DiscardButton onClick={closeDialog} />
            </Box>
            <Box>
              <CngPrimaryButton
                disabled={selectedTemplateData == null ? true : false}
                onClick={() => handleDialogSubmit(selectedTemplateData)}
              >
                {translatedTextsObject.insertAndPopulate}
              </CngPrimaryButton>
            </Box>
          </Grid>
        </Box>
      </Box>
    )
  }
}

function PartyTemplateRow(props) {
  const [open, setOpen] = useState(false)
  const timeDateFormat = 'DD MMM YYYY HH:mm'

  let createdDate = moment(props.row.creationDate)
  createdDate = createdDate.format(timeDateFormat)
  return (
    <Box
      mt={1}
      mx={1}
      className={
        props.selected == props.row.id ? 'contact-box-selected' : 'contact-box'
      }
    >
      <Grid
        container
        xs={12}
        onClick={(e) => props.rowClick(e, props.row)}
        alignItems='center'
      >
        <Grid item xs={1} className={'ab-table-cell-padding'}>
          <FormControlLabel
            value={props.row.id}
            key={props.row.id}
            onClick={(e) => {
              e.preventDefault()
            }}
            control={<Radio checked={props.selected == props.row.id} />}
            label=''
          />
        </Grid>
        <Grid item xs={7} className={'ab-table-cell-padding'}>
          <div class='grid-text-ellipsis'>{props.row.tmpltName}</div>
        </Grid>
        <Grid item xs={3} className={'ab-table-cell-padding'}>
          <div class='grid-text-ellipsis' style={{paddingLeft: '1em'}}>
            <b>{createdDate}</b>
          </div>
        </Grid>

        <Grid item xs={1} className={'ab-table-cell-padding'}>
          <IconButton
            aria-label='expand row'
            size='small'
            style={{
              borderRadius: '8px',
              backgroundColor: !open ? '#F5F5FA' : '#5E81F4',
              color: !open ? '#8996AF' : '#FFFFFF'
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Grid container>
              <Box mb={2} mx={2} className={'table-card-enclosing'}>
                <Table size='small' aria-label='memers'>
                  <TableHead style={{ backgroundColor: '#F5F5FA65' }}>
                    <TableRow>
                      <TableCell className={'ab-table-cell-header'}>
                        <div className={'rs-grey'}>DETAILS NO.</div>
                      </TableCell>
                      <TableCell className={'ab-table-cell-header'}>
                        <div className={'rs-grey'}>CATEGORY</div>
                      </TableCell>
                      <TableCell className={'ab-table-cell-header'}>
                        <div className={'rs-grey'}>CONTACT NAME</div>
                      </TableCell>
                      <TableCell className={'ab-table-cell-header'}>
                        <div className={'rs-grey'}>CONTACT ADDRESS</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.row.shpName ? (
                      <TableRow className={'ab-table-cell-row'}>
                        <TableCell className={'ab-table-cell-row'}>1</TableCell>
                        <TableCell className={'ab-table-cell-row'}>
                          Shipper
                        </TableCell>
                        <TableCell className={'ab-table-cell-row'}>
                          {props.row.shpName}
                        </TableCell>
                        <TableCell className={'ab-table-cell-row'}>
                          {props.row.shpAddress}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                    {props.row.conName ? (
                      <TableRow className={'ab-table-cell-row'}>
                        <TableCell className={'ab-table-cell-row'}>
                          {' '}
                          {props.row.shpName ? 2 : 1}
                        </TableCell>
                        <TableCell className={'ab-table-cell-row'}>
                          Consignee
                        </TableCell>
                        <TableCell className={'ab-table-cell-row'}>
                          {props.row.conName}
                        </TableCell>
                        <TableCell className={'ab-table-cell-row'}>
                          {props.row.conAddress}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                    {props.row.dstNotifyName ? (
                      <TableRow className={'ab-table-cell-row'}>
                        <TableCell className={'ab-table-cell-row'}>
                          {' '}
                          {props.row.shpName && props.row.conName
                            ? 3
                            : props.row.conName || props.row.shpName
                            ? 2
                            : 1}
                        </TableCell>
                        <TableCell className={'ab-table-cell-row'}>
                          Notify Party
                        </TableCell>
                        <TableCell className={'ab-table-cell-row'}>
                          {props.row.dstNotifyName}
                        </TableCell>
                        <TableCell className={'ab-table-cell-row'}>
                          {props.row.dstNotifyAddress}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PartyTemplateSelectionForm
