import {
    Box,
    Grid,
    Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { PlusCircle } from 'react-feather'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CompanyPreferenceTranslationText from '../../../shared/CompanyPreferenceTranslationText';
import EditContactDetailsDialog from '../addressbook/EditContactDetailsDialog';
import {
    components
} from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { useHistory } from 'react-router-dom'

const {
    button: { CngPrimaryButton, CngSecondaryButton }
} = components



function FilterNoResult(props) {
    const translatedTextsObject = CompanyPreferenceTranslationText()

    return (
        <div className={'vs-noresult'}>
            <img
                src={
                    process.env.PUBLIC_URL + '/static/images/shippingInstruction/NotFound_Empty.svg'
                }
                alt='No Result'
            ></img>
            <Typography variant='h3' style={{ fontWeight: 'bold', paddingTop: '10px' }}>
                {props.title}
            </Typography>
            <Typography variant='subtitle1' style={{ paddingTop: '10px', color: '#808080' }}>
                {props.desc}
            </Typography>

            <Box pt={2}>

                <Grid container>
                    {props.buttonPanel}
                </Grid>
            </Box>
        </div>

    )
}

export default FilterNoResult;