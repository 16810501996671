import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { LogOut, PlusCircle } from 'react-feather';

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText';
import AddressBookDialog from '../../../shared/dialog';

function ShipperCompanyContactButtonComponent(props) {
  const translatedTextsObject = CalistaUiComponentTranslationText();

  return (
    <Grid container xs={12} sm={12} justify='flex-end'>
      {props.disableExport ?
        <></>
        :
        <Box pr={2}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              props.handleExportContact()
            }}
            startIcon={<LogOut style={{ marginRight: 5 }} />}
            classes={{ root: 'ng-button-filled-secondary' }}
          >
            {translatedTextsObject.exportContactsExcel}
          </Button>
        </Box>
      }
      <Box pr={2}>
        <Button
          variant='contained'
          onClick={() => {
            props.handleAddContact(AddressBookDialog.ADD_CONTACT_DIALOG)
          }}
          startIcon={<PlusCircle style={{ marginRight: 5 }} />}
          className='button-blue originalText'
        >
          {translatedTextsObject.addNewContact}
        </Button>
      </Box>
    </Grid>
  )
}

export default ShipperCompanyContactButtonComponent
