import {
  Card,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Tooltip,
  IconButton
} from '@material-ui/core'
import React, { useState, useRef, useCallback, useEffect } from 'react'

import { components, useServices } from 'cng-web-lib'
import ResetFilterComponent from '../filters/ResetFilterComponent'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Trash, Edit } from 'react-feather'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import HtmlTooltip from 'src/views/freightbooking/components/HtmlTooltip'
import Utils from 'src/views/common/utils/Utils'

import CompanyPreferenceApiUrls from '../../../../../apiUrls/CompanyPreferenceApiUrls'

import AddressBookDialog from '../../../shared/dialog'

import FilterNoResult from '../filters/FilterNoResult'
import FilterClearButtonComponent from '../filters/FilterClearButtonComponent'

const {
  table: { useDefaultNotification },
  CngGridItem
} = components

const initialValues = Object.freeze({})

const FormBody = (props) => {
  const observer = useRef()
  const translatedTextsObject = CompanyPreferenceTranslationText()
  const calistaUiTextObject = CalistaUiComponentTranslationText()
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)

  const [contactList, setContactList] = useState(props.contactList)
  const { fetchPageableRecords } = useServices()

  const [pageSize] = useState(30)
  const [pageNumber, setPageNumber] = useState(0)

  const { error: showErrorNotification } = useDefaultNotification(
    props.showNotification
  )

  let searchCriteria = props.searchCriteria

  useEffect(() => {
    setLoading(true)
    // console.log('props.contactList', props.contactList);

    let temp = [...props.contactList]
    if (temp.length < pageSize) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    setPageNumber(0)
    setContactList(props.contactList)
    props.setCurrentCount(props.contactList.length)
    setLoading(false)
  }, [props.contactList])

  function loadContacts(page) {
    // console.log('Load Contacts', page);
    fetchPageableRecords.execute(
      CompanyPreferenceApiUrls.COMPANY_CONTACT_SEARCH,
      {
        filters: [],
        sorts: [],
        page: page,
        pageSize: pageSize,
        customData: searchCriteria
      },
      (data) => {
        // console.log("Loading Contacts");
        if (data.content.length < pageSize) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }

        let temp3 = [...contactList]
        let temp4 = temp3.concat(data.content)

        setContactList(temp4)
        props.setCurrentCount(temp4.length)
        setLoading(false)
      },
      (error) => {
        showErrorNotification(error)
      }
    )
  }

  const lastContactElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLoading(true)
          setPageNumber(pageNumber + 1)
          loadContacts(pageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  function loadStatus(contact) {
    if (contact.status) {
      if (contact.addressStatus == 'Active') {
        return (
          <TableCell>
            <div className={'ab-text-green'}>{contact.addressStatus}</div>
          </TableCell>
        )
      } else {
        return (
          <TableCell>
            <div className={'ab-text-red'}>{contact.addressStatus}</div>
          </TableCell>
        )
      }
    } else {
      return (
        <TableCell>
          <div className={'ab-text-red'}>Draft</div>
        </TableCell>
      )
    }
  }

  function loadRows() {
    if (props.loading) {
      return (
        <TableRow>
          <TableCell colSpan={7}>
            <object
              data={
                process.env.PUBLIC_URL +
                '/static/images/shippingInstruction/Loading_RowTextSection.svg'
              }
              alt='Tabs Loading...'
            />
          </TableCell>
        </TableRow>
      )
    } else {
      return contactList.map(function (c, index) {
        return (
          <TableRow
            key={index}
            hover
            onClick={(e) =>
              props.handleOpenDialog(
                AddressBookDialog.VIEW_CONTACT_DIALOG,
                e,
                c
              )
            }
          >
            <TableCell>
              <HtmlTooltip title={c.name} placement='right'>
                <div key={index} ref={lastContactElementRef}>
                  {Utils.trimString(c.name, 15)}
                </div>
              </HtmlTooltip>
            </TableCell>
            <TableCell>{c.category.categoryDesc}</TableCell>
            <TableCell>{Utils.trimString(c.addressEmail, 25)}</TableCell>
            <TableCell>
              <HtmlTooltip title={c.address} placement='right'>
                <div>{Utils.trimString(c.address, 30)}</div>
              </HtmlTooltip>
            </TableCell>
            <TableCell>{Utils.trimString(c.addressContactNo, 15)}</TableCell>
            {loadStatus(c)}
            <TableCell className={'vs-table-cell-padding'}>
              <Tooltip title='View & Edit' placement='top' arrow>
                <IconButton
                  color={'default'}
                  onClick={(e) =>
                    props.handleOpenDialog(
                      AddressBookDialog.ADD_CONTACT_DIALOG,
                      e,
                      c
                    )
                  }
                >
                  <Edit size='16px' />
                </IconButton>
              </Tooltip>
              <Tooltip title='Delete' placement='top' arrow>
                <IconButton
                  color={'default'}
                  onClick={(e) =>
                    props.handleOpenDialog(
                      AddressBookDialog.DELETE_CONTACT_CONFIRM,
                      e,
                      c
                    )
                  }
                >
                  <Trash size='16px' />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        )
      })
    }
  }
  if (!props.loading && (contactList === undefined || contactList.length < 1)) {
    return (
      <FilterNoResult
        title={translatedTextsObject.noResultsFilterTitle}
        desc={translatedTextsObject.noResultsFilterDesc}
        buttonPanel={
          <FilterClearButtonComponent
            handleResetFilter={props.handleResetFilter}
          />
        }
      />
    )
  } else {
    return (
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#F5F5FA65' }}>
                <TableCell className={'vs-table-cell-padding'}>
                  <div className={'rs-grey'}>
                    {' '}
                    {translatedTextsObject.contactName.toUpperCase()}
                  </div>
                </TableCell>
                <TableCell className={'vs-table-cell-padding'}>
                  <div className={'rs-grey'}>
                    {translatedTextsObject.category.toUpperCase()}
                  </div>
                </TableCell>
                <TableCell className={'vs-table-cell-padding'}>
                  <div className={'rs-grey'}>
                    {' '}
                    {translatedTextsObject.emailId.toUpperCase()}
                  </div>
                </TableCell>
                <TableCell className={'vs-table-cell-padding'}>
                  <div className={'rs-grey'}>
                    {' '}
                    {translatedTextsObject.address.toUpperCase()}
                  </div>
                </TableCell>
                <TableCell className={'vs-table-cell-padding'}>
                  <div className={'rs-grey'}>
                    {' '}
                    {translatedTextsObject.contactNo.toUpperCase()}
                  </div>
                </TableCell>
                <TableCell className={'vs-table-cell-padding'}>
                  <div className={'rs-grey'}>
                    {calistaUiTextObject.status.toUpperCase()}
                  </div>
                </TableCell>
                <TableCell className={'vs-table-cell-padding'}>
                  <div className={'rs-grey'}>
                    {calistaUiTextObject.action.toUpperCase()}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{loadRows()}</TableBody>
          </Table>
        </TableContainer>

        <div style={{ display: loading ? 'inline' : 'none' }}>
          <Grid container justify='center'>
            <CngGridItem>
              <CircularProgress />
            </CngGridItem>
          </Grid>
        </div>

        <Box mt={5} style={{ display: loading ? 'none' : 'inline' }}>
          <ResetFilterComponent
            leftCount={props.leftCount}
            handleClearAllButtonClick={props.handleClearAllButtonClick}
          />
        </Box>
      </Card>
    )
  }
}
// }

const CompanyContactTableComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default CompanyContactTableComponent
