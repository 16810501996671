import React, { useEffect, useState } from 'react'

import CountrySelectAutoComplete from 'src/views/common/ui/CountrySelectAutoComplete'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import CompanyPreferenceTranslationText from '../shared/CompanyPreferenceTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls'
import TtfbTprUserApiUrls from 'src/apiUrls/TtfbTprUserApiUrls'
import { v4 as uuid } from 'uuid'
import SubmitButton from 'src/components/button/SubmitButton.js'
import Alert from '@material-ui/lab/Alert'
import { Card, CardContent, Grid, Typography, Box } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import Utils from 'src/views/shippinginstruction/shared/Utils'

const {
  form: {
    field: { CngSelectField, CngSwitchField },
    adapter: {
      useFormAdapter: { useField }
    }
  },
  CngGridItem,
  table: { useDefaultNotification }
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  placeOfIssue: '',
  portnetSgShpOnboardDate: false,
  skipManagerApproval: false,
  allowEmptyContInfo: false,
  userSignatureId: [],
  disableNewPermitButton: false,
  version: 0,
  id: null
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({
  disabled,
  showNotification,
  loading,
  setLoading,
  refresh,
  isSubmitting
}) {
  const translatedTextsObject = CompanyPreferenceTranslationText()
  const commonTranslatedTextsObject = CalistaUiComponentTranslationText()
  const { fetchRecords } = useServices()
  const [countryKey, setCountryKey] = useState(uuid())
  const [signatureItems, setSignatureItems] = useState([
    { text: 'Loading', value: null }
  ])
  const [firstRefresh, setFirstRefresh] = useState(true)
  const [, , { setValue: setSkipManagerApprovalField }] = useField(
    'skipManagerApproval'
  )
  const [, , { setValue: setPortnetSgShpOnboardDateField }] = useField(
    'portnetSgShpOnboardDate'
  )
  const [, , { setValue: setAllowEmptyContInfoField }] =
    useField('allowEmptyContInfo')
  const [userSignatureIdField, , { setValue: setUserSignatureIdField }] =
    useField('userSignatureId')
  const [, , { setValue: setPlaceOfIssueField }] = useField('placeOfIssue')
  // Following fields required for update
  const [, , { setValue: setDataVersionField }] = useField('version')
  const [, , { setValue: setDataIdField }] = useField('id')
  const [, , { setValue: setDataPartyIdField }] = useField('partyId')
  const [, , { setValue: setCloseShippingInfoUpdateField }] = useField(
    'closeShippingInfoUpdate'
  )
  const [hideSignatureField, setHideSignatureField] = useState(false)

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  useEffect(() => {
    // Load default signature options
    setLoading(true)
    if (firstRefresh) {
      fetchUserRoleFromAPI()
      setFirstRefresh(false)
    }
    fetchShippingInfoPref()
  }, [refresh])

  function fetchShippingInfoPref() {
    fetchRecords.execute(
      CompanyPreferenceApiUrls.SI_PREF_CREATE,
      undefined,
      (data) => {
        console.log(
          'SI setup preference data retreived: ',
          JSON.stringify(data)
        )
        if (data.content && data.content.length > 0) {
          setSkipManagerApprovalField(data.content[0].skipManagerApproval)
          setPortnetSgShpOnboardDateField(
            data.content[0].portnetSgShpOnboardDate
          )
          setAllowEmptyContInfoField(data.content[0].allowEmptyContInfo)
          setPlaceOfIssueField(data.content[0].placeOfIssue)
          setDataVersionField(data.content[0].version)
          setDataIdField(data.content[0].id)
          setDataPartyIdField(data.content[0].partyId)
          setUserSignatureIdField(data.content[0].userSignatureId)
          setCloseShippingInfoUpdateField(data.content[0].closeShippingInfoUpdate)
        }
        setCountryKey(uuid())
        setLoading(false)
      },
      (error) => {
        console.log(error)
        showErrorNotification(error.message)
        setLoading(false)
      }
    )
  }

  function fetchUserRoleFromAPI() {
    fetchRecords.execute(
      `${process.env.REACT_APP_USER_ORIGIN_URL}/tpr-auth/user-detail/by-login/get`,
      undefined,
      (data) => {
        console.log('fetchUserRoleFromAPI data: ', data)
        if (data.length > 0) {
          let partyId = data[0].partyId
          let partyName = data[0].party.name

          if (Utils.isThisPartyPIL(partyName)) {
            fetchUsersByPartyId(partyId)
          } else {
            setHideSignatureField(true)
          }
        }
      },
      (error) => {
        console.log(error)
        setLoading(false)
        setSignatureItems([{ text: 'Loading', value: null }])
      }
    )
  }

  function fetchUsersByPartyId(partyId) {
    console.log('fetchUsersByPartyId>' + partyId)
    fetchRecords.execute(
      TtfbTprUserApiUrls.GET_USER_DETAIL,
      {},
      (data) => {
        let usersByParty = data.content.filter(
          (user) => user.partyId == partyId
        )
        if (usersByParty.length > 0) {
          let usersIdList = []
          usersByParty.forEach((user) => {
            if ((user.status = 21)) {
              // ensure user is registered
              usersIdList.push(user.userProfileId)
            }
          })
          fetchUserRoleByProfileId(usersIdList)
        }
      },
      (error) => {
        console.log(error)
        setLoading(false)
        setSignatureItems([{ text: 'Error', value: null }])
      }
    )
  }

  function fetchUserRoleByProfileId(usersIdList) {
    console.log('fetchUserRoleByProfileId>' + JSON.stringify(usersIdList))
    let userRoleArr = []
    let successCount = 0

    usersIdList.forEach((userProfileId, idx) => {
      let userProfileIdMap = { userId: userProfileId }
      fetchRecords.execute(
        `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-role/get-by-userprofile/get`,
        { customData: userProfileIdMap },
        (data) => {
          if (data.content.length > 0) {
            let roleIds = []
            data.content.forEach((role) => {
              roleIds.push(role.roleId)
            })
            userRoleArr.push({ userId: userProfileId, roleIds: roleIds })
          }
          successCount += 1
          if (successCount == usersIdList.length) {
            fetchUserRoleByRoleId(userRoleArr)
          }
        },
        (error) => {
          console.log(error)
          setLoading(false)
          setSignatureItems([{ text: 'Error', value: null }])
        }
      )
    })
  }

  function fetchUserRoleByRoleId(userRoleArr) {
    let userSignatureIds = []
    let successCount = 0

    userRoleArr.forEach((user, idx) => {
      fetchRecords.execute(
        `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/role/get`,
        {
          customData: {},
          filters: [
            {
              field: 'id',
              operator: 'IN',
              value: user.roleIds
            }
          ]
        },
        (data) => {
          console.log('fetchUserRoleByRoleId', data)
          if (data.content && data.content.length > 0) {
            let roleCodes = []
            data.content.forEach((roleCode) => {
              roleCodes.push(roleCode.roleCode.toUpperCase())
            })

            console.log('fetchUserRoleByRoleId role code: ' + roleCodes)
            if (
              roleCodes.some(
                (substring) =>
                  substring.includes('MANAGER') && substring.includes('CARRIER')
              )
            ) {
              userSignatureIds.push(user.userId)
            }
          }
          successCount += 1
          if (successCount == userRoleArr.length) {
            fetchUserUsernameByUserId(userSignatureIds)
          }
        },
        (error) => {
          console.log('fetchUserRoleByRoleId : ' + error)
          setLoading(false)
          setSignatureItems([{ text: 'Error', value: null }])
        }
      )
    })
  }

  function fetchUserUsernameByUserId(userSignatureIds) {
    let signatureUserIdOption = []
    let successCount = 0
    userSignatureIds.forEach((userId, idx) => {
      fetchRecords.execute(
        TtfbTprUserApiUrls.GET,
        {},
        (data) => {
          console.log('fetchUserNameById', JSON.stringify(data))
          if (data.content && data.content.length > 0) {
            let userData = data.content.filter(
              (userDetail) => userDetail.id == userId
            )
            signatureUserIdOption.push({
              text: userData[0].loginId,
              value: userId
            })
          }
          successCount += 1
          if (successCount == userSignatureIds.length) {
            let signatureUserIdOptionNew = [
              
              { text: translatedTextsObject.selectSign, value: null },
              ...signatureUserIdOption
            ]
           
            setSignatureItems(signatureUserIdOptionNew)
          }
        },
        (error) => {
          console.log('fetchUserRoleByRoleId : ' + error)
          setLoading(false)
          setSignatureItems([{ text: 'Error', value: null }])
        }
      )
    })
  }

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Typography className={'boldLabel'} style={{ fontSize: 20 }}>
            {translatedTextsObject.bolPreference}
          </Typography>
        </Grid>

        <Box py={2} pl={2}>
          <Grid container spacing={2} xs={12} sm={12}>
            <CngGridItem xs={6} sm={6}>
              <Box pt={2}>
                <Card>
                  <Box className='view-card-content' px={2}>
                    <Box pb={1}>
                      <Typography
                        className={'boldLabel'}
                        style={{ fontSize: 14 }}
                      >
                        {translatedTextsObject.issuePlace}
                      </Typography>
                    </Box>
                    <CountrySelectAutoComplete
                      name='placeOfIssue'
                      label='Country'
                      key={countryKey}
                    />
                  </Box>
                </Card>
              </Box>
            </CngGridItem>

            <CngGridItem xs={6} sm={6}>
              <Box pt={2}>
                {!hideSignatureField && (
                  <Card>
                    <Box className='view-card-content' px={2}>
                      <Box pb={1}>
                        <Typography
                          className={'boldLabel'}
                          style={{ fontSize: 14 }}
                        >
                          {translatedTextsObject.defaultSignStamp}
                        </Typography>
                      </Box>
                      <CngSelectField
                        name='userSignatureId'
                        label='Username'
                        items={signatureItems}
                      />
                    </Box>
                  </Card>
                )}
              </Box>
            </CngGridItem>
          </Grid>

          <Grid container spacing={3} xs={12} sm={12}>
            <CngGridItem xs={6} sm={6}>
              <Box pt={2}>
                <Card>
                  <Box className='view-card-content' px={2}>
                    <Box pb={1}>
                      <Typography
                        className={'boldLabel'}
                        style={{ fontSize: 14 }}
                      >
                        {translatedTextsObject.otherPreference}
                      </Typography>
                    </Box>
                    {/* <Box pb={1}>
                      <Card
                        className='inner-card-group'
                        style={{ backgroundColor: '#5E81F405' }}
                      >
                        <Grid
                          container
                          spacing={2}
                          xs={12}
                          sm={12}
                          justify='space-between'
                          alignItems='center'
                        >
                          <Grid item xs={10} sm={10}>
                            <Typography
                              className='card-content-label'
                              style={{ fontSize: 13 }}
                            >
                              {translatedTextsObject.autoPopulateShpDate}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <CngSwitchField name='portnetSgShpOnboardDate' />
                          </Grid>
                        </Grid>
                      </Card>
                    </Box> */}

                    <Box pb={1}>
                      <Card
                        className='inner-card-group'
                        style={{ backgroundColor: '#5E81F405' }}
                      >
                        <Grid
                          container
                          spacing={2}
                          xs={12}
                          sm={12}
                          justify='space-between'
                          alignItems='center'
                        >
                          <Grid item xs={10} sm={10}>
                            <Typography
                              className='card-content-label'
                              style={{ fontSize: 13 }}
                            >
                              {translatedTextsObject.skipManagerApproval}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <CngSwitchField name='skipManagerApproval' />
                          </Grid>
                        </Grid>
                        <Box pr={2}>
                          {/* <Card
                            className='inner-card-group'
                            //style={{ backgroundColor: '#f2f2f2' }}
                          > */}
                          <Grid
                            container
                            spacing={2}
                            xs={12}
                            sm={12}
                            alignItems='center'
                          >
                            {/* <Grid item xs={1} sm={1}>
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  color='#F4BE5E'
                                  style={{ fontSize: 30 }}
                                />
                              </Grid>
                              <Grid item xs={11} sm={11}>
                                <Typography
                                  className='bold-text-paragraph'
                                  style={{ fontSize: 12 }}
                                >
                                  {
                                    translatedTextsObject.skipManagerApprovalWarn
                                  }
                                </Typography>
                              </Grid> */}
                            <Alert
                              severity='warning'
                              //  icon={<LockOpenIcon color='action' />}

                              //className={classes.myAlert}
                            >
                              {translatedTextsObject.skipManagerApprovalWarn}
                            </Alert>
                          </Grid>
                          {/* </Card> */}
                        </Box>
                      </Card>
                    </Box>

                    <Box>
                      <Card
                        className='inner-card-group'
                        style={{ backgroundColor: '#5E81F405' }}
                      >
                        <Grid
                          container
                          spacing={2}
                          xs={12}
                          sm={12}
                          justify='space-between'
                          alignItems='center'
                        >
                          <Grid item xs={10} sm={10}>
                            <Typography
                              className='card-content-label'
                              style={{ fontSize: 13 }}
                            >
                              {translatedTextsObject.allowSiEmptyCont}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <CngSwitchField name='allowEmptyContInfo' />
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </CngGridItem>
          </Grid>
        </Box>
        <Box pt={2} mr={2}>
          <Grid container justify='flex-end'>
            <SubmitButton isSubmitting={isSubmitting}>
              {commonTranslatedTextsObject.saveChanges}
            </SubmitButton>
          </Grid>
        </Box>
        <Grid>
          <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
            <CngBackdrop loading={loading} />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const ShippingInfoPrefFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: Fields
})

export default ShippingInfoPrefFormProperties
