import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import {
    Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, Grid
} from '@material-ui/core';
import { Trash, Download } from 'react-feather';
import moment from 'moment';

import { useServices, components } from 'cng-web-lib';
import Utils from 'src/views/common/utils/Utils';

import CompanyPreferenceApiUrls from '../../../../../apiUrls/CompanyPreferenceApiUrls'

import CompanyPreferenceTranslationText from '../../../shared/CompanyPreferenceTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import FilterNoResult from '../filters/FilterNoResult';
import FilterClearButtonComponent from '../filters/FilterClearButtonComponent';
import AddressBookDialog from '../../../shared/dialog';

const {
    table: { useDefaultNotification },
} = components;


function BulkUploadTableComponent(props) {
    const observer = useRef()
    const translatedTextsObject = CompanyPreferenceTranslationText();
    const calistaUiTextObject = CalistaUiComponentTranslationText();

    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(props.showNotification);

    const { fetchPageableRecords, fetchRecord, securedSendRequest } = useServices();

    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(0);

    const [uploadedFileList, setUploadedFileList] = useState(props.fileList.content);
    const timeDateFormat = 'DD MMM YYYY HH:mm';

    let searchCriteria = props.searchCriteria

    useEffect(() => {
        setLoading(true);
        console.log('props.fileList', props.fileList);
        let temp = [...props.fileList];
        if (temp.length < pageSize) {
            setHasMore(false);
        }
        else {
            setHasMore(true);
        }
        setPageNumber(0);
        setUploadedFileList(props.fileList);
        props.setCurrentCount(props.fileList.length);
        setLoading(false);
    }, [props.fileList]);

    function loadMoreFiles(page) {
        console.log('loadMoreFiles', page)
        fetchPageableRecords.execute(
            CompanyPreferenceApiUrls.BULK_UPLOAD_SEARCH,
            {
                filters: [],
                sorts: [],
                page: pageNumber,
                pageSize: pageSize,
                customData: searchCriteria

            },
            (data) => {
                console.log("Loading More Files");
                if (data.content.length < pageSize) {
                    setHasMore(false);
                }
                else {
                    setHasMore(true);
                }

                let temp3 = [...uploadedFileList];
                let temp4 = temp3.concat(data.content);

                props.setCurrentCount(temp4.length);
                setUploadedFileList(temp4);
                setLoading(false);
            },
            (error) => {
                showErrorNotification(error);
            }
        )
    }

    const lastContactElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setLoading(true);
                setPageNumber(pageNumber + 1);
                loadMoreFiles(pageNumber + 1);
            }
        })
        if (node) observer.current.observe(node)
    },
        [loading, hasMore]
    );


    function handleDownloadFile(event, elem) {
        console.log("Download File");
        console.log(event);
        console.log(elem);

        let params = {
            id: elem.id
        }
        console.log('id',elem.id);

        securedSendRequest.execute(
            "POST",
            CompanyPreferenceApiUrls.BULK_UPLOAD_DOWNLOAD_FILE,
            { id: elem.id },
            (data) => {
                console.log(data);
                var blob = data.data;
                let url = window.URL.createObjectURL(blob);

                let link = document.createElement('a');
                link.href = url;
                link.download = elem.fileName;
                
                link.click();
                showSuccessNotification('File downloaded');
                
            },
            (error) => {
                console.log(error);
                showErrorNotification('Error downloading file - ' + error.message);
            },
            () => {
                console.log('completed');
            },
            { responseType: "blob" }
        );
    }

    function loadRows() {
        if (props.loading) {
            return (
                <TableRow>
                    <TableCell colspan={4}>
                        <object
                            data={process.env.PUBLIC_URL + '/static/images/shippingInstruction/Loading_RowTextSection.svg'}
                            alt='Tabs Loading...'
                        />
                    </TableCell>
                </TableRow>
            );
        }
        else {
            return (
                uploadedFileList.map(function (c, index) {
                    let createdDate = moment(c.creationDate);
                    createdDate = createdDate.format(timeDateFormat);
                    return (
                        <TableRow
                            key={index}
                            hover
                        >
                            <TableCell>
                                <div key={index}
                                    ref={lastContactElementRef}
                                > {Utils.trimString(c.fileName,30)}</div>

                            </TableCell>
                            <TableCell>{Utils.formatFileSize(c.fileSize,true,1)}
                            </TableCell>
                            <TableCell>
                                {createdDate}
                            </TableCell>
                            <TableCell className={'vs-table-cell-padding'}>
                                <Tooltip title='Delete' placement='top' arrow>
                                    <IconButton color={'default'}
                                        onClick={(e) => props.handleOpenDialog(AddressBookDialog.DELETE_BULK_UPLOAD_CONFIRM, e, c)}>
                                        <Trash size='16px' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Download' placement='top' arrow>
                                    <IconButton color={'default'} onClick={(e) => handleDownloadFile(e, c)}>
                                        <Download size='16px' />
                                    </IconButton>
                                </Tooltip>

                            </TableCell>
                        </TableRow>
                    )
                })
            );
        }
    }

    if (!props.loading && (uploadedFileList == undefined || uploadedFileList.length == 0)) {
        console.log('empty result');
        return (

            <FilterNoResult
                title={translatedTextsObject.noResultsFilterTitle}
                desc={translatedTextsObject.noResultsFilterDesc}
                buttonPanel={
                    <FilterClearButtonComponent
                        handleResetFilter={props.handleResetFilter}
                    />
                }
            />
        );

    }
    else {
        return (
            <Card>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#F5F5FA65' }}>
                                <TableCell className={'vs-table-cell-padding'}>
                                    <div className={'rs-grey'}>
                                        {calistaUiTextObject.fileName.toUpperCase()}
                                    </div>
                                </TableCell>
                                <TableCell className={'vs-table-cell-padding'}>
                                    <div className={'rs-grey'}>
                                        {calistaUiTextObject.fileSize.toUpperCase()}
                                    </div>
                                </TableCell>
                                <TableCell className={'vs-table-cell-padding'}>
                                    <div className={'rs-grey'}>
                                        {calistaUiTextObject.creationDate.toUpperCase()}
                                    </div>
                                </TableCell>
                                <TableCell className={'vs-table-cell-padding'}>
                                    <div className={'rs-grey'}>
                                        {calistaUiTextObject.action.toUpperCase()}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loadRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card >
        );
    }

}

export default BulkUploadTableComponent