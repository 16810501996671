const AddressBookDialog = {
    ADD_CONTACT_DIALOG: 'addContact',
    VIEW_CONTACT_DIALOG: 'viewContact',
    BULK_UPLOAD_DIALOG: 'bulkUpload',
    ADD_PARTY_TEMPLATE_DIALOG: 'addPartyTemplate',
    VIEW_PARTY_TEMPLATE_DIALOG: 'viewPartyTemplate',
    DELETE_CONTACT_CONFIRM: 'deleteContact',
    DELETE_BULK_UPLOAD_CONFIRM: 'deleteBulkUpload',
    DELETE_PARTY_TEMPLATE_CONFIRM: 'deletePartyTemplate',
    DISCARD_BULK_UPLOAD_CONFIRM: 'discardBulkUpload',
};

export default AddressBookDialog;