import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import EditContactDetailsContext from './EditContactDetailsContext.js'
import AddContactForm from './AddContactForm.js'
import { components } from 'cng-web-lib'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

const { CngDialog } = components

function EditContactDetailsDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  setPreviousDialog,
  currentContact,
  role,
  partyId
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = CompanyPreferenceTranslationText()

  return (
    <EditContactDetailsContext.Provider
      value={{
        closeDialog,
        showNotification,
        currentContact,
        role,
        partyId,
        setPreviousDialog,
        form: {
          isSubmitting,
          setSubmitting
        }
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow: 'hidden' }}>
              <AddContactForm
                closeDialog={closeDialog}
                currentContact={currentContact}
                setPreviousDialog={setPreviousDialog}
                role={role}
                partyId={partyId}
              />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <b>
              {currentContact
                ? translatedTextsObject.manageContact
                : translatedTextsObject.addNewContact}
            </b>
            <CloseDialogIconButton
              onClick={() => {
                setPreviousDialog(null)
                closeDialog(false)
              }}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </EditContactDetailsContext.Provider>
  )
}

export default EditContactDetailsDialog
