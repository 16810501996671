import { BasePage as CngBasePage } from 'cng-web-lib'
import ShippingInfoPrefPage from './ShippingInfoPrefPage.js'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CompanyPreference'
import TranslationText from '../shared/CompanyPreferenceTranslationText'

function WrappedShippingInfoPrefPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.companyPreferenceTitle}
      renderPage={(showSnackbar) => (
        <ShippingInfoPrefPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export { WrappedShippingInfoPrefPage as ShippingInfoPrefPage }
