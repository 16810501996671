import { Box, Button, Grid } from '@material-ui/core'


import React from 'react'
import { PlusCircle } from 'react-feather'
import { components } from 'cng-web-lib'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import AddressBookDialog from '../../../shared/dialog';
const {
  form: {
    field: { CngTextField, CngSelectField }
  },
  dropzone: {
    CngFileUpload
  },
  CngGridItem,
  button: { CngPrimaryButton }
} = components



function PartyTemplateButtonComponent(props) {
  const translatedTextsObject = CalistaUiComponentTranslationText()

  function showErrorMessages(e) {
    console.log(e);
  }

  function moreActions() {
    //to do
  }

  return (
    <Grid container xs={12} sm={12} justify='flex-end'>
      <Box pr={2}>
        <Button
          variant='contained'
          onClick={() => {
            props.handleAddPartyTemplate(AddressBookDialog.ADD_PARTY_TEMPLATE_DIALOG)
          }}
          startIcon={<PlusCircle style={{ marginRight: 5 }} />}
          className='button-blue originalText'
        >
          {translatedTextsObject.addNewTemplate}
        </Button>


      </Box>
    </Grid>
  )
}

export default PartyTemplateButtonComponent
