import React, { useState } from 'react';
import { Paper, Box } from '@material-ui/core';

import { components } from 'cng-web-lib';

import EditPartyTemplateContext from './EditPartyTemplateContext.js';
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js';
import PartyTemplateSelectionForm from './PartyTemplateSelectionForm';

import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText';


const {
    CngDialog,
    dropzone: {
        CngFileUpload
    },
    table: { useDefaultNotification }
} = components;

function ListPartyTemplateDialog({
    isDialogOpen,
    closeDialog,
    handleDialogSubmit,
    showNotification,
    currentTemplate
}) {
    const [isSubmitting, setSubmitting] = useState(false);
    const translatedTextsObject = CompanyPreferenceTranslationText()

    return (
        <EditPartyTemplateContext.Provider
            value={{
                closeDialog,
                showNotification,
                handleDialogSubmit,
                currentTemplate,
                form: {
                    isSubmitting,
                    setSubmitting
                },

            }}
        >
            <CngDialog
                customDialogContent={
                    <Paper>
                        <Box p={3}>
                            <PartyTemplateSelectionForm
                                currentTemplate={currentTemplate}
                                handleDialogSubmit={handleDialogSubmit}
                            />
                        </Box>
                    </Paper>
                }
                dialogTitle={
                    <>
                        <b>
                            {translatedTextsObject.addressBook}
                        </b>
                        <CloseDialogIconButton
                            onClick={closeDialog}
                            disabled={isSubmitting}
                        />
                    </>
                }
                open={isDialogOpen}
                fullWidth
                maxWidth='lg'
            />


        </EditPartyTemplateContext.Provider >
    )
}

export default ListPartyTemplateDialog;