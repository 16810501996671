import { BasePage as CngBasePage } from 'cng-web-lib'
import AddressBookShipperPage from './AddressBookShipperPage.js'
import AddressBookCarrierPage from './AddressBookCarrierPage.js'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CompanyPreference'
import TranslationText from '../shared/CompanyPreferenceTranslationText'

function WrappedAddressBookShipperPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.companyPreferenceTitle}
      renderPage={(showSnackbar) => (
        <AddressBookShipperPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddressBookCarrierPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.companyPreferenceTitle}
      renderPage={(showSnackbar) => (
        <AddressBookCarrierPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export { 
  WrappedAddressBookShipperPage as AddressBookShipperPage,
  WrappedAddressBookCarrierPage as AddressBookCarrierPage 
}
