import { Grid, Box } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import GeneralButtonTabComponent from 'src/views/common/ui/GeneralButtonTabComponent'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

const { CngGridItem } = components

const FormBody = (props) => {
  const companyPreferenceTranslationObject = CompanyPreferenceTranslationText()
  let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  const getTabInfo = () => {
    let temp = [
      {
        tabText: 'All',
        tabCount: null,
        tabClicked: props.nameIndex == 0
      }
    ]
    alphabet.split('').forEach((e, i) => {
      temp.push({
        tabText: e,
        tableCount: null,
        tabClicked: props.nameIndex == i + 1
      })
    })
    return temp
  }

  function checkLoading() {
    if (props.loading) {
      return (
        <Grid container xs={12}>
          <Grid item xs={12}>
            <object
              data={
                process.env.PUBLIC_URL +
                '/static/images/shippingInstruction/Loading_FilterSection.svg'
              }
              alt='Tabs Loading...'
            />
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container xs={12} spacing={1}>
          <Grid container item xs={2} justify='flex-start'>
            <Box m='auto'>{companyPreferenceTranslationObject.viewNamesBy}</Box>
          </Grid>
          <Grid container item xs={10}>
            {getTabInfo().map((elem, i) => {
              return (
                <Box>
                  <GeneralButtonTabComponent.FormBody
                    key={i}
                    tabInfo={elem}
                    clickAction={props.switchAlphabet(i)}
                    disable={
                      elem.tabText != 'All'
                        ? !props.enableAlphabet.includes(elem.tabText)
                        : false
                    }
                  />
                </Box>
              )
            })}
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Box pr={2}>
      <Grid container spacing={1}>
        <CngGridItem item xs={12} sm={12}>
          {checkLoading()}
        </CngGridItem>
      </Grid>
    </Box>
  )
}

const AlphanumericFilterComponent = Object.freeze({
  FormBody: FormBody
})

export default AlphanumericFilterComponent
