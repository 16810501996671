import React, { useState, useEffect } from 'react';
import { Avatar, Box, Card, Table, TableBody, TableContainer, TableRow, TableCell, Paper } from '@material-ui/core';
import { FileText } from 'react-feather';

import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent';
import DocumentDetailsActionMenu from 'src/views/freightbooking/components/DocumentDetailsActionMenu'

function BulkUploadDocumentDetails(props) {
    const {
        uploadedFiles,
        batchNo,
        hideEdit,
        hideDelete,
        removeRowClick,
        downloadRowClick,
        downloadLogClick
    } = props;

    useEffect(() => {
        console.log("Bulk Upload files change");
        console.log(uploadedFiles);
        console.log(batchNo);
    }, [uploadedFiles]);

    function fileStatus(f) {
        if (f.status === 'Completed') {
            return (
                <Box mt={2} mr={8} className='rs-green'>
                    <b>Success</b>
                </Box>
            );
        } else if (f.status === 'Pending' || f.status === 'Processing') {
            return (
                <Box mt={2} mr={8} style={{ color: '#8181a5' }}>
                    <b>In Progress...</b>
                </Box>
            );
        } else {
            return (
                <Box mt={2} mr={8} style={{ color: 'red' }}>
                    <b>File Error</b>
                </Box>
            );
        }
    }


    console.log(uploadedFiles);
    if (uploadedFiles === undefined) {
        return <br />
    }
    else {
        return (
            <TableContainer component={Paper}>
                <Table id='table'>
                    <TableBody>
                        {uploadedFiles.map((f, i) => {
                            console.log(f);
                            return (
                                <TableRow key={`row-${i}`}>
                                    <TableCell>
                                        <Box display='flex'>
                                            <Box flexGrow={1}>
                                                <Box display='flex'>
                                                    <Box>
                                                        <Avatar style={{ backgroundColor: '#f5f6fa' }}>
                                                            <FileText size='25px' />
                                                        </Avatar>
                                                    </Box>
                                                    <Box ml={2}>
                                                        <DocumentInfoDecoratorComponent
                                                            bold={true}
                                                            name={f.fileName}
                                                            size={f.fileSize}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                {fileStatus(f)}
                                            </Box>
                                            <Box>
                                                <DocumentDetailsActionMenu
                                                    hideEdit={hideEdit}
                                                    hideDelete={hideDelete}
                                                    hideDownloadLog={f.status !== 'Error'}
                                                    id={i}
                                                    removeRowClick={removeRowClick}
                                                    downloadRowClick={downloadRowClick}
                                                    downloadLogClick={downloadLogClick}
                                                />
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}

export default BulkUploadDocumentDetails;