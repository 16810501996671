import { Box, Grid, Card, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'

import { components } from 'cng-web-lib'
import CountrySelectAutoComplete from 'src/views/common/ui/CountrySelectAutoComplete'

import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
      CngSwitchField,
      CngSelectField
    }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  category: '',
  countryCode: '',
  name: '',
  state: '',
  address: '',
  postalCode: '',
  addressEmail: '',
  addressContactNo: '',
  addressStatus: 'Active',
  status: true
})

const category = [
  { value: 'AGENT', text: 'Agent' },
  { value: 'CONSIGNEE', text: 'Consignee' },
  { value: 'CUSTOMS BROKER', text: 'Customs Broker' },
  { value: 'DELIVERY LOCATION', text: 'Delivery Location' },
  { value: 'DEPOT', text: 'Depot' },
  { value: 'NOTIFY PARTY', text: 'Notify Party' },
  { value: 'PORT', text: 'Port' },
  { value: 'REPRESENTATIVE', text: 'Representative' },
  { value: 'SHIPPER', text: 'Shipper' }
]

const FormBody = (props) => {
  const { setFieldValue } = useFormikContext()
  const translatedTextsObject = CompanyPreferenceTranslationText()
  const [countryCodeField, ,] = useField('countryCode')

  useEffect(() => {
    if (props.role == 'CARRIER') {
      setFieldValue('category', 'AGENT')
    }
  }, [])

  const trimStrings = (event) => {
    setFieldValue(event.target.name, event.target.value.trim())
  }

  return (
    <Box>
      <Grid container className='accordian-header'>
        <CngGridItem xs={6} sm={6}>
          <Box p={1} fontSize='large' fontWeight='bolder'>
            {translatedTextsObject.contactDetails}
          </Box>
        </CngGridItem>
      </Grid>

      <Card className='inner-card-group'>
        <Box mr={1}>
          <Grid container spacing={2}>
            <CngGridItem xs={4} sm={4}>
              {props.role == 'SHIPPER' ? (
                <CngSelectField
                  name='category'
                  label={translatedTextsObject.category}
                  items={category}
                  onChange={(e) => console.log(e.target.value)}
                />
              ) : (
                <CngSelectField
                  name='category'
                  label={translatedTextsObject.category}
                  items={[
                    {
                      value: 'AGENT',
                      text: 'Agent'
                    }
                  ]}
                  disabled
                />
              )}
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngTextField
                type='text'
                name='name'
                inputProps={{ maxLength: 46 }}
                label={translatedTextsObject.contactName}
                onBlur={trimStrings}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}></CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngTextField
                type='text'
                name='addressEmail'
                inputProps={{ maxLength: 100 }}
                label={translatedTextsObject.emailId}
                onBlur={trimStrings}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngTextField
                type='text'
                name='addressContactNo'
                inputProps={{ maxLength: 100 }}
                label={translatedTextsObject.contactNo}
                onBlur={trimStrings}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  backgroundColor: '#F8F9FA',
                  height: '3.5em'
                }}
              >
                <CngGridItem xs={2} sm={2}>
                  <Typography style={{ color: '#1C1D21' }}>Active</Typography>
                </CngGridItem>
                <CngGridItem xs={2} sm={2}>
                  <CngSwitchField name='addressStatus' />
                </CngGridItem>
              </Grid>
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CountrySelectAutoComplete
                name='countryCode'
                label={translatedTextsObject.country}
                //keyRef={countryCodeRef}
                value={countryCodeField.value}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngTextField
                type='text'
                name='state'
                inputProps={{ maxLength: 100 }}
                label={translatedTextsObject.state}
                onBlur={trimStrings}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngTextField
                type='text'
                name='postalCode'
                inputProps={{ maxLength: 20 }}
                label={translatedTextsObject.postalCode}
                onBlur={trimStrings}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={12}>
              <CngTextField
                type='address'
                name='address'
                multiline
                rows={4}
                inputProps={{ maxLength: 512 }}
                label={translatedTextsObject.address}
                onBlur={trimStrings}
              />
            </CngGridItem>
          </Grid>
        </Box>
      </Card>
    </Box>
  )
}

const EditCompanyContactDetailsComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default EditCompanyContactDetailsComponent
