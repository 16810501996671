//import ValidationMessageTranslationText from 'src/views/common/CommonValidationTranslationText'
import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/views/company-preference/shared/validation/ValidationRegex'
import ValidationMessageTranslationText from 'src/views/company-preference/shared/validation/ValidationMessageTranslationText'

function useAddCompanyContactValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({
    category: Yup.string()
      .trim()
      .required(validationMessageTranslation.requiredMessage),
    name: Yup.string()
      .trim()
      .required(validationMessageTranslation.requiredMessage),
    address: Yup.string()
      .matches(
        ValidationRegex.addressMaxLengthRegex,
        validationMessageTranslation.invalidAddressMaxLengthMsg
      )
      .trim()
      .nullable(),
    state: Yup.string().trim().nullable(),
    postalCode: Yup.string()
      .matches(
        ValidationRegex.postalCodeRegex,
        validationMessageTranslation.invalidPostalCode
      )
      .trim()
      .nullable(),
    addressEmail: Yup.string()
      .matches(
        ValidationRegex.emailRegx,
        validationMessageTranslation.invalidEmailMessage
      )
      .trim()
      .nullable(),
    addressContactNo: Yup.string()
      .matches(
        ValidationRegex.phoneRegx,
        validationMessageTranslation.invalidPhoneMsg
      )
      .trim()
      .nullable()
  })
}

export default useAddCompanyContactValidationSchema
