import CompanyPreference from 'src/constants/locale/key/CompanyPreference'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'

const CompanyPreferenceTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.COMPANY_PREFERENCE
  ])
  let title = translate(Namespace.COMPANY_PREFERENCE, CompanyPreference.TITLE)
  let companyPreferenceTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.COMPANY_PREFERENCE_TITLE
  )
  let addressBook = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ADDRESS_BOOK
  )
  let contactDetails = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.CONTACT_DETAILS
  )
  let companyContact = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.COMPANY_CONTACT
  )
  let bulkUploadContact = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.BULK_UPLOAD_CONTACT
  )
  let partyTemplate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.PARTY_TEMPLATE
  )
  let viewNamesBy = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.VIEW_NAMES_BY
  )
  let sequenceNo = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SEQUENCE_NO
  )
  let category = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.CATEGORY
  )
  let contactName = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NAME
  )
  let emailId = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.EMAIL_ID
  )
  let contactNo = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.CONTACT_NO
  )
  let country = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.COUNTRY
  )
  let city = translate(Namespace.COMPANY_PREFERENCE, CompanyPreference.CITY)
  let state = translate(Namespace.COMPANY_PREFERENCE, CompanyPreference.STATE)
  let postalCode = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.POSTAL_CODE
  )
  let address = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ADDRESS
  )

  let addNewContact = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ADD_NEW_CONTACT
  )
  let viewContact = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.VIEW_CONTACT
  )
  let manageContact = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.MANAGE_CONTACT
  )
  let uploadFileTemplate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.UPLOAD_FILE_TEMPLATE
  )
  let addNewTemplate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ADD_NEW_TEMPLATE
  )
  let manageTemplate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.MANAGE_TEMPLATE
  )
  let viewTemplate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.VIEW_TEMPLATE
  )
  let uploadAndValidate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.UPLOAD_AND_VALIDATE
  )
  let saveFile = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SAVE_FILE
  )

  let addNewConsignee = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ADD_NEW_CONSIGNEE
  )
  let addNewShipper = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ADD_NEW_SHIPPER
  )
  let addNewNotifyParty = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ADD_NEW_NOTIFY_PARTY
  )
  let addNewRepresentative = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ADD_NEW_REPRESENTATIVE
  )
  let addNewAgent = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ADD_NEW_AGENT
  )

  let connectedPartyTemplates = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.CONNECTED_PARTY_TEMPLATES
  )
  let connectedPartyTemplatesNote = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.CONNECTED_PARTY_TEMPLATES_NOTE
  )
  let templateName = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.TEMPLATE_NAME
  )
  let saveContact = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SAVE_CONTACT
  )
  let saveTemplate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SAVE_TEMPLATE
  )
  let saveChanges = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SAVE_CHANGES
  )

  let exportContactsExcel = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.EXPORT_CONTACTS_EXCEL
  )
  let insertAndPopulate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.INSERT_AND_POPULATE
  )
  let backToSummary = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.BACK_TO_SUMMARY
  )

  let noContactsTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NO_CONTACTS_TITLE
  )
  let noContactsDesc = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NO_CONTACTS_DESC
  )
  let noBulkUploadTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NO_BULK_UPLOAD_TITLE
  )
  let noBulkUploadDesc = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NO_BULK_UPLOAD_DESC
  )
  let noPartyTemplateTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NO_PARTY_TEMPLATE_TITLE
  )
  let noPartyTemplateDesc = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NO_PARTY_TEMPLATE_DESC
  )
  let noResultsFilterTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NO_RESULTS_FILTER_TITLE
  )
  let noResultsFilterDesc = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NO_RESULTS_FILTER_DESC
  )

  let operationCannotBeUndone = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.OPERATION_CANNOT_BE_UNDONE
  )
  let deleteContactTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DELETE_CONTACT_TITLE
  )
  let deleteContactMsg = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DELETE_CONTACT_MSG
  )
  let deleteBulkUploadTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DELETE_BULK_UPLOAD_TITLE
  )
  let deleteBulkUploadMsg = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DELETE_BULK_UPLOAD_MSG
  )
  let deletePartyTemplateTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DELETE_PARTY_TEMPLATE_TITLE
  )
  let deletePartyTemplateMsg = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DELETE_PARTY_TEMPLATE_MSG
  )
  let discardBulkUploadTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DISCARD_BULK_UPLOAD_TITLE
  )
  let discardBulkUploadMsg = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DISCARD_BULK_UPLOAD_MSG
  )

  let dateFilterTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DATE_FILTER_TITLE
  )
  let dateFilterAll = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DATE_FILTER_ALL
  )
  let dateFilterDay15 = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DATE_FILTER_DAY_15
  )
  let dateFilterDay30 = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DATE_FILTER_DAY_30
  )
  let dateFilterDay60 = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DATE_FILTER_DAY_60
  )

  let notifyContactSaved = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NOTIFY_CONTACT_SAVED
  )
  let notifyContactDeleted = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NOTIFY_CONTACT_DELETED
  )
  let notifyBulkUploadDeleted = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NOTIFY_BULK_UPLOAD_DELETED
  )
  let notifyBulkUploadNoFileError = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NOTIFY_BULK_UPLOAD_FILE_ERROR
  )
  let notifyBulkUploadFileUploaded = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NOTIFY_BULK_UPLOAD_FILE_UPLOADED
  )
  let notifyBulkUploadFileSaved = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NOTIFY_BULK_UPLOAD_FILE_SAVED
  )
  let notifyPartyTemplateSaved = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NOTIFY_PARTY_TEMPLATE_SAVED
  )
  let notifyPartyTemplateDeleted = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.NOTIFY_PARTY_TEMPLATE_DELETED
  )

  let filterResetFilters = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.FILTER_RESET_FILTERS
  )
  let filterClearAll = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.FILTER_CLEAR_ALL
  )

  let errorDeleteContact = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ERROR_DELETE_CONTACT
  )

  let errorDeleteRecord = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ERROR_DELETE_RECORD
  )
  let errorDeleteFile = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ERROR_DELETE_FILE
  )
  let errorUploadingFile = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ERROR_UPLOAD_FILE
  )
  let errorDownloadingFile = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ERROR_DOWNLOAD_FILE
  )
  let errorDownloadingErrorLog = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ERROR_DOWNLOAD_ERROR_LOG
  )
  let errorSavingTemplate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ERROR_SAVING_TEMPLATE
  )
  let errorGetDropdownList = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ERROR_GET_DROPDOWN_LIST
  )

  //---Shipping Info Settings
  let siSetting = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SI_SETTING
  )

  let bolPreference = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.BOL_PREFERENCE
  )

  let issuePlace = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ISSUE_PLACE
  )

  let defaultSignStamp = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.DEFAULT_SIGN_STAMP
  )

  let selectSign = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SELECT_SIGN
  )

  let otherPreference = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.OTHER_PREFERENCE
  )

  let autoPopulateShpDate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.AUTO_POPULATE_SHP_DATE
  )

  let skipManagerApproval = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SKIP_MANAGER_APPROVAL
  )

  let skipManagerApprovalWarn = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SKIP_MANAGER_APPROVAL_WARN
  )

  let allowSiEmptyCont = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.ALLOW_SI_EMPTY_CONT
  )

  let updateSubmitSuccessMsg = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.UPDATE_SUBMIT_SUCCESS_MSG
  )

  let smthWrong = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SMTH_WRONG
  )

  let smthWrongDesc = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SMTH_WRONG_DESC
  )

  let refresh = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.REFRESH
  )

  let frbPrefTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.FRB_COM_PREF_TITLE
  )

  let siPrefTitle = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreference.SI_COM_PREF_TITLE
  )

  return {
    title,
    companyPreferenceTitle,
    addressBook,
    contactDetails,
    companyContact,
    bulkUploadContact,
    partyTemplate,
    viewNamesBy,
    sequenceNo,
    category,
    contactName,
    emailId,
    contactNo,
    country,
    city,
    state,
    postalCode,
    address,

    addNewConsignee,
    addNewShipper,
    addNewNotifyParty,
    addNewRepresentative,
    addNewAgent,

    connectedPartyTemplates,
    connectedPartyTemplatesNote,
    templateName,
    saveContact,
    saveTemplate,
    saveChanges,

    exportContactsExcel,
    insertAndPopulate,
    backToSummary,

    addNewContact,
    viewContact,
    manageContact,
    uploadFileTemplate,
    addNewTemplate,
    manageTemplate,
    viewTemplate,
    uploadAndValidate,
    saveFile,

    noContactsTitle,
    noContactsDesc,
    noBulkUploadTitle,
    noBulkUploadDesc,
    noPartyTemplateTitle,
    noPartyTemplateDesc,
    noResultsFilterTitle,
    noResultsFilterDesc,

    operationCannotBeUndone,
    deleteContactTitle,
    deleteContactMsg,
    deleteBulkUploadTitle,
    deleteBulkUploadMsg,
    deletePartyTemplateTitle,
    deletePartyTemplateMsg,
    discardBulkUploadTitle,
    discardBulkUploadMsg,

    dateFilterTitle,
    dateFilterAll,
    dateFilterDay15,
    dateFilterDay30,
    dateFilterDay60,

    notifyContactSaved,
    notifyContactDeleted,
    notifyBulkUploadDeleted,
    notifyBulkUploadNoFileError,
    notifyBulkUploadFileUploaded,
    notifyBulkUploadFileSaved,
    notifyPartyTemplateSaved,
    notifyPartyTemplateDeleted,

    filterClearAll,
    filterResetFilters,

    errorDeleteContact,
    errorDeleteRecord,
    errorDeleteFile,
    errorUploadingFile,
    errorDownloadingFile,
    errorDownloadingErrorLog,
    errorSavingTemplate,
    errorGetDropdownList,

    siSetting,
    bolPreference,
    issuePlace,
    defaultSignStamp,
    selectSign,
    otherPreference,
    autoPopulateShpDate,
    skipManagerApproval,
    skipManagerApprovalWarn,
    allowSiEmptyCont,
    updateSubmitSuccessMsg,

    smthWrong,
    smthWrongDesc,
    refresh,
    frbPrefTitle,
    siPrefTitle
  }
}

export default CompanyPreferenceTranslationText
