import React, { useState, useContext, useEffect, useRef } from 'react';
import { Grid, Paper, Box, Card, Table, TableHead, TableBody, TableRow, TableCell, Tab } from '@material-ui/core';

import { components, constants, useServices } from 'cng-web-lib';
import AccordionHeaderComponent from '../../../../common/ui/AccordionHeaderComponent';

import EditButton from 'src/components/button/EditButton.js'
import DeleteButton from 'src/components/button/DeleteButton.js'
import ResetButton from 'src/components/button/ResetButton'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CompanyPreferenceTranslationText from '../../../shared/CompanyPreferenceTranslationText';
import AddressBookDialog from '../../../shared/dialog';
import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls';
import AddressBookApi from '../../../shared/api';

import useCreatePartyTemplateValidationSchema from './useCreatePartyTemplateValidationSchema';

import EditPartyTemplateContext from './EditPartyTemplateContext';
import LabelValueVertical from '../LabelValueVertical';


const {
    form: {
        CngViewForm,
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        }
    },
    button: { CngSecondaryButton },
    CngGridItem
} = components;


function ViewPartyTemplate(props) {
    const dialogContext = useContext(EditPartyTemplateContext);

    const {
        closeDialog,
        showNotification,
        form: { isSubmitting, setSubmitting },
        currentTemplate,
        editPartyTemplate,
        setPreviousDialog,
        openDialog,
    } = dialogContext;

    function onSubmit(data) {
        setPreviousDialog(null);
        closeDialog(true, false);
    }

    const validationSchema = useCreatePartyTemplateValidationSchema();

    return (
        <CngViewForm
            formikProps={{
                initialValues: {
                    ...DEFAULT_INITIAL_VALUES
                },
                validationSchema: validationSchema,
                onSubmit: onSubmit,
                validateOnChange: false
            }}
            bodySection={
                <FormBody
                    closeDialog={closeDialog}
                    isSubmitting={isSubmitting}
                    currentTemplate={currentTemplate}
                    editPartyTemplate={editPartyTemplate}
                    openDialog={openDialog}
                    setPreviousDialog={setPreviousDialog}
                />
            }
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        />
    )
}

function FormBody({ closeDialog, isSubmitting, currentTemplate, editPartyTemplate, openDialog, setPreviousDialog }) {
    const dialogContext = useContext(EditPartyTemplateContext);
    const { setFieldValue } = useFormikContext();

    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
    const translatedTextsObject = CompanyPreferenceTranslationText()

    const [partyMembers, setPartyMembers] = useState([]);

    useEffect(() => {
        console.log("currentTemplate", currentTemplate);

        let tempArray = [];
        let tempDetail = {};
        if (currentTemplate.shpName != null) {
            tempDetail = {
                category: 'Shipper',
                name: currentTemplate.shpName,
                address: currentTemplate.shpAddress,
            };
            tempArray.push(tempDetail);
        }
        if (currentTemplate.conName != null) {
            tempDetail = {
                category: 'Consignee',
                name: currentTemplate.conName,
                address: currentTemplate.conAddress,
            };
            tempArray.push(tempDetail);
        }
        if (currentTemplate.dstNotifyName != null) {
            tempDetail = {
                category: 'Notify Party',
                name: currentTemplate.dstNotifyName,
                address: currentTemplate.dstNotifyAddress,
            };
            tempArray.push(tempDetail);
        }
        if (currentTemplate.repName != null) {
            tempDetail = {
                category: 'Representative',
                name: currentTemplate.repName,
                address: currentTemplate.repAddress,
            };
            tempArray.push(tempDetail);
        }
        console.log(tempArray);

        setPartyMembers(tempArray);

    }, [currentTemplate]);


    return (
        <Paper>
            <Box m={1.5}>
                <Grid container className='accordian-header'>
                    <CngGridItem xs={6} sm={6}>
                        <Box p={1} fontSize='large' fontWeight='bolder'>
                            Template Details
                        </Box>
                    </CngGridItem>
                </Grid>
                <Card className='inner-card-group'>
                    <Grid container spacing={2}>
                        <CngGridItem xs={12} sm={4}>
                            <LabelValueVertical
                                label='Template Name'
                                value={currentTemplate.tmpltName}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={12}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <TableHead style={{ backgroundColor: '#F5F5FA65' }}>
                                    <TableRow>
                                        <TableCell style={{ width: '10%' }}>Detail No.</TableCell>
                                        <TableCell style={{ width: '10%' }}>Category</TableCell>
                                        <TableCell>Contact Name</TableCell>
                                        <TableCell>Contact Address</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {partyMembers.map((elem, i) => {
                                        return (
                                            <TableRow>
                                                <TableCell>
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell>
                                                    {elem.category}
                                                </TableCell>
                                                <TableCell style={{ wordBreak: 'break-all' }}>
                                                    {elem.name}
                                                </TableCell>
                                                <TableCell style={{ wordBreak: 'break-all' }}>
                                                    <LabelValueVertical
                                                        value={elem.address}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </CngGridItem>
                    </Grid>
                </Card>

                <Box display='flex' mt={2}>
                    <Grid xs={6} sm={6} justify='flex-start'>
                        <CngSecondaryButton onClick={closeDialog}>
                            {uiTranslatedTextsObject.goBack}
                        </CngSecondaryButton>
                    </Grid>

                    <Grid container xs={6} sm={6} justify='flex-end'>
                        <Box pr={2}>
                            <DeleteButton onClick={(e) => {
                                closeDialog(true);
                                openDialog(AddressBookDialog.DELETE_PARTY_TEMPLATE_CONFIRM, e, currentTemplate);
                                setPreviousDialog(AddressBookDialog.VIEW_PARTY_TEMPLATE_DIALOG);
                            }}
                                disabled={isSubmitting} />
                        </Box>
                        <Box>
                            <EditButton onClick={editPartyTemplate} disabled={isSubmitting} />

                            {/* <SubmitButton isSubmitting={isSubmitting}>
              {companyPreferenceTranslationObject.saveContact}
            </SubmitButton> */}
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </Paper>
    )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({});

export default ViewPartyTemplate
