import CompanyPreference from 'src/constants/locale/key/CompanyPreference';
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation, constants } from 'cng-web-lib'


const {
    locale: {
        key: { CommonValidationMessageKeys }
    }
} = constants

const ValidationMessageTranslationText = () => {
    const { translate } = useTranslation([
        Namespace.COMPANY_PREFERENCE,
        Namespace.COMMON_VALIDATION_MSG,
    ])

    let requiredMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )

    let invalidEmailMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.INVALID_EMAIL
    )

    let invalidPhoneMsg = translate(
        Namespace.COMPANY_PREFERENCE,
        CompanyPreference.VALIDATION_INVALID_PHONE
    )

    let invalidAddressMaxLengthMsg = translate(
        Namespace.COMPANY_PREFERENCE,
        CompanyPreference.VALIDATION_INVALID_ADDRESS_MAX_LENGTH
    )

    let invalidPostalCode = translate(
        Namespace.COMPANY_PREFERENCE,
        CompanyPreference.VALIDATION_INVALID_POSTAL_CODE
    )

    let nameExceedMaxLengthMsg = translate(
        Namespace.COMPANY_PREFERENCE,
        CompanyPreference.VALIDATION_NAME_EXCEED_MAX_LENGTH
    )

    return {
        requiredMessage,
        invalidEmailMessage,
        invalidPhoneMsg,
        invalidAddressMaxLengthMsg,
        invalidPostalCode,
        nameExceedMaxLengthMsg
    }
}

export default ValidationMessageTranslationText
