import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef
} from 'react'
import { PlusCircle } from 'react-feather'
import {
  Card,
  Box,
  Grid,
  IconButton,
  Collapse,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Button
} from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import CompanyPreferenceTranslationText from '../../../shared/CompanyPreferenceTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls'

import DiscardButton from 'src/components/button/DiscardButton.js'
import CountryIconViewField from 'src/components/field/CountryIconViewField'

import LabelValueVertical from '../LabelValueVertical'

import EditContactDetailsContext from './EditContactDetailsContext'
import AlphanumericFilterComponent from '../filters/AlphanumericFilterComponent'
import TabSearchResultFilter from '../filters/TabSearchResultFilter'
import FilterNoResult from '../filters/FilterNoResult'
import FilterClearButtonComponent from '../filters/FilterClearButtonComponent'

const {
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  table: { useDefaultNotification },
  button: { CngPrimaryButton },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  selectedContact: 0
})

const initialValues = Object.freeze({
  ...DEFAULT_INITIAL_VALUES
})

function CompanyContactSelectionForm(props) {
  const dialogContext = useContext(EditContactDetailsContext)

  const {
    closeDialog,
    showNotification,
    form: { isSubmitting },
    contactType,
    handleDialogSubmit,
    handleAddContact,
    refresh
  } = dialogContext

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues
        },
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          handleDialogSubmit={handleDialogSubmit}
          handleAddContact={handleAddContact}
          contactType={contactType}
          refresh={refresh}
          showNotification={showNotification}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}
function FormBody({
  closeDialog,
  isSubmitting,
  contactType,
  handleDialogSubmit,
  handleAddContact,
  refresh,
  showNotification
}) {
  const translatedTextsObject = CompanyPreferenceTranslationText()
  const calistaUiTextObject = CalistaUiComponentTranslationText()

  const [filterTextField, , { setValue: setFilterTextField }] =
    useField('filterText')

  const { error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const { setFieldValue } = useFormikContext()

  const [loading, setLoading] = useState(false)
  const observer = useRef()
  const [hasMore, setHasMore] = useState(false)
  const [innerLoading, setInnerLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const pageSize = 10

  const { fetchPageableRecords } = useServices()

  const [nameIndex, setNameIndex] = useState(0)
  const [alphaFilterEnable, setAlphaFilterEnable] = useState([])

  const [currentCount, setCurrentCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [companyContactList, setCompanyContactList] = useState([]) // stores records currently shown to user

  const [selectedContact, setSelectedContact] = useState('')
  const [, , { setValue: setSelectedContactField }] =
    useField('selectedContact')

  const [searchCriteria, setSearchCriteria] = useState({})

  const [selectedContactData, setSelectedContactData] = useState(null)

  const [sortList] = useState([
    {
      value: 'Name_A-Z',
      text: 'Name (A-Z)'
    },
    {
      value: 'Name_Z-A',
      text: 'Name (Z-A)'
    }
  ])

  useEffect(() => {
    refreshContacts()
  }, [refresh])

  useEffect(() => {
    console.log(searchCriteria)

    if (pageNumber > 0) {
      setInnerLoading(true)
      ProgressiveLoading()
    }
  }, [pageNumber])

  useEffect(() => {
    if (Object.keys(searchCriteria).length) {
      getFilterContacts()
    }
  }, [searchCriteria])

  function refreshContacts() {
    console.log('enter Refresh')

    setLoading(true)
    setFieldValue('sortCode', 'Name_A-Z')

    let tempSearch = {
      filterText: '',
      dateRange: 0,
      statusCode: [1],
      addressStatus: [],
      category: [contactType],
      namePrefix: '',
      sortType: 'Name_A-Z'
    }

    if (contactType == 'AGENT') {
      tempSearch.addressStatus = ['Active', 'Inactive']
    } else {
      tempSearch.addressStatus = ['Active']
    }
    console.log('searchCriteria', tempSearch)
    setSearchCriteria(tempSearch)

    fetchPageableRecords.execute(
      CompanyPreferenceApiUrls.COMPANY_CONTACT_SEARCH,
      {
        filters: [],
        sorts: [],
        page: 0,
        pageSize: 1000000, // Just to get all records cos setting filters
        customData: tempSearch
      },
      (data) => {
        let alphaFilter = []
        data.content.map((e, i) => {
          if (e.name != null) {
            if (!alphaFilter.includes(e.name.toUpperCase().charAt(0))) {
              alphaFilter.push(e.name.toUpperCase().charAt(0))
            }
          }
        })

        setAlphaFilterEnable(alphaFilter)
      },
      (error) => {
        console.log(error)
        showErrorNotification(error)
        setLoading(false)
      }
    )
  }

  function getFilterContacts() {
    setInnerLoading(true)
    setPageNumber(0)

    fetchPageableRecords.execute(
      CompanyPreferenceApiUrls.COMPANY_CONTACT_SEARCH,
      {
        filters: [],
        sorts: [],
        page: 0,
        pageSize: pageSize,
        customData: searchCriteria
      },
      (data) => {
        let contactList = data.content

        if (contactList != null && contactList.length < pageSize) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }

        setCompanyContactList(contactList)
        setCurrentCount(contactList.length)
        setTotalCount(data.totalElements)
        setInnerLoading(false)
        setLoading(false)
      },
      (error) => {
        console.log(error)
        showErrorNotification(error)
        setInnerLoading(false)
        setLoading(false)
      }
    )
  }

  function ProgressiveLoading() {
    console.log('ProgressiveLoading')
    fetchPageableRecords.execute(
      CompanyPreferenceApiUrls.COMPANY_CONTACT_SEARCH,
      {
        filters: [],
        sorts: [],
        page: pageNumber,
        pageSize: pageSize,
        customData: searchCriteria
      },
      (data) => {
        console.log(data.content)

        if (data.content.length < pageSize) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }

        let temp = [...companyContactList]
        console.log(temp)
        let temp2 = temp.concat(data.content)
        console.log(temp2)

        setCompanyContactList(temp2)
        setCurrentCount(temp2.length)
        setInnerLoading(false)
      },
      (error) => {
        console.log(error)
        showErrorNotification('Error loading more records.')
      }
    )
  }

  const switchAlphabet = (e) => () => {
    setNameIndex(e)
    let tempCriteria = { ...searchCriteria }
    tempCriteria.namePrefix = alphabetProcessing(e)
    setSearchCriteria(tempCriteria)
  }

  function alphabetProcessing(index) {
    let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    if (index == 0) {
      return ''
    } else {
      return alphabet[index - 1]
    }
  }

  const clearFilterText = (e) => {
    let tempCriteria = { ...searchCriteria }
    tempCriteria.filterText = ''
    setSearchCriteria(tempCriteria)
    setFilterTextField('')
  }

  const handleFilterTextChange = (e, isButtonClick) => {
    if (isButtonClick) {
      console.log('FilterTextField: ' + JSON.stringify(filterTextField))
    } else {
      let newFilterText = e.target.value
      let tempCriteria = { ...searchCriteria }
      tempCriteria.filterText = newFilterText
      setSearchCriteria(tempCriteria)
      setFilterTextField(newFilterText)
    }
  }

  function handleSortByChange(e) {
    if (e.target.value != null && e.target.value != 0) {
      let tempCriteria = { ...searchCriteria }
      tempCriteria.sortType = e.target.value
      setSearchCriteria(tempCriteria)
    }
  }

  function handleSelectContact(e, contact) {
    if (contact == undefined) {
      return
    }
    setSelectedContact(contact.id)
    setSelectedContactField(contact.id)

    let tempInfo = selectedContactData == null ? {} : { ...selectedContactData }

    tempInfo.contactType = contactType
    tempInfo.addressStatus = contact.addressStatus

    if (contact.address == null) {
      contact.address = ''
    }

    if (contactType == 'CONSIGNEE') {
      tempInfo.conName = contact.name
      tempInfo.conAddress = contact.address
      tempInfo.conEmail = contact.addressEmail
      tempInfo.conContactNo = contact.addressContactNo
      tempInfo.conCityName = contact.state
      tempInfo.conPostalCode = contact.postalCode
      tempInfo.conCountryName = contact.countryName
      tempInfo.conCountryCode = contact.countryCode
    } else if (contactType == 'SHIPPER') {
      tempInfo.shpName = contact.name
      tempInfo.shpAddress = contact.address
      tempInfo.shpEmail = contact.addressEmail
      tempInfo.shpContactNo = contact.addressContactNo
      tempInfo.shpCityName = contact.state
      tempInfo.shpPostalCode = contact.postalCode
      tempInfo.shpCountryName = contact.countryName
      tempInfo.shpCountryCode = contact.countryCode
    } else if (contactType == 'NOTIFY PARTY') {
      tempInfo.dstNotifyName = contact.name
      tempInfo.dstNotifyAddress = contact.address
    } else if (contactType == 'AGENT') {
      tempInfo.agentName = contact.name
      tempInfo.agentAddress = contact.address
    } else if (contactType == 'REPRESENTATIVE') {
      tempInfo.repName = contact.name
    } else if (contactType == 'CARRIER') {
      tempInfo.carrName = contact.name
      tempInfo.carrAddress = contact.address
      tempInfo.carrEmail = contact.addressEmail
      tempInfo.carrContactNo = contact.addressContactNo
      tempInfo.carrCityName = contact.state
      tempInfo.carrPostalCode = contact.postalCode
      tempInfo.carrCountryName = contact.countryName
      tempInfo.carrCountryCode = contact.countryCode
    } 

    setSelectedContactData(tempInfo)
  }

  const lastBookElementRef = useCallback(
    (node) => {
      if (innerLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          console.log(hasMore)
        }
      })
      if (node) observer.current.observe(node)
    },
    [innerLoading, hasMore]
  )

  if (loading) {
    return (
      <div style={{ display: loading ? 'inline' : 'none' }}>
        <Grid container justify='center'>
          <CngGridItem>
            <CircularProgress />
          </CngGridItem>
        </Grid>
      </div>
    )
  } else {
    return (
      <Box>
        <Card>
          <Box p={2}>
            <Box className='modal-sticky-top '>
              <TabSearchResultFilter
                resultCount={currentCount}
                totalCount={totalCount}
                title={contactType}
                buttonComponent={
                  <CompanyContactAddButton
                    contactType={contactType}
                    handleAddContact={handleAddContact}
                  />
                }
                filterText={filterTextField.value}
                handleFilterTextChange={handleFilterTextChange}
                clearFilterText={clearFilterText}
                sortByList={sortList}
                handleSortByChange={handleSortByChange}
              />
              <AlphanumericFilterComponent.FormBody
                loading={loading}
                switchAlphabet={switchAlphabet}
                enableAlphabet={alphaFilterEnable}
                nameIndex={nameIndex}
              />
              {companyContactList != null && companyContactList.length > 0 && (
                <Box mt={1}>
                  <Grid container>
                    <Grid item xs={1} className={'ab-table-cell-header'}></Grid>
                    <Grid item xs={3} className={'ab-table-cell-header'}>
                      {translatedTextsObject.contactName.toUpperCase()}
                    </Grid>
                    <Grid item xs={5} className={'ab-table-cell-header'}>
                      {translatedTextsObject.address.toUpperCase()}
                    </Grid>
                    <CngGridItem item xs={2} className={'ab-table-cell-header'}>
                      {calistaUiTextObject.status.toUpperCase()}
                    </CngGridItem>
                    <Grid item xs={1} className={'ab-table-cell-header'}></Grid>
                  </Grid>
                </Box>
              )}
            </Box>

            {companyContactList === null || companyContactList.length < 1 ? (
              <FilterNoResult
                title={translatedTextsObject.noResultsFilterTitle}
                desc={translatedTextsObject.noResultsFilterDesc}
                buttonPanel={
                  <FilterClearButtonComponent
                    handleResetFilter={clearFilterText}
                  />
                }
              />
            ) : (
              <Box className='modal-content'>
                <RadioGroup
                  name='selectedContact'
                  id='selectedContact'
                  value={selectedContact}
                >
                  <Grid container>
                    {companyContactList !== null &&
                      companyContactList.map((c, index) => (
                        <div
                          key={index}
                          ref={lastBookElementRef}
                          class='div-flex'
                        >
                          <CompanyContactRow
                            key={c.id}
                            row={c}
                            rowClick={(e) => handleSelectContact(e, c)}
                            selected={selectedContact}
                            open={false}
                          />
                        </div>
                      ))}
                  </Grid>
                  <div style={{ display: innerLoading ? 'inline' : 'none' }}>
                    <Grid container justify='center'>
                      <CngGridItem>
                        <CircularProgress />
                      </CngGridItem>
                    </Grid>
                  </div>
                </RadioGroup>
              </Box>
            )}
          </Box>
        </Card>
        <Box mt={2}>
          <Grid container xs={12} sm={12} justify='flex-end'>
            <Box pr={2}>
              <DiscardButton onClick={closeDialog} />
            </Box>
            <Box>
              <CngPrimaryButton
                disabled={selectedContactData == null ? true : false}
                onClick={() => handleDialogSubmit(selectedContactData)}
              >
                {translatedTextsObject.insertAndPopulate}
              </CngPrimaryButton>
            </Box>
          </Grid>
        </Box>
      </Box>
    )
  }

  function CompanyContactAddButton(props) {
    function addContactType(contactType) {
      if (contactType == 'CONSIGNEE') {
        return translatedTextsObject.addNewConsignee
      } else if (contactType == 'SHIPPER') {
        return translatedTextsObject.addNewShipper
      } else if (contactType == 'NOTIFY PARTY') {
        return translatedTextsObject.addNewNotifyParty
      } else if (contactType == 'AGENT') {
        return translatedTextsObject.addNewAgent
      } else if (contactType == 'REPRESENTATIVE') {
        return translatedTextsObject.addNewRepresentative
      }
    }

    return (
      <Button
        variant='contained'
        startIcon={<PlusCircle style={{ marginRight: 5 }} />}
        className='button-blue originalText'
        onClick={(e) => props.handleAddContact(props.contactType)}
      >
        {addContactType(props.contactType)}
      </Button>
    )
  }

  function CompanyContactRow(props) {
    const [isOpen, setIsOpen] = useState(false)
    console.log(isOpen)

    return (
      <Box
        mt={1}
        mx={1}
        className={
          props.selected == props.row.id
            ? 'contact-box-selected'
            : 'contact-box'
        }
      >
        <Grid
          container
          xs={12}
          onClick={(e) => props.rowClick(e, props.row)}
          alignItems='center'
        >
          <Grid item xs={1} className={'ab-table-cell-padding'}>
            <FormControlLabel
              value={props.row.id}
              key={props.row.id}
              onClick={(e) => {
                e.preventDefault()
              }}
              control={<Radio checked={props.selected == props.row.id} />}
              label=''
            />
          </Grid>
          <Grid item xs={3} className={'ab-table-cell-padding'}>
            <div class='grid-text-ellipsis'>{props.row.name}</div>
          </Grid>
          <Grid item xs={5} className={'ab-table-cell-padding'}>
            <div class='grid-text-ellipsis'>{props.row.address}</div>
          </Grid>
          <Grid item xs={2} className={'ab-table-cell-padding'}>
            {props.row.addressStatus == 'Active' ? (
              <div className={'ab-text-green'}>{props.row.addressStatus}</div>
            ) : (
              <div className={'ab-text-red'}>{props.row.addressStatus}</div>
            )}
          </Grid>
          <Grid item xs={1} className={'ab-table-cell-padding'}>
            <IconButton
              aria-label='expand row'
              size='small'
              style={{
                borderRadius: '8px',
                backgroundColor: !isOpen ? '#F5F5FA' : '#5E81F4',
                color: !isOpen ? '#8996AF' : '#FFFFFF'
              }}
              onClick={(e) => {
                e.stopPropagation()
                setIsOpen((currentIsOpen) => !currentIsOpen)
              }}
            >
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={isOpen} timeout='auto' unmountOnExit>
              <Grid container spacing={2}>
                <Grid container xs={12}>
                  <CngGridItem xs={1}></CngGridItem>
                  <CngGridItem xs={3}>
                    <LabelValueVertical
                      label={translatedTextsObject.contactName}
                      value={props.row.name}
                    />
                  </CngGridItem>
                  <CngGridItem xs={5}>
                    <LabelValueVertical
                      label={translatedTextsObject.address}
                      value={props.row.address}
                    />
                  </CngGridItem>
                  <CngGridItem xs={3}>
                    <LabelValueVertical
                      label={translatedTextsObject.postalCode}
                      value={props.row.postalCode}
                    />
                  </CngGridItem>
                </Grid>
                <Grid container xs={12}>
                  <CngGridItem xs={4}></CngGridItem>
                  <CngGridItem xs={5}>
                    <LabelValueVertical
                      label={translatedTextsObject.state}
                      value={props.row.state}
                    />
                  </CngGridItem>
                  <CngGridItem xs={3}>
                    {props.row.countryCode != null ? (
                      <Box className='view-card-content'>
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.country}
                        </Box>
                        <Box
                          className='view-card-content-value'
                          style={{ paddingRight: 0 }}
                        >
                          <CountryIconViewField
                            countryCode={props.row.countryCode}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <LabelValueVertical
                        label={translatedTextsObject.country}
                        value='-'
                      />
                    )}
                  </CngGridItem>
                </Grid>
                <Grid container xs={12}>
                  <CngGridItem xs={4}></CngGridItem>
                  <CngGridItem xs={5}>
                    <LabelValueVertical
                      label={translatedTextsObject.emailId}
                      value={props.row.addressEmail}
                    />
                  </CngGridItem>
                  <CngGridItem xs={3}>
                    <LabelValueVertical
                      label={translatedTextsObject.contactNo}
                      value={props.row.addressContactNo}
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default CompanyContactSelectionForm
