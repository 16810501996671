import { Card, CardContent, Grid } from '@material-ui/core'

import DateRangeFilterComponent from './DateRangeFilterComponent';
import FilterHeaderComponent from './FilterHeaderComponent';
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngSwitchField }
  },
  CngGridItem
} = components

function DateRangeFilterPanelComponent(props) {
  return (
    <Grid md={12}>
      <Card>
        <CardContent>
          <FilterHeaderComponent
            handleClearAllButtonClick={props.handleResetFilter}
          />

          <DateRangeFilterComponent
            title={props.dateTitle}
            dateRangeOptions={props.dateRangeOptions}
            dateRangeChange={props.dateRangeChange}
            dateRange={props.dateRange}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default DateRangeFilterPanelComponent
