

import React from 'react'
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib';
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText';
import StatusCheckboxGroup from './StatusCheckboxGroup';

const CategoryFilterComponent = (props) => {
  const translatedTextsObject = CompanyPreferenceTranslationText();

  const {
    CngGridItem
  } = components;

  return (
    <Grid container='true' item className={'rs-filter-card'}>
      <Grid className={'rs-bg-grey'} item md={12}>
        <CngGridItem md={6}>
          <Typography className={'rs-filter-subheader'}>
            {translatedTextsObject.category}
          </Typography>
        </CngGridItem>
      </Grid>
      {/* <ContactCategoryStatusCheckboxGroup
            checkboxList={props.category}
            onStatusCheckBoxChange={props.onCategoryCheckBoxChange}
          /> */}
      <StatusCheckboxGroup
        checkBoxList={props.category}
        onChange={props.onCheckBoxChange}
        filterName={''}
        fieldName={props.fieldName}
      />
    </Grid>
  )
}

export default CategoryFilterComponent
