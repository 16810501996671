import { Box, Grid, Chip, Card } from '@material-ui/core'
import React  from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Alert from '@material-ui/lab/Alert'

import { useServices, components } from 'cng-web-lib'

import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

const {
  form: {
    field: {
      CngTextField
    }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  names: []
})

const FormBody = (props) => {
  const translatedTextsObject = CompanyPreferenceTranslationText();

  let templateNames = props.currentContact.addressBookPartiesDTOs.map((partyTemplate, i) => {
    let template = {
      label: partyTemplate.tmpltName,
      id: partyTemplate.id
    };
    return template;
  });

  let selectedTemplates = props.currentContact.addressBookPartiesDTOs.map((partyTemplate, i) => {
    return partyTemplate.tmpltName;
  });

  return (
    <Box>
      <Grid container className='accordian-header'>
        <CngGridItem xs={6} sm={6}>
          <Box p={1} fontSize='large' fontWeight='bolder'>
            {translatedTextsObject.connectedPartyTemplates}
          </Box>
        </CngGridItem>
      </Grid>

      <Card className='inner-card-group'>
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={12}>
            <Alert severity='info' className='font-mediumBold'>
              {translatedTextsObject.connectedPartyTemplatesNote}
            </Alert>
          </CngGridItem>

          <CngGridItem xs={12} sm={12}>
            <Autocomplete
              name='templateNames'
              label={translatedTextsObject.templateName}
              multiple
              disabled={props.disabled}
              options={templateNames}
              value={selectedTemplates}
              renderInput={(params) => (
                <CngTextField
                  {...params}
                  label={translatedTextsObject.templateName}
                />
              )}
              renderTags={(value) =>
                value.map((option) => (
                  <Chip variant="outlined" label={option} style={{backgroundColor: '#EBF0FE', color: '#1C1D21', border: 'none'}}/>
                ))
              }
            />
          </CngGridItem>
        </Grid>
      </Card>
    </Box>
  )
}

const ConnectedPartyTemplateComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ConnectedPartyTemplateComponent
