import { Box, Grid, Card, } from '@material-ui/core'
import React from 'react'


import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'
import { components } from 'cng-web-lib'
import LabelValueVertical from '../LabelValueVertical';
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CountryIconViewField from 'src/components/field/CountryIconViewField'
const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  category: '',
  name: '',
  addrEmail: '',
  addrContactNo: '',
  active: true
})

const FormBody = (props) => {
  const { currentContact } = props
  const translatedTextsObject = CompanyPreferenceTranslationText()
  const calistaUiTextObject = CalistaUiComponentTranslationText()
  const [countryCodeField, , { setValue: setCountryCodeField }] = useField('countryCode')

  function contactStatus() {
    if (currentContact.status) {
      if (currentContact.addressStatus == 'Active') {
        return (
          <CngGridItem xs={12} sm={4} >
            <LabelValueVertical
              boxClass={'ab-bg-active'}
              label={calistaUiTextObject.status}
              value={
                <div className={'ab-text-green'}>{currentContact.addressStatus}</div>
              }
            />
          </CngGridItem>

        );
      }
      else {
        return (
          <CngGridItem xs={12} sm={4} >
            <LabelValueVertical
              boxClass={'ab-bg-inactive'}
              label={calistaUiTextObject.status}
              value={
                <div className={'ab-text-red'}>{currentContact.addressStatus}</div>
              }
            />
          </CngGridItem>
        );
      }
    }
    else {
      return (
        <CngGridItem xs={12} sm={4} >
          <LabelValueVertical
            boxClass={'ab-bg-inactive'}
            label={calistaUiTextObject.status}
            value={
              <div className={'ab-text-red'}>Draft</div>
            }
          />
        </CngGridItem>
      );
    }
  }



  return (
    <Box>
      <Grid container className='accordian-header'>
        <CngGridItem xs={6} sm={6}>
          <Box p={1} fontSize='large' fontWeight='bolder'>
            {translatedTextsObject.contactDetails}
          </Box>
        </CngGridItem>
      </Grid>

      <Card className='inner-card-group'>
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={4}>
            <LabelValueVertical
              label={translatedTextsObject.category}
              value={currentContact.category.categoryCode}
            />
            {/* <CngCodeMasterAutocompleteField
              name='category'
              label={translatedTextsObject.category}
              codeType='CAL_ADD_BK_CAT'
            /> */}
          </CngGridItem>
          <CngGridItem xs={12} sm={4}>
            <LabelValueVertical
              label={translatedTextsObject.contactName}
              value={currentContact.name}
            />
            {/* <CngTextField
              type='text'
              name='name'
              inputProps={{ maxLength: 100 }}
              label={translatedTextsObject.contactName}
            /> */}
          </CngGridItem>
          {contactStatus()}

          <CngGridItem xs={12} sm={4}>
            <LabelValueVertical
              label={translatedTextsObject.emailId}
              value={currentContact.addressEmail}
            />
            {/* <CngTextField
              type='text'
              name='addrEmail'
              inputProps={{ maxLength: 100 }}
              label={translatedTextsObject.emailId}
            /> */}
          </CngGridItem>
          <CngGridItem xs={12} sm={4}>
            <LabelValueVertical
              label={translatedTextsObject.contactNo}
              value={currentContact.addressContactNo}
            />
            {/* <CngTextField
              type='text'
              name='addrContactNo'
              inputProps={{ maxLength: 100 }}
              label={translatedTextsObject.contactNo}
            /> */}
          </CngGridItem>
          <CngGridItem xs={4} sm={4}>
            {currentContact.countryCode != null ?
              (<Box className='view-card-content'>
                <Box className='view-card-content-label'>
                  {translatedTextsObject.country}
                </Box>
                <Box
                  className='view-card-content-value'
                  style={{ paddingRight: 0 }}
                >
                  <CountryIconViewField countryCode={currentContact.countryCode} />
                </Box>
              </Box>)
              :
              <LabelValueVertical
                label={translatedTextsObject.country}
                value='-'
              />
            }
          </CngGridItem>
          {/*
          <CngGridItem xs={4} sm={4}>
           <CountrySelectAutoComplete
              name='countryCode'
              label={translatedTextsObject.country}
              //keyRef={countryCodeRef}
              value={countryCodeField.value}
              onChangeAutoComplete={onChangeCountryAutoComplete}
            /> 
          </CngGridItem>*/}
          <CngGridItem xs={12} sm={4}>
            {/* <CngTextField
              type='text'
              name='state'
              inputProps={{ maxLength: 100 }}
              label={translatedTextsObject.state}
            /> */}
            <LabelValueVertical
              label={translatedTextsObject.state}
              value={currentContact.state}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4}>
            {/* <CngTextField
              type='text'
              name='postalCode'
              inputProps={{ maxLength: 20 }}
              label={translatedTextsObject.postalCode}
            /> */}
            <LabelValueVertical
              label={translatedTextsObject.postalCode}
              value={currentContact.postalCode}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4}>
            <LabelValueVertical
              label={translatedTextsObject.address}
              value={currentContact.address}
            />
            {/* <CngTextField
              type='text'
              name='address'
              multiline
              rows={4}
              inputProps={{ maxLength: 512 }}
              label={translatedTextsObject.address}
            /> */}
          </CngGridItem>
        </Grid>
      </Card>
    </Box >
  )
}

const ViewCompanyContactDetailsComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewCompanyContactDetailsComponent
