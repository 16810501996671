
import React from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import { Download, PlusCircle } from 'react-feather'
import { components } from 'cng-web-lib'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import AddressBookDialog from '../../../shared/dialog';

const {
  form: {
    field: { CngTextField, CngSelectField }
  },
  dropzone: {
    CngFileUpload
  },
  CngGridItem,
  button: { CngPrimaryButton }
} = components



function BulkUploadContactButtonComponent(props) {
 
  const translatedTextsObject = CalistaUiComponentTranslationText();

  return (
    <Grid container xs={12} sm={12} justify='flex-end'>
      <Box pr={2}>
        <Button
          variant='contained'
          onClick={() => {props.handleDownloadTemplate()}}
          color='secondary'
          startIcon={<Download style={{ marginRight: 5 }} />}
          classes={{ root: 'ng-button-filled-secondary' }}
          
        >

          {translatedTextsObject.templateExcel}
        </Button>
      </Box>
      <Box pr={2}>
        <Button
          variant='contained'
          onClick={() => {
            props.handleUploadBulkTemplate(AddressBookDialog.BULK_UPLOAD_DIALOG)
          }}
          startIcon={<PlusCircle style={{ marginRight: 5 }} />}
          className='button-blue originalText'
        >
          {translatedTextsObject.uploadFile}
        </Button>
      </Box>
    </Grid>
  )
}

export default BulkUploadContactButtonComponent
