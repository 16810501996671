import React, { useState, useContext, useEffect, useRef } from 'react'
import {
  Grid,
  Box,
  Card,
  Accordion,
  AccordionSummary,
  Paper
} from '@material-ui/core'

import { components, useServices } from 'cng-web-lib'
import AccordionHeaderComponent from '../../../../common/ui/AccordionHeaderComponent'

import SubmitButton from 'src/components/button/SubmitButton.js'
import DiscardButton from 'src/components/button/DiscardButton.js'
import ResetButton from 'src/components/button/ResetButton'

import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls'
import CompanyPreferenceTranslationText from '../../../shared/CompanyPreferenceTranslationText'
import AddressBookApi from '../../../shared/api'

import EditPartyTemplateContext from './EditPartyTemplateContext'
import useCreatePartyTemplateValidationSchema from './useCreatePartyTemplateValidationSchema'

const {
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter: { useFormikContext }
    },
    field: { CngTextField, CngSelectField }
  },
  table: { useDefaultNotification },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  tmpltName: '',
  shpId: null,
  dstId: null,
  conId: null
})

const initialValues = Object.freeze({
  ...DEFAULT_INITIAL_VALUES
})

function AddPartyTemplateForm(props) {
  const dialogContext = useContext(EditPartyTemplateContext)

  const translatedTextObjects = CompanyPreferenceTranslationText()
  const { createRecord } = useServices()

  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting },
    currentTemplate,
    setPreviousDialog
  } = dialogContext

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    console.log('Saving Party Template')
    console.log(data)
    data.partyId = props.partyId

    function onSuccess(response) {
      console.log('onSuccess', response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      }

      showSuccessNotification(translatedTextObjects.notifyPartyTemplateSaved)
      closeDialog(true, false)
    }
    function onError(error) {
      console.log('onError', error)
      showErrorNotification('Error saving template - ' + error)
    }

    createRecord.execute(
      CompanyPreferenceApiUrls.PARTY_TEMPLATE_CREATE_OR_UPDATE,
      data,
      onSuccess,
      onError
    )
  }

  const validationSchema = useCreatePartyTemplateValidationSchema()

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          showNotification={showNotification}
          isSubmitting={isSubmitting}
          currentTemplate={currentTemplate}
          setPreviousDialog={setPreviousDialog}
          partyId={props.partyId}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}
function FormBody({
  closeDialog,
  showNotification,
  isSubmitting,
  currentTemplate,
  setPreviousDialog,
  partyId
}) {
  const { fetchRecords } = useServices()
  const { error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const translatedTextObjects = CompanyPreferenceTranslationText()

  const [shipperList, setShipperList] = useState([])
  const [consigneeList, setConsigneeList] = useState([])
  const [notifyPartyList, setNotifyPartyList] = useState([])

  const [selectedShipper, setSelectedShipper] = useState('')
  const [selectedConsignee, setSelectedConsignee] = useState('')
  const [selectedNotifyParty, setSelectedNotifyParty] = useState('')

  const { setFieldValue } = useFormikContext()
  const isEdit = useRef(false)

  const resetForm = () => {
    console.log('reset')
    console.log(DEFAULT_INITIAL_VALUES)

    const dataArr = Object.entries(DEFAULT_INITIAL_VALUES)
    dataArr.forEach(([key, val]) => {
      setFieldValue(key, val)
    })
    setSelectedShipper('')
    setSelectedConsignee('')
    setSelectedNotifyParty('')
  }

  useEffect(() => {
    console.log(currentTemplate)
    populateDropdownList()
  }, [currentTemplate])

  function populatePartyTemplateFields() {
    console.log('populatePartyTemplateFields')
    console.log(currentTemplate)

    setSelectedShipper(currentTemplate.shpId)
    setSelectedConsignee(currentTemplate.conId)
    setSelectedNotifyParty(currentTemplate.dstId)

    const dataArr = Object.entries(currentTemplate)
    dataArr.forEach(([key, val]) => {
      setFieldValue(key, val)
    })
    isEdit.current = true
  }

  const onDropdownChange = (e) => {
    console.log('e.target', e.target)
    console.log('e.target.value', e.target.value)

    if (e.target.value === '') {
      return
    }
    if (e.target.name === 'conId') {
      setSelectedConsignee(e.target.value)
      setFieldValue(e.target.name, e.target.value)
    } else if (e.target.name === 'shpId') {
      setSelectedShipper(e.target.value)
      setFieldValue(e.target.name, e.target.value)
    } else if (e.target.name === 'dstId') {
      setSelectedNotifyParty(e.target.value)
      setFieldValue(e.target.name, e.target.value)
    }
  }

  function populateDropdownList() {
    console.log('populateDropdownList')
    setShipperList([])
    setConsigneeList([])
    // setRepresentativeList([]);
    setNotifyPartyList([])

    AddressBookApi.fetchPartyTemplateDropdownList(
      fetchRecords,
      {
        baseFilterDTO: {
          filterType: 'AND',
          filterProperties: [
            {
              fieldName: 'partyId',
              operatorType: 'EQUAL',
              value1: partyId
            }
          ]
        }
      },
      (data) => {
        console.log(data)
        let shipperTemp = shipperList
        let consigneeTemp = consigneeList
        // let repreTemp = representativeList;
        let notifyTemp = notifyPartyList

        shipperTemp.push({
          value: null,
          text: '<Blank>'
        })
        consigneeTemp.push({
          value: null,
          text: '<Blank>'
        })
        notifyTemp.push({
          value: null,
          text: '<Blank>'
        })
        data.content
          .filter((e) => e.category === 'SHIPPER')
          .map((e, i) => {
            shipperTemp.push({
              value: e.id,
              text: e.name
            })
          })
        data.content
          .filter((e) => e.category === 'CONSIGNEE')
          .map((e, i) => {
            consigneeTemp.push({
              value: e.id,
              text: e.name
            })
          })
        data.content
          .filter((e) => e.category === 'NOTIFY PARTY')
          .map((e, i) => {
            notifyTemp.push({
              value: e.id,
              text: e.name
            })
          })

        console.log('shipperTemp', shipperTemp)
        console.log('consigneeTemp', consigneeTemp)
        console.log('notifyTemp', notifyTemp)

        setShipperList(shipperTemp)
        setConsigneeList(consigneeTemp)
        setNotifyPartyList(notifyTemp)

        if (currentTemplate != null) {
          populatePartyTemplateFields()
        }
      },
      (error) => {
        console.log(error)
        showErrorNotification('Error getting dropdown lists - ' + error)
      }
    )
  }

  const trimStrings = (event) => {
    setFieldValue(event.target.name, event.target.value.trim())
  }

  return (
    <Paper>
      <Box m={1.5}>
        <Box pt={1}>
          <Grid container className='accordian-header'>
            <CngGridItem xs={6} sm={6}>
              <Box p={1} fontSize='large' fontWeight='bolder'>
                Template Details
              </Box>
            </CngGridItem>
          </Grid>

          <Card className='inner-card-group'>
            <Grid container spacing={2}>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='tmpltName'
                  inputProps={{ maxLength: 100 }}
                  label='Template Name'
                  onBlur={trimStrings}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={12}>
                <Box pt={1}>
                  <Accordion
                    expanded={true}
                    style={{ backgroundColor: '#5E81F405' }}
                  >
                    <AccordionSummary
                      aria-controls='nominateContactContent'
                      id='nominateContactHeader'
                    >
                      <Grid container>
                        <CngGridItem xs={12} sm={12}>
                          <AccordionHeaderComponent
                            title='Nominate Contacts'
                            hideMandatory
                          />
                          <Box fontSize={10} paddingBottom={2}></Box>
                        </CngGridItem>

                        <Grid
                          container
                          xs={12}
                          sm={12}
                          spacing={2}
                          alignItems='center'
                        >
                          <CngGridItem xs={4} sm={4}>
                            <CngSelectField
                              name='shpId'
                              label='Shipper Name'
                              value={selectedShipper}
                              items={shipperList}
                              onChange={onDropdownChange}
                            />
                          </CngGridItem>
                          <CngGridItem xs={4} sm={4}>
                            <CngSelectField
                              name='conId'
                              label='Consignee Name'
                              value={selectedConsignee}
                              items={consigneeList}
                              onChange={onDropdownChange}
                            />
                          </CngGridItem>
                          <CngGridItem xs={4} sm={4}>
                            <CngSelectField
                              name='dstId'
                              label='Notify Party Name'
                              value={selectedNotifyParty}
                              items={notifyPartyList}
                              onChange={onDropdownChange}
                            />
                          </CngGridItem>
                          {/* <CngGridItem xs={4} sm={4}>
                                <CngSelectField
                                    name='repId'
                                    label='Representative Name'
                                    // value={selectedRepresentative}
                                    items={representativeList}
                                    onChange={onDropdownChange}
                                />
                            </CngGridItem> */}
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                  </Accordion>
                </Box>
              </CngGridItem>
            </Grid>
          </Card>
        </Box>

        <Box display='flex' mt={2}>
          <Grid xs={6} sm={6} justify='flex-start'>
            <ResetButton onClick={resetForm} disabled={isSubmitting} />
          </Grid>

          <Grid container xs={6} sm={6} justify='flex-end'>
            <Box pr={2}>
              <DiscardButton
                onClick={() => {
                  closeDialog(false, false)
                  setPreviousDialog(null)
                }}
                disabled={isSubmitting}
              />
            </Box>
            <Box>
              <SubmitButton isSubmitting={isSubmitting}>
                {currentTemplate
                  ? translatedTextObjects.saveChanges
                  : translatedTextObjects.saveTemplate}
              </SubmitButton>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Paper>
  )
}

export default AddPartyTemplateForm
