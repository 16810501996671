//import ValidationMessageTranslationText from 'src/views/common/CommonValidationTranslationText'
import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/views/freightbooking/shared/validation/ValidationRegex'
import ValidationMessageTranslationText from 'src/views/freightbooking/shared/validation/ValidationMessageTranslationText'

function useCreatePartyTemplateValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({
    tmpltName: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable()
  })
}

export default useCreatePartyTemplateValidationSchema
