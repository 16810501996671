import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import Paper from '@material-ui/core/Paper'

import EditPartyTemplateContext from './EditPartyTemplateContext.js'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import ViewPartyTemplate from './ViewPartyTemplate'

const {
  CngDialog,
  dropzone: { CngFileUpload },
  table: { useDefaultNotification }
} = components

function ViewPartyTemplateDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  currentTemplate,
  editPartyTemplate,
  openDialog,
  setPreviousDialog
}) {
  const [isSubmitting, setSubmitting] = useState(false)

  return (
    <EditPartyTemplateContext.Provider
      value={{
        closeDialog,
        showNotification,
        currentTemplate,
        editPartyTemplate,
        openDialog,
        setPreviousDialog,
        form: {
          isSubmitting,
          setSubmitting
        }
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow: 'hidden' }}>
              <ViewPartyTemplate
                editPartyTemplate={editPartyTemplate}
                openDialog={openDialog}
                setPreviousDialog={setPreviousDialog}
              />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <b>View template</b>
            <CloseDialogIconButton
              onClick={() => {
                closeDialog(false, false)
                setPreviousDialog(null)
              }}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </EditPartyTemplateContext.Provider>
  )
}

export default ViewPartyTemplateDialog
