import {
    Box,
    Grid,
    Typography,
} from '@material-ui/core'
import React from 'react'

function AddressBookNoResult(props) {


    return (
        <div className={'vs-noresult'}>
            <img
                src={
                    process.env.PUBLIC_URL + '/static/images/shippingInstruction/AddressBook_Empty.svg'
                }
                alt='No Result'
            ></img>
            <Typography variant='h3' style={{ fontWeight: 'bold', paddingTop: '10px' }}>
                {props.title}

            </Typography>
            <Typography variant='subtitle1' style={{ paddingTop: '10px', color: '#808080' }}>
                {props.desc}
            </Typography>

            <Box pt={2}>
                <Grid container>
                    {props.buttonPanel}
                </Grid>
            </Box>
        </div >

    )
}

export default AddressBookNoResult;