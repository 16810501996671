import React, { useState } from 'react';
import { Paper } from '@material-ui/core';

import { components } from 'cng-web-lib';

import EditContactDetailsContext from './EditContactDetailsContext';
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js';
import AddContactSIForm from './AddContactSIForm';

import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText';


const {
    CngDialog,
    table: { useDefaultNotification }
} = components;

function AddContactSIDialog({
    isDialogOpen,
    closeDialog,
    showNotification,
    contactType,
    doRefresh
}) {
    const translatedTextsObject = CompanyPreferenceTranslationText()
    const [isSubmitting, setSubmitting] = useState(false);

    return (
        <EditContactDetailsContext.Provider
            value={{
                closeDialog,
                showNotification,
                contactType,
                doRefresh
            }}
        >
            <CngDialog
                customDialogContent={
                    <Paper>
                        <div style={{ overflow:'hidden' }}>
                        <AddContactSIForm
                            contactType={contactType}
                            doRefresh={doRefresh}
                            isSubmitting={isSubmitting}
                            setSubmitting={setSubmitting}
                        />
                        </div>
                    </Paper>
                }
                dialogTitle={
                    <>
                        <b>
                            {translatedTextsObject.addressBook}
                        </b>
                        <CloseDialogIconButton
                            onClick={closeDialog}
                            disabled={isSubmitting}
                        />
                    </>
                }
                open={isDialogOpen}
                fullWidth
                maxWidth='lg'
            />


        </EditContactDetailsContext.Provider >
    )
}

export default AddContactSIDialog;