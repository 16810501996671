import React, { useContext, useEffect, useRef } from 'react'
import { Grid, Box } from '@material-ui/core'
import { useServices, components } from 'cng-web-lib'

import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

import SubmitButton from 'src/components/button/SubmitButton.js'
import DiscardButton from 'src/components/button/DiscardButton.js'
import ResetButton from 'src/components/button/ResetButton'

import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls'
import EditContactDetailsContext from './EditContactDetailsContext.js'

import CompanyContactDetailsComponent from './CompanyContactDetailsComponent'
import ConnectedPartyTemplateComponent from '../ConnectedPartyTemplateComponent'
import useAddCompanyContactValidationSchema from './useAddCompanyContactValidationSchema'

const {
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  table: { useDefaultNotification }
} = components

function AddContactForm(props) {
  const dialogContext = useContext(EditContactDetailsContext)

  const { createRecord } = useServices()
  const { updateRecord } = useServices()

  const {
    closeDialog,
    showNotification,
    form: { isSubmitting },
    currentContact,
    role,
    partyId
  } = dialogContext

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    console.log('submit ')
    console.log(data)
    if (data.addressStatus) {
      data.addressStatus = 'Active'
    } else {
      data.addressStatus = 'Inactive'
    }
    data.partyId = partyId

    console.log(currentContact)

    if (currentContact) {
      console.log('editing')
      if (!data.status) {
        data.status = true
      }

      updateRecord.execute(
        CompanyPreferenceApiUrls.UPDATE_CONTACT,
        data,
        onSuccess,
        onError
      )
    } else {
      console.log('adding')
      createRecord.execute(
        CompanyPreferenceApiUrls.ADD_CONTACT,
        data,
        onSuccess,
        onError
      )
    }

    function onSuccess(response) {
      console.log('success')
      showSuccessNotification('Contact Saved')
      closeDialog(true, false)
    }

    function onError(error) {
      console.log('error')
      console.log(error)
      showErrorNotification('Error - ' + error)
      closeDialog(true, false)
    }
  }

  const validationSchema = useAddCompanyContactValidationSchema()
  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          currentContact={currentContact}
          role={role}
          partyId={partyId}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({
  closeDialog,
  isSubmitting,
  currentContact,
  role,
  partyId
}) {
  const companyPreferenceTranslationObject = CompanyPreferenceTranslationText()
  const { setFieldValue } = useFormikContext()
  const isEdit = useRef(false)

  const [, , { setValue: setCategoryField }] = useField('category')

  const resetForm = () => {
    let dataArr = {}
    if (currentContact != undefined) {
      dataArr = Object.entries(currentContact)
      dataArr.forEach(([key, val]) => {
        if (val == null) {
          setFieldValue(key, "")
        }
        else if (key == 'category') {
          setFieldValue(key, val.categoryCode)
          setCategoryField(val.categoryCode)
        } else if (key == 'addressStatus') {
          setFieldValue(key, val == 'Active' ? true : false)
        } else {
          setFieldValue(key, val)
        }
      })
    } else {
      dataArr = Object.entries(DEFAULT_INITIAL_VALUES)
      dataArr.forEach(([key, val]) => {
        setFieldValue(key, val)
      })
    }
    if (role == 'CARRIER') {
      setFieldValue('category', 'AGENT')
    }
  }

  useEffect(() => {
    if (currentContact != undefined) {
      populateContactFields()
      setFieldValue('category', currentContact.category.categoryCode)
    }
  }, [currentContact])

  const populateContactFields = () => {
    isEdit.current = true
    const dataArr = Object.entries(currentContact)
    dataArr.forEach(([key, val]) => {
      if (key == 'category') {
        setFieldValue(key, val.categoryCode)
        setCategoryField(val.categoryCode)
      } else if (key == 'addressStatus') {
        setFieldValue(key, val == 'Active' ? true : false)
      } else {
        setFieldValue(key, val)
      }
    })
  }

  function renderConnectedParty() {
    if (currentContact) {
      if (
        currentContact.category.categoryCode == 'CONSIGNEE' ||
        currentContact.category.categoryCode == 'NOTIFY PARTY' ||
        currentContact.category.categoryCode == 'SHIPPER'
      )
        return (
          <Box pt={1}>
            <ConnectedPartyTemplateComponent.FormBody
              disabled
              currentContact={currentContact}
            />
          </Box>
        )
    }
  }
  return (
    <Box m={1.5}>
      <Box pt={1}>
        <CompanyContactDetailsComponent.FormBody role={role} />
      </Box>
      {renderConnectedParty()}

      <Box display='flex' mt={2}>
        <Grid xs={6} sm={6} justify='flex-start'>
          <ResetButton onClick={resetForm} disabled={isSubmitting} />
        </Grid>

        <Grid container xs={6} sm={6} justify='flex-end'>
          <Box pr={2}>
            <DiscardButton onClick={closeDialog} disabled={isSubmitting} />
          </Box>
          <Box>
            <SubmitButton isSubmitting={isSubmitting}>
              {companyPreferenceTranslationObject.saveContact}
            </SubmitButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...CompanyContactDetailsComponent.initialValues,
  ...ConnectedPartyTemplateComponent.initialValues
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

export default AddContactForm
