
import React from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import RestoreIcon from '@material-ui/icons/Restore'
import { components } from 'cng-web-lib'

import CompanyPreferenceTranslationText from '../../../shared/CompanyPreferenceTranslationText'

const {
    form: {
        field: { CngTextField, CngSelectField }
    },
    dropzone: {
        CngFileUpload
    },
    CngGridItem,
    button: { CngPrimaryButton }
} = components



function FilterClearButtonComponent(props) {
    const translatedTextsObject = CompanyPreferenceTranslationText()

    return (
        <Grid container xs={12} sm={12} justify='flex-end'>
            <Box pr={2}>
                <Button
                    variant='contained'
                    color='secondary'
                    name='clear'
                    startIcon={<RestoreIcon />}
                    className={'ng-button-filled-secondary'}
                    size='small'
                    onClick={props.handleResetFilter}
                >
                    {translatedTextsObject.filterResetFilters}
                </Button>
            </Box>
        </Grid>
    )
}

export default FilterClearButtonComponent
