import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import Paper from '@material-ui/core/Paper'

import BulkUploadContext from './BulkUploadContext.js'
import AddressBookDialog from '../../../shared/dialog'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import BulkUploadForm from './BulkUploadForm'

const {
  CngDialog,
  dropzone: { CngFileUpload },
  table: { useDefaultNotification }
} = components

function BulkUploadDialog({
  isDialogOpen,
  closeDialog,
  openDialog,
  showNotification,
  setPreviousDialog
}) {
  const [isSubmitting, setSubmitting] = useState(false)

  function confirmClose() {
    setPreviousDialog(AddressBookDialog.BULK_UPLOAD_DIALOG)
    closeDialog(false)
    openDialog(AddressBookDialog.DISCARD_BULK_UPLOAD_CONFIRM)
  }

  return (
    <BulkUploadContext.Provider
      value={{
        closeDialog,
        openDialog,
        showNotification,
        setPreviousDialog,
        form: {
          isSubmitting,
          setSubmitting
        }
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow: 'hidden' }}>
              <BulkUploadForm setPreviousDialog={setPreviousDialog} />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <b>Upload file template</b>
            <CloseDialogIconButton
              onClick={(e) => {
                closeDialog(false)
                setPreviousDialog(AddressBookDialog.BULK_UPLOAD_DIALOG)
                openDialog(AddressBookDialog.DISCARD_BULK_UPLOAD_CONFIRM)
              }}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </BulkUploadContext.Provider>
  )
}

export default BulkUploadDialog
