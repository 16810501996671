import React, { useState, useContext, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';

import { components, useServices } from 'cng-web-lib';
import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls';
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText';

import SubmitButton from 'src/components/button/SubmitButton.js'
import DiscardButton from 'src/components/button/DiscardButton.js'

import AddressBookDialog from '../../../shared/dialog';
import BulkUploadContext from './BulkUploadContext';

import BulkUploadDocumentDetails from './BulkUploadDocumentDetails';


const {
    form: {
        CngViewForm,
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        }
    },
    CngGridItem,
    dropzone: {
        CngFileUpload,
        HelperTextArea
    },
    table: { useDefaultNotification }
} = components;



function BulkUploadForm(props) {
    const translatedTextObjects = CompanyPreferenceTranslationText();

    const dialogContext = useContext(BulkUploadContext);

    const [pendingFiles, setPendingFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [batchNo, setBatchNo] = useState('');
    const [pollingCount, setPollingCount] = useState(0);

    const [pendingFilesField, , { setValue: setPendingFilesField }] = useField('pendingFiles');
    const [, , { setValue: setUploadedFilesField }] = useField('uploadedFiles');

    const [allValidated, setAllValidated] = useState(false);

    const {
        closeDialog,
        openDialog,
        showNotification,
        setPreviousDialog,
        form: { isSubmitting, setSubmitting },
    } = dialogContext;

    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(showNotification);

    const { createRecord, updateRecord, fetchRecords, securedSendRequest, deleteRecord } = useServices();

    useEffect(() => {
        console.log(uploadedFiles);
    });

    useEffect(() => {
        if (pollingCount !== 0 && batchNo !== '') {
            console.log(batchNo);
            setTimeout(() => {
                fetchRecords.execute(
                    CompanyPreferenceApiUrls.BULK_UPLOAD_DOCUMENT_QUERY,
                    {
                        customData: { batchNo: batchNo }
                    },
                    (data) => {
                        console.log('From API');
                        console.log(data);
                        let fromApi = data;
                        let newlyUploadedFiles = fromApi.map((file) => {
                            console.log(file);

                            return {
                                ...uploadedFiles.find((doc) => doc.id === file.id),
                                status: file.status,
                                version: file.version
                            }
                        })


                        let validatedFiles = newlyUploadedFiles.filter((file) =>
                            file.status === 'Completed' || file.status === 'Error'
                        );
                        if (validatedFiles.length === uploadedFiles.length) {
                            console.log(validatedFiles.length + "==" + uploadedFiles.length);
                            setAllValidated(true);
                        }
                        else {
                            setAllValidated(false);
                        }

                        setUploadedFiles(newlyUploadedFiles);
                        setUploadedFilesField(newlyUploadedFiles);

                        if (newlyUploadedFiles.filter((doc) => doc.status === 'Pending' || doc.status === 'Processing').length > 0) {
                            setPollingCount(pollingCount + 1);
                        }

                    },
                    (error) => {
                        console.log(error);
                        setPollingCount(pollingCount + 1);
                    }
                )
            }, 1000);
        }
    }, [pollingCount]);

    function checkUploadedAndValidated(fileList) {
        if (fileList.length == 0) {
            setAllValidated(false);
        } else {
            let validatedFiles = fileList.filter((file) =>
                file.status === 'Completed' || file.status === 'Error'
            );
            if (validatedFiles.length === fileList.length) {
                console.log(validatedFiles.length + "==" + fileList.length);
                setAllValidated(true);
            }
            else {
                setAllValidated(false);
            }
        }
    }


    function uploadAndValidate(data) {
        console.log("Uploading bulk contact templates");
        console.log(pendingFiles);
        if (pendingFiles.length < 1) {
            showErrorNotification(translatedTextObjects.notifyBulkUploadNoFileError);
            return;
        }
        setPendingFiles([]);
        var formData = new FormData();

        formData.append('batchNo', '');

        pendingFilesField.value.forEach((file, i) => {
            var fileBlob = file.file;
            console.log(fileBlob);
            formData.append("fileContents", fileBlob);
        });

        console.log(pendingFiles);

        function onSuccess(response) {
            console.log("onSuccess", response);
            if (response.errorMessages != null) {
                showErrorNotification(response.errorMessages);
            }

            setBatchNo(response[0].batchNo);
            setUploadedFiles(response);
            setPollingCount(pollingCount + 1);
            console.log('uploadedFiles');
            console.log(uploadedFiles);
            showSuccessNotification(translatedTextObjects.notifyBulkUploadFileUploaded);
        }
        function onError(error) {
            console.log("onError", error);
            showErrorNotification('File upload error - ' + error.message);
        }

        createRecord.execute(
            CompanyPreferenceApiUrls.BULK_UPLOAD_UPLOAD,
            formData,
            onSuccess,
            onError
        );
    }

    function saveFiles(e) {
        e.preventDefault();
        setSubmitting(true);
        console.log("Save bulk contact templates");
        console.log(uploadedFiles);

        uploadedFiles.forEach((file) => {
            let updateFields = {
                batchNo: file.batchNo,
                fileName: file.fileName,
                active: true,
                status: file.status,
                id: file.id,
                version: file.version
            }
            console.log(updateFields);

            updateRecord.execute(
                CompanyPreferenceApiUrls.BULK_UPLOAD_DOCUMENT_UPDATE,
                updateFields,
                onSuccess,
                onError
            );

        });
        function onSuccess(response) {
            console.log("onSuccess", response);
            if (response.errorMessages != null) {
                showErrorNotification(response.errorMessages);
            }
            showSuccessNotification(translatedTextObjects.notifyBulkUploadFileSaved)
            closeDialog(true);
            setSubmitting(false);

        }
        function onError(error) {
            console.log("onError", error);
            showErrorNotification(error);
            setSubmitting(false);
        }
    }

    function handleDeleteFile(i) {
        console.log("Remove File");
        console.log(uploadedFiles[i]);

        let params = {
            id: uploadedFiles[i].id,
            version: uploadedFiles[i].version
        }
        console.log(params);
        deleteRecord.execute(
            CompanyPreferenceApiUrls.BULK_UPLOAD_DELETE,
            params,
            (data) => {
                console.log(data);
                showSuccessNotification('File deleted');

                let newUploadedFiles = uploadedFiles.filter((file) => data.id !== file.id);
                console.log("newUploadedFiles", newUploadedFiles);
                setUploadedFiles(newUploadedFiles);
                checkUploadedAndValidated(newUploadedFiles);
            },
            (error) => {
                console.log(error);
                showErrorNotification('Error deleting file - ' + error.message);
            }
        );
    }

    function handleDownloadLog(i) {
        console.log("Download Log");
        console.log(uploadedFiles[i]);

        let params = {
            id: uploadedFiles[i].id
        }
        console.log(params);
        securedSendRequest.execute(
            "POST",
            CompanyPreferenceApiUrls.BULK_UPLOAD_DOWNLOAD_LOG,
            params,
            (data) => {
                console.log(data);
                var blob = data.data;
                let url = window.URL.createObjectURL(blob);

                let link = document.createElement('a');
                link.href = url;
                link.download = "ErrorLog - " + uploadedFiles[i].fileName + ".txt";

                link.click();

                showSuccessNotification('Error log downloaded');
            },
            (error) => {
                console.log(error);
                showErrorNotification('Error downloading error log - ' + error.message);
            },
            () => {
                console.log('completed');
            },
            { responseType: "blob" }
        );
    }

    function handleDownloadFile(i) {
        console.log("Download File");
        console.log(uploadedFiles[i]);

        let params = {
            id: uploadedFiles[i].id
        }
        console.log(params);

        securedSendRequest.execute(
            "POST",
            CompanyPreferenceApiUrls.BULK_UPLOAD_DOWNLOAD_FILE,
            params,
            (data) => {
                console.log(data);
                var blob = data.data;
                let url = window.URL.createObjectURL(blob);

                let link = document.createElement('a');
                link.href = url;
                link.download = uploadedFiles[i].fileName;

                link.click();
                showSuccessNotification('File downloaded');
            },
            (error) => {
                console.log(error);
                showErrorNotification('Error downloading file - ' + error.message);
            },
            () => {
                console.log('completed');
            },
            { responseType: "blob" }
        );
    }

    const showErrorMessages = (errorMessages) => {
        showErrorNotification(errorMessages[0]);
    }

    const moreActions = [
        {
            action: 'remove',
            name: 'Delete',
            icon: ['fal', 'trash']
        },
    ]

    function confirmClose() {
        setPreviousDialog(AddressBookDialog.BULK_UPLOAD_DIALOG);
        closeDialog(false);
        openDialog(AddressBookDialog.DISCARD_BULK_UPLOAD_CONFIRM);
    }


    return (

        <CngViewForm
            formikProps={{
                initialValues: {
                    ...initialValues
                },
                onSubmit: uploadAndValidate,
                validateOnChange: false
            }}
            bodySection={
                <Box m={1.5}>
                    <Box pt={1}>
                        <Grid container xs={12} sm={12} justify='center'>
                            <CngGridItem xs={12} sm={12}>
                                <BulkUploadDocumentDetails
                                    uploadedFiles={uploadedFiles}
                                    batchNo={batchNo}
                                    hideEdit={true}
                                    hideDelete={false}
                                    removeRowClick={handleDeleteFile}
                                    downloadRowClick={handleDownloadFile}
                                    downloadLogClick={handleDownloadLog}
                                />
                            </CngGridItem>

                            <CngGridItem xs={12} sm={12}>
                                <CngFileUpload
                                    accept={['.xlsx', '.xls']}
                                    maxSize={2097152}
                                    maxFiles={5}
                                    files={pendingFiles}
                                    onFileSelect={selectedFileList => {
                                        console.log("selected file list: " + JSON.stringify(selectedFileList))
                                        setPendingFiles(selectedFileList);
                                        setPendingFilesField(selectedFileList);

                                    }}
                                    showFormFields={false}
                                    fieldConfig={[]}
                                    moreActions={moreActions}
                                    onDropRejected={e => showErrorMessages(e)}
                                    renderHelperText={() => {
                                        return (
                                            <HelperTextArea
                                                accept={['.xlsx', '.xls']}
                                                maxFiles={5}
                                                maxSize={2097152}
                                            />
                                        )
                                    }}
                                />
                            </CngGridItem>
                            <CngGridItem>
                                <Box>
                                    <SubmitButton
                                        disabled={(pendingFiles.length === 0)}>
                                        {translatedTextObjects.uploadAndValidate}
                                    </SubmitButton>
                                </Box>
                            </CngGridItem>
                        </Grid>
                    </Box>
                    <Box display='flex' mt={2}>
                        <Grid container xs={6} sm={6} justify='flex-start'>
                        </Grid>
                        <Grid container xs={6} sm={6} justify='flex-end'>
                            <Box pr={2}>
                                <DiscardButton onClick={(e) => {
                                    closeDialog(false);
                                    setPreviousDialog(AddressBookDialog.BULK_UPLOAD_DIALOG);
                                    openDialog(AddressBookDialog.DISCARD_BULK_UPLOAD_CONFIRM)
                                }}
                                    disabled={isSubmitting} />
                            </Box>
                            <Box>
                                <SubmitButton
                                    isSubmitting={isSubmitting}
                                    disabled={!allValidated}
                                    onClick={saveFiles}
                                >
                                    {translatedTextObjects.saveFile}
                                </SubmitButton>
                            </Box>
                        </Grid>
                    </Box>
                </Box>

            }
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        />
    )
}

const initialValues = {};


export default BulkUploadForm
