import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Grid } from '@material-ui/core'
import { Yup, components, useServices } from 'cng-web-lib'

import Utils from 'src/views/common/utils/Utils'
import CompanyPreferenceTranslationText from '../shared/CompanyPreferenceTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'

import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls'

import BulkUploadContactButtonComponent from './components/bulkupload/BulkUploadContactButtonComponent'
import PartyTemplateButtonComponent from './components/partytemplate/PartyTemplateButtonComponent'
import ShipperCompanyContactButtonComponent from './components/addressbook/ShipperCompanyContactButtonComponent'

import AddressBookNoResult from './components/AddressBookNoResult'
import AddressBookPageError from './components/AddressBookPageError'

import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import EditContactDetailsDialog from './components/addressbook/EditContactDetailsDialog'
import ViewContactDetailsDialog from './components/addressbook/ViewContactDetailsDialog'
import BulkUploadDialog from './components/bulkupload/BulkUploadDialog'
import AddPartyTemplateDialog from './components/partytemplate/AddPartyTemplateDialog'
import ViewPartyTemplateDialog from './components/partytemplate/ViewPartyTemplateDialog'

import AddressBookTabButtonComponent from './components/AddressBookTabButtonComponent'
import AddressBookFunctionButtonComponent from './components/AddressBookFunctionButtonComponent'

import AlphanumericFilterComponent from './components/filters/AlphanumericFilterComponent'
import DateRangeFilterPanelComponent from './components/filters/DateRangeFilterPanelComponent'
import FreeTextSearchComponent from './components/filters/FreeTextSearchComponent'
import ContactFilterComponent from './components//filters/ContactFilterComponent'

import CompanyContactTableComponent from './components/addressbook/CompanyContactTableComponent'
import BulkUploadTableComponent from './components/bulkupload/BulkUploadTableComponent'
import PartyTemplateTableComponent from './components/partytemplate/PartyTemplateTableComponent'

const DEFAULT_INITIAL_VALUES = Object.freeze({
  dateRange: '0',
  // pendingClicked: true,
  filterText: '',
  sortCode: '',
  companyContactCount: 0,
  bulkUploadCount: 0,
  partyTemplateCount: 0,
  // statusCodes: [
  //   CalistaConstant.BOOKING_STATUS_DRAFT,
  //   CalistaConstant.BOOKING_STATUS_AMENDED,
  //   CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL
  // ],
  status: {},
  category: {}
})

function DEFAULT_VALIDATION_SCHEMA(translate) {
  return Yup.object({})
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
  //makeValidationSchema: makeValidationSchema
}

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem,
  table: { useDefaultNotification }
} = components

// Util

const capitalizeString = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

const alphabetProcessing = (index) => {
  let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  if (index == 0) {
    return ''
  } else {
    return alphabet[index - 1]
  }
}

const addAllFilter = (array) => {
  array.push({
    code: 'ALL',
    desc: 'ALL',
    checked: true
  })
  return array
}

const addDraftFilter = (array) => {
  array.push({
    code: 'DRAFT',
    desc: 'Draft',
    checked: true
  })
  return array
}

function FormFields({ disabled, showNotification, isCarrier }) {
  // Default Values
  const defaultDateRange = '0'
  const defaultNameIndex = 0
  const VALUE_ALL = 'ALL'
  const VALUE_DRAFT = 'Draft'

  // Calista Variables
  const {
    fetchRecords,
    fetchPageableRecords,
    deleteRecord,
    securedSendRequest
  } = useServices()
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const translatedTextsObject = CompanyPreferenceTranslationText()
  const calistaUiTranslatedTextsObject = CalistaUiComponentTranslationText()

  // Filter Variables
  const dateRangeOptions = [
    { label: translatedTextsObject.dateFilterAll, value: '0' },
    { label: translatedTextsObject.dateFilterDay15, value: '15' },
    { label: translatedTextsObject.dateFilterDay30, value: '30' },
    { label: translatedTextsObject.dateFilterDay60, value: '60 ' }
  ]

  const [nameIndex, setNameIndex] = useState(0)

  const [filterText, setFilterText] = useState()
  const [filterTextField, , { setValue: setFilterTextField }] =
    useField('filterText')

  const [category, setCategory] = useState([])
  const [status, setStatus] = useState([])
  const [categoryCodes, setCategoryCodes] = useState(null)

  const [statusField, , { setValue: setStatusField }] = useField('status')
  const [categoryField, , { setValue: setCategoryField }] = useField('category')
  const [statusCodesField, , { setValue: setStatusCodesField }] =
    useField('statusCode')

  const [dateRangeField, , { setValue: setDateRangeField }] =
    useField('dateRange')

  const [role, setRole] = useState('')
  const [partyId, setPartyId] = useState()

  const [searchCriteria, setSearchCriteria] = useState({
    filterText: '',
    dateRange: parseInt(defaultDateRange, 10),
    statusCode: [],
    category: [],
    addressStatus: [],
    namePrefix: ''
  })

  // Control Variables
  const pageSize = 30
  const pageNumber = 0

  const [loading, setLoading] = useState(true)
  const [, setSearchCriteriaLoaded] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [pageError, setPageError] = useState(false)

  const [tabIndex, setTabIndex] = useState(0)
  const [alphaFilterEnable, setAlphaFilterEnable] = useState([])

  const [selectedObject, setSelectedObject] = useState()

  // Dialog Variables
  const [addContactDialogOpen, setAddContactDialogOpen] = useState(false)
  const [viewContactDialogOpen, setViewContactDialogOpen] = useState(false)
  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false)
  const [addPartyTemplateDialogOpen, setAddPartyTemplateDialogOpen] =
    useState(false)
  const [viewPartyTemplateDialogOpen, setViewPartyTemplateDialogOpen] =
    useState(false)
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false)
  const [confirmDiscardDialogOpen, setConfirmDiscardDialogOpen] =
    useState(false)

  const [previousDialog, setPreviousDialog] = useState(null)

  const [deleteDialogTitle, setDeleteDialogTitle] = useState('')
  const [deleteDialogMessage, setDeleteDialogMessage] = useState('')

  // Information & Filter Limit Variables
  const [totalCount, setTotalCount] = useState(0)
  const [filteredCount, setFilteredCount] = useState(0)

  const [companyContactCount, setCompanyContactCount] = useState(0)
  const [bulkUploadCount, setBulkUploadCount] = useState(0)
  const [partyTemplateCount, setPartyTemplateCount] = useState(0)

  const [companyContactList, setCompanyContactList] = useState([])
  const [bulkUploadList, setBulkUploadList] = useState([])
  const [partyTemplateList, setPartyTemplateList] = useState([])

  const initialCategory = [
    'AGENT',
    'NOTIFY PARTY',
    'PORT',
    'DELIVERY LOCATION',
    'CUSTOMS BROKER',
    'REPRESENTATIVE',
    'DEPOT',
    'CONSIGNEE',
    'SHIPPER'
  ]

  const initialStatus = ['0', '1']
  const initialAddressStatus = ['Active', 'Inactive']

  const refreshPage = () => {
    setRefresh(!refresh)
    setLoading(true)
  }

  useEffect(() => {
    setLoading(true)

    if (isCarrier) {
      setRole('CARRIER')
    } else {
      setRole('SHIPPER')
    }

    setCompanyContactList([])
    setBulkUploadList([])
    setPartyTemplateList([])
    setTotalCount(0)

    switch (tabIndex) {
      case 0:
        fetchRecords.execute(
          CompanyPreferenceApiUrls.COMPANY_CONTACT_SEARCH_CRITERIA,
          searchCriteria,
          (data) => {
            setCompanyContactCount(data.contactCount)
            setBulkUploadCount(data.bulkUploadCount)
            setPartyTemplateCount(data.partyTemplateCount)

            setTotalCount(data.contactCount)

            let tempCategory = []
            data.categoryList.map((e, i) => {
              tempCategory.push({
                code: e.categoryCode,
                desc: e.categoryDesc,
                checked: true
              })
            })
            addAllFilter(tempCategory)
            setCategory(
              Utils.isEmptyObject(categoryField.value)
                ? tempCategory
                : categoryField.value
            )

            let tempStatus = []
            data.statusList.map((e, i) => {
              tempStatus.push({
                code: e.statusCode,
                desc: capitalizeString(e.statusDesc),
                checked: true
              })
            })
            addDraftFilter(tempStatus)
            addAllFilter(tempStatus)

            setStatus(
              Utils.isEmptyObject(statusField.value)
                ? tempStatus
                : statusField.value
            )

            setPartyId(data.partyId)
            setAlphaFilterEnable(data.namePrefix)

            setSearchCriteriaLoaded(true)
            loadCompanyContacts()
          },
          (error) => {
            console.log(error)
            setPageError(true)
            onError(error)
          }
        )
        break
      case 1:
        fetchRecords.execute(
          CompanyPreferenceApiUrls.BULK_UPLOAD_SEARCH_CRITERIA,
          searchCriteria,
          (data) => {
            setCompanyContactCount(data.contactCount)
            setBulkUploadCount(data.bulkUploadCount)
            setPartyTemplateCount(data.partyTemplateCount)
            setTotalCount(data.bulkUploadCount)

            setAlphaFilterEnable(data.namePrefix)

            setSearchCriteriaLoaded(true)
            loadBulkUploads()
          },
          (error) => {
            console.log(error)
            setPageError(true)
            onError(error)
          }
        )
        break
      case 2:
        if (role == 'SHIPPER') {
          fetchRecords.execute(
            CompanyPreferenceApiUrls.PARTY_TEMPLATE_SEARCH_CRITERIA,
            searchCriteria,
            (data) => {
              console.log('searchCriteria Return', data)
              setCompanyContactCount(data.contactCount)
              setBulkUploadCount(data.bulkUploadCount)
              setPartyTemplateCount(data.partyTemplateCount)
              setTotalCount(data.partyTemplateCount)

              setAlphaFilterEnable(data.namePrefix)
              setSearchCriteriaLoaded(true)
              loadPartyTemplates()
            },
            (error) => {
              console.log(error)
              setPageError(true)
              onError(error)
            }
          )
        }
        break
    }
  }, [refresh])

  // Page Control methods

  const handleTabComponent = (event, value) => {
    setTabIndex(value)
    handleResetFilter()
    refreshPage()
  }

  const onError = (error) => {
    console.log(error)
    showErrorNotification(error.message)
    setLoading(false)
  }

  const switchAlphabet = (e) => () => {
    setNameIndex(e)
    let alphaFilter = alphabetProcessing(e)
    setSearchCriteria({
      ...searchCriteria,
      namePrefix: alphaFilter
    })
    refreshPage()
  }

  const setupSearchCriteria = () => {
    if (categoryCodes == null) {
      searchCriteria.category = initialCategory
    } else {
      searchCriteria.category = categoryCodes
    }

    if (statusCodesField.value == undefined) {
      searchCriteria.statusCode = initialStatus
      searchCriteria.addressStatus = initialAddressStatus
    } else {
      if (statusCodesField.value.indexOf('All') != -1) {
        searchCriteria.addressStatus = ['Active', 'Inactive']
        searchCriteria.statusCode = ['0', '1']
      } else {
        resetStatus()
        parseStatus()
      }
    }
    if (filterText != undefined) {
      searchCriteria.filterText = filterText.toUpperCase()
    }
    searchCriteria.namePrefix = alphabetProcessing(nameIndex)

    if (tabIndex == 2) {
      searchCriteria.populated = false
      searchCriteria.activeOnly = false
    }

    setSearchCriteria(searchCriteria)
    console.log('searchCriteria', searchCriteria)
  }

  const resetStatus = () => {
    searchCriteria.addressStatus = []
    searchCriteria.statusCode = []
  }

  const parseStatus = () => {
    status
      .filter((v) => v.checked)
      .forEach((v) => {
        if (v.code == 'DRAFT') {
          searchCriteria.statusCode.push('0')
        } else {
          if (searchCriteria.statusCode.indexOf('1') < 0) {
            searchCriteria.statusCode.push('1')
          }
        }
        if (v.code == '1') {
          searchCriteria.addressStatus.push('Active')
        }
        if (v.code == '0') {
          searchCriteria.addressStatus.push('Inactive')
        }
      })
    if (
      searchCriteria.statusCode.length == 1 &&
      searchCriteria.statusCode.indexOf('0') >= 0
    ) {
      searchCriteria.addressStatus = ['Active', 'Inactive']
    }
    console.log('statusCode', searchCriteria.statusCode)
    console.log('addressStatus', searchCriteria.addressStatus)
  }

  const loadCompanyContacts = () => {
    setupSearchCriteria()
    fetchPageableRecords.execute(
      CompanyPreferenceApiUrls.COMPANY_CONTACT_SEARCH,
      {
        filters: [],
        sorts: [],
        page: pageNumber,
        pageSize: pageSize,
        customData: searchCriteria
      },
      (data) => {
        console.log(data)
        if (
          companyContactCount == 0 &&
          companyContactCount != data.content.length
        ) {
          refreshPage()
        }
        let contactList = data.content

        setFilteredCount(contactList.length)
        setCompanyContactList(contactList)
        setLoading(false)
      },
      (error) => {
        onError(error)
      }
    )
  }

  function loadBulkUploads() {
    setupSearchCriteria()
    fetchPageableRecords.execute(
      CompanyPreferenceApiUrls.BULK_UPLOAD_SEARCH,
      {
        filters: [],
        sorts: [],
        page: pageNumber,
        pageSize: pageSize,
        customData: searchCriteria
      },
      (data) => {
        console.log(data)
        if (bulkUploadCount == 0 && bulkUploadCount != data.content.length) {
          refreshPage()
        }
        let fileList = data.content
        setFilteredCount(fileList.length)

        setBulkUploadList(fileList)
        setLoading(false)
      },
      (error) => {
        onError(error)
      }
    )
  }

  const loadPartyTemplates = () => {
    setupSearchCriteria()
    fetchPageableRecords.execute(
      CompanyPreferenceApiUrls.PARTY_TEMPLATE_SEARCH,
      {
        filters: [],
        sorts: [],
        page: pageNumber,
        pageSize: pageSize,
        customData: searchCriteria
      },
      (data) => {
        console.log(data)
        if (
          partyTemplateCount == 0 &&
          partyTemplateCount != data.content.length
        ) {
          refreshPage()
        }
        let templateList = data.content

        setFilteredCount(templateList.length)

        setPartyTemplateList(templateList)
        setLoading(false)
      },
      (error) => {
        onError(error)
      }
    )
  }

  // Dialog Methods

  const closeDialog = (reload, openPrevDialog) => {
    console.log('closing dialog')
    setAddContactDialogOpen(false)
    setViewContactDialogOpen(false)
    setConfirmDeleteDialogOpen(false)
    setBulkUploadDialogOpen(false)
    setAddPartyTemplateDialogOpen(false)
    setViewPartyTemplateDialogOpen(false)
    setConfirmDiscardDialogOpen(false)

    if (previousDialog != null && openPrevDialog) {
      openDialog(previousDialog, undefined, selectedObject)
    } else {
      if (reload) {
        console.log('refreshing page')
        refreshPage()
      }
    }
  }

  const openDialog = (dialogType, event, elem) => {
    console.log(dialogType)
    if (event !== undefined) {
      event.stopPropagation()
    }

    setSelectedObject(elem)
    switch (dialogType) {
      case 'addContact':
        setAddContactDialogOpen(true)
        break
      case 'viewContact':
        setSelectedObject(elem)
        setViewContactDialogOpen(true)
        break
      case 'bulkUpload':
        setBulkUploadDialogOpen(true)
        break
      case 'addPartyTemplate':
        setAddPartyTemplateDialogOpen(true)
        break
      case 'viewPartyTemplate':
        setViewPartyTemplateDialogOpen(true)
        break
      case 'deleteContact':
        setSelectedObject(elem)
        setDeleteDialogTitle(translatedTextsObject.deleteContactTitle)
        setDeleteDialogMessage(
          <>
            {translatedTextsObject.deleteContactMsg}
            <br />
            {translatedTextsObject.operationCannotBeUndone}
          </>
        )
        setConfirmDeleteDialogOpen(true)
        break
      case 'deleteBulkUpload':
        setSelectedObject(elem)
        setDeleteDialogTitle(translatedTextsObject.deleteBulkUploadTitle)
        setDeleteDialogMessage(
          <>
            {translatedTextsObject.deleteBulkUploadMsg}
            <br />
            {translatedTextsObject.operationCannotBeUndone}
          </>
        )
        setConfirmDeleteDialogOpen(true)
        break
      case 'discardBulkUpload':
        setDeleteDialogTitle(translatedTextsObject.discardBulkUploadTitle)
        setDeleteDialogMessage(
          <>
            {translatedTextsObject.discardBulkUploadMsg}
            <br />
            {translatedTextsObject.operationCannotBeUndone}
          </>
        )
        setConfirmDiscardDialogOpen(true)
        break
      case 'deletePartyTemplate':
        setSelectedObject(elem)
        setDeleteDialogTitle(translatedTextsObject.deletePartyTemplateTitle)
        setDeleteDialogMessage(
          <>
            {translatedTextsObject.deletePartyTemplateMsg}
            <br />
            {translatedTextsObject.operationCannotBeUndone}
          </>
        )
        setConfirmDeleteDialogOpen(true)
        break
    }
  }

  const closeDiscardDialog = (e) => {
    closeDialog(false, true)
  }

  const confirmDiscardBulkUpload = () => {
    console.log('confirmDiscardBulkUpload')
    setPreviousDialog(null)
    refreshPage()
    closeDialog(false, false)
  }

  const editContact = () => {
    console.log('editContact')
    setAddContactDialogOpen(true)
    setViewContactDialogOpen(false)
    setPreviousDialog(null)
  }

  const editPartyTemplate = () => {
    console.log('editPartyTemplate')
    setAddPartyTemplateDialogOpen(true)
    setViewPartyTemplateDialogOpen(false)
  }

  const confirmDeleteItem = (elem) => {
    console.log('confirmDeleteItem')
    console.log(selectedObject)

    if (selectedObject == undefined) {
      closeDialog(true, false)
      return
    }

    let uriDelete = ''
    let notificationMsg = ''
    switch (tabIndex) {
      case 0:
        notificationMsg = translatedTextsObject.notifyContactDeleted
        if (role == 'SHIPPER') {
          uriDelete = CompanyPreferenceApiUrls.CUSTOM_DELETE_CONTACT
        } else {
          uriDelete = CompanyPreferenceApiUrls.DELETE_CONTACT
        }
        break
      case 1:
        notificationMsg = translatedTextsObject.notifyBulkUploadDeleted
        uriDelete = CompanyPreferenceApiUrls.BULK_UPLOAD_DELETE
        break
      case 2:
        notificationMsg = translatedTextsObject.notifyPartyTemplateDeleted
        uriDelete = CompanyPreferenceApiUrls.PARTY_TEMPLATE_DELETE
        break
    }

    let data = {
      id: selectedObject.id,
      version: selectedObject.version
    }

    deleteRecord.execute(
      uriDelete,
      data,
      confirmDeleteItemSuccess,
      confirmDeleteItemError
    )

    function confirmDeleteItemSuccess(response) {
      console.log(response)
      closeDialog(true, false)
      showSuccessNotification(notificationMsg)
      refreshPage()
    }

    function confirmDeleteItemError(error) {
      console.log(error)
      closeDialog(true, false)
      showErrorNotification('Delete record error - ' + error.message)
    }
  }

  // Filter Methods

  const handleResetFilter = () => {
    setLoading(true)
    // Clear Free Text
    setFilterText('')
    setFilterTextField('')

    // Set All Category Filters
    let newCategory = [...category]
    newCategory = newCategory.map((item) => ({
      ...item,
      checked: true
    }))
    setCategory(newCategory)
    setCategoryField(newCategory)

    //get new status code and list
    let newCategoryCode = getNewCategoryCode(newCategory)

    setCategoryCodes(newCategoryCode)

    // Set All Status Filters
    let newStatus = [...status]
    newStatus = newStatus.map((item) => ({
      ...item,
      checked: true
    }))
    setStatus(newStatus)
    setStatusCodesField(newStatus)

    //get new status code and list
    let newStatusCode = getNewStatusCode(newStatus)

    setStatusCodesField(newStatusCode)

    // Reset Alphanumeric Filter
    setNameIndex(defaultNameIndex)

    // Reset Date Range Filter
    //setDateRange(defaultDateRange)
    setDateRangeField(defaultDateRange)

    refreshPage()
  }

  // useEffect(() => {
  //   if (filterText != undefined) {
  //     if (filterText.length % 4 == 0) {
  //       refreshPage()
  //     }
  //   }
  // }, [filterText])

  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value)
    setFilterTextField(e.target.value)
  }

  const handleFreeTextSearch = () => {
    refreshPage()
  }

  const handleCategoryFilter =
    (index) =>
    ({ target: { checked } }) => {
      setLoading(true)
      let newCategory = [...category]
      const currentCategory = category[index]

      if (currentCategory.desc !== VALUE_ALL) {
        const allCategory = category[category.length - 1]

        newCategory[index] = {
          ...currentCategory,
          checked: !currentCategory.checked
        }
        newCategory[category.length - 1] = { ...allCategory, checked: false }
      } else {
        newCategory = newCategory.map((item) => ({
          ...item,
          checked: !currentCategory.checked
        }))
      }

      setCategory(newCategory)
      setCategoryField(newCategory)

      //get new category code and list
      let newCategoryCode = getNewCategoryCode(newCategory)

      setCategoryCodes(newCategoryCode)

      refreshPage()
    }

  const handleStatusFilter =
    (index) =>
    ({ target: { checked } }) => {
      setLoading(true)
      let newStatus = [...status]

      const currentStatus = status[index]
      if (currentStatus.desc == VALUE_ALL) {
        newStatus = newStatus.map((item) => ({
          ...item,
          checked: !currentStatus.checked
        }))
      } else if (currentStatus.desc == VALUE_DRAFT) {
        newStatus.forEach((item) => {
          if (item.desc == VALUE_DRAFT) {
            item.checked = !item.checked
            console.log(item)
          }
        })
      } else {
        const allStatus = status[status.length - 1]

        newStatus[index] = { ...currentStatus, checked: !currentStatus.checked }
        newStatus[status.length - 1] = { ...allStatus, checked: false }
      }

      setStatus(newStatus)
      setStatusField(newStatus)

      //get new status code and list
      let newStatusCode = getNewStatusCode(newStatus)
      setStatusCodesField(newStatusCode)

      refreshPage()
    }

  const getNewCategoryCode = (newCategory) => {
    let temp = []
    newCategory
      .filter((v) => v.checked)
      .map((v) => {
        temp.push(v.code)
      })
    return temp
  }

  const getNewStatusCode = (newStatus) => {
    let temp = []
    newStatus
      .filter((v) => v.checked)
      .map((v) => {
        temp.push(v.desc.toUpperCase())
      })
    return temp
  }

  const handleDateRangeFilter = (e) => {
    console.log(e.target.defaultValue)
    //setDateRange(e.target.defaultValue)
    setDateRangeField(e.target.defaultValue)

    refreshPage()
  }

  const handleDownloadTemplate = () => {
    securedSendRequest.execute(
      'POST',
      CompanyPreferenceApiUrls.BULK_UPLOAD_DOWNLOAD_TEMPLATE,
      {},
      (data) => {
        console.log(data)
        var blob = data.data
        let url = window.URL.createObjectURL(blob)

        let link = document.createElement('a')
        link.href = url
        link.download = 'ContactUploadTemplate.xlsx'

        link.click()

        showSuccessNotification('Template downloaded.')
      },
      (error) => {
        console.log(error)
        showErrorNotification('Error downloading file - ' + error.message)
      },
      () => {
        console.log('completed')
      },
      { responseType: 'blob' }
    )
  }

  const handleExportContact = () => {
    securedSendRequest.execute(
      'POST',
      CompanyPreferenceApiUrls.COMPANY_CONTACT_EXPORT,
      {
        statusCode: [0, 1],
        category: initialCategory,
        addressStatus: ['Active', 'Inactive'],
        dateRange: 0,
        filterText: '',
        namePrefix: ''
      },
      (data) => {
        console.log(data)
        var blob = data.data
        let url = window.URL.createObjectURL(blob)

        let link = document.createElement('a')
        link.href = url
        link.download = 'Exported-Contacts.xlsx'

        link.click()

        showSuccessNotification('Exported file downloaded')
      },
      (error) => {
        console.log(error)
        showErrorNotification('Error downloading file - ' + error.message)
      },
      () => {
        console.log('completed')
      },
      { responseType: 'blob' }
    )
  }

  const showNoResultPage = () => {
    switch (tabIndex) {
      case 0:
        return companyContactCount == 0 && !loading
      case 1:
        return bulkUploadCount == 0 && companyContactCount == 0 && !loading
      case 2:
        return partyTemplateCount == 0 && companyContactCount == 0 && !loading
    }
  }

  const renderAddressBook = () => {
    return (
      <Grid container ms={12} spacing={2} justify='center'>
        <CngGridItem
          xs={totalCount == 0 ? 12 : 9}
          ms={totalCount == 0 ? 12 : 9}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <CngGridItem xs={12} ms={12}>
                  <AddressBookTabButtonComponent.FormBody
                    switchTabs={handleTabComponent}
                    handleTabComponent={handleTabComponent}
                    tabIndex={tabIndex}
                    loading={loading}
                    companyContactCount={companyContactCount}
                    bulkUploadCount={bulkUploadCount}
                    partyTemplateCount={partyTemplateCount}
                    role={role}
                  />
                  <AddressBookFunctionButtonComponent.FormBody
                    tabIndex={tabIndex}
                    loading={loading}
                    handleOpenDialog={openDialog}
                    handleExportContact={handleExportContact}
                    handleDownloadTemplate={handleDownloadTemplate}
                    totalCount={totalCount}
                    filteredCount={filteredCount}
                  />
                </CngGridItem>
                <CngGridItem xs={12} ms={12}>
                  <Box
                    style={{
                      display:
                        showNoResultPage() && !pageError ? 'inline' : 'none'
                    }}
                  >
                    <AddressBookNoResult
                      title={noResultsTitle().title}
                      desc={noResultsTitle().desc}
                      buttonPanel={switchButtonPanel()}
                    />
                  </Box>
                </CngGridItem>
                <Box
                  style={{
                    display: showNoResultPage() || pageError ? 'none' : 'inline'
                  }}
                >
                  {renderTab()}
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
        {totalCount >= 0 || !loading ? renderFilters() : <></>}
      </Grid>
    )
  }

  const renderTab = () => {
    switch (tabIndex) {
      case 0:
        if (companyContactCount == 0 && !loading) {
          return (
            <AddressBookNoResult
              title={translatedTextsObject.noContactsTitle}
              desc={translatedTextsObject.noContactsDesc}
              buttonPanel={
                <ShipperCompanyContactButtonComponent
                  disableExport={true}
                  handleAddContact={openDialog}
                />
              }
            />
          )
        } else {
          return (
            <Grid container xs={12}>
              <CngGridItem xs={12} ms={12}>
                <AlphanumericFilterComponent.FormBody
                  loading={loading}
                  switchAlphabet={switchAlphabet}
                  enableAlphabet={alphaFilterEnable}
                  nameIndex={nameIndex}
                />
              </CngGridItem>
              <CngGridItem xs={12} ms={12}>
                <CompanyContactTableComponent.FormBody
                  loading={loading}
                  searchCriteria={searchCriteria}
                  contactList={companyContactList}
                  setCurrentCount={setFilteredCount}
                  showNotification={showNotification}
                  handleOpenDialog={openDialog}
                  handleResetFilter={handleResetFilter}
                />
              </CngGridItem>
            </Grid>
          )
        }
      case 1:
        if (bulkUploadCount == 0 && !loading) {
          return (
            <AddressBookNoResult
              title={translatedTextsObject.noBulkUploadTitle}
              desc={translatedTextsObject.noBulkUploadDesc}
              buttonPanel={
                <BulkUploadContactButtonComponent
                  handleUploadBulkTemplate={openDialog}
                  handleDownloadTemplate={handleDownloadTemplate}
                />
              }
            />
          )
        } else {
          return (
            <Grid container xs={12}>
              <CngGridItem xs={12} ms={12}>
                <AlphanumericFilterComponent.FormBody
                  loading={loading}
                  switchAlphabet={switchAlphabet}
                  enableAlphabet={alphaFilterEnable}
                  nameIndex={nameIndex}
                />
              </CngGridItem>
              <CngGridItem xs={12} ms={12}>
                <BulkUploadTableComponent
                  loading={loading}
                  searchCriteria={searchCriteria}
                  showNotification={showNotification}
                  fileList={bulkUploadList}
                  setCurrentCount={setFilteredCount}
                  handleOpenDialog={openDialog}
                  handleResetFilter={handleResetFilter}
                />
              </CngGridItem>
            </Grid>
          )
        }
      case 2:
        if (partyTemplateCount == 0 && !loading) {
          return (
            <AddressBookNoResult
              title={translatedTextsObject.noPartyTemplateTitle}
              desc={translatedTextsObject.noPartyTemplateDesc}
              buttonPanel={
                <PartyTemplateButtonComponent
                  handleAddPartyTemplate={openDialog}
                />
              }
            />
          )
        } else {
          return (
            <Grid container xs={12}>
              <CngGridItem xs={12} ms={12}>
                <AlphanumericFilterComponent.FormBody
                  loading={loading}
                  switchAlphabet={switchAlphabet}
                  enableAlphabet={alphaFilterEnable}
                  nameIndex={nameIndex}
                />
              </CngGridItem>
              <CngGridItem xs={12} ms={12}>
                <PartyTemplateTableComponent
                  loading={loading}
                  searchCriteria={searchCriteria}
                  showNotification={showNotification}
                  templateList={partyTemplateList}
                  setCurrentCount={setFilteredCount}
                  handleOpenDialog={openDialog}
                  handleResetFilter={handleResetFilter}
                />
              </CngGridItem>
            </Grid>
          )
        }
    }
  }

  const renderFilters = () => {
    switch (tabIndex) {
      case 0:
        if (companyContactCount > 0 && !loading) {
          return (
            <CngGridItem sm={3} xs={12}>
              <FreeTextSearchComponent.FormBody
                filterText={filterTextField.value}
                handleInputChange={handleFilterTextChange}
                handleFreeTextSearch={handleFreeTextSearch}
              />
              <CngGridItem xs={12} ms={12}>
                <ContactFilterComponent
                  category={category}
                  status={status}
                  role={role}
                  onCategoryCheckBoxChange={handleCategoryFilter}
                  onStatusCheckBoxChange={handleStatusFilter}
                  handleResetFilter={handleResetFilter}
                />
              </CngGridItem>
            </CngGridItem>
          )
        }
        break
      case 1:
        if (bulkUploadCount > 0 && !loading) {
          return (
            <CngGridItem sm={3} xs={12}>
              <FreeTextSearchComponent.FormBody
                filterText={filterTextField.value}
                handleInputChange={handleFilterTextChange}
                handleFreeTextSearch={handleFreeTextSearch}
              />
              <CngGridItem xs={12} ms={12}>
                <DateRangeFilterPanelComponent
                  dateTitle={translatedTextsObject.dateFilterTitle}
                  dateRangeOptions={dateRangeOptions}
                  dateRange={dateRangeField.value}
                  dateRangeChange={handleDateRangeFilter}
                  handleResetFilter={handleResetFilter}
                />
              </CngGridItem>
            </CngGridItem>
          )
        }
        break
      case 2:
        if (partyTemplateCount > 0 && !loading) {
          return (
            <CngGridItem sm={3} xs={12}>
              <FreeTextSearchComponent.FormBody
                filterText={filterTextField.value}
                handleInputChange={handleFilterTextChange}
                handleFreeTextSearch={handleFreeTextSearch}
              />
              <CngGridItem xs={12} ms={12}>
                <DateRangeFilterPanelComponent
                  dateTitle={translatedTextsObject.dateFilterTitle}
                  dateRangeOptions={dateRangeOptions}
                  dateRange={dateRangeField.value}
                  dateRangeChange={handleDateRangeFilter}
                  handleResetFilter={handleResetFilter}
                />
              </CngGridItem>
            </CngGridItem>
          )
        }
        break
    }
  }

  const noResultsTitle = () => {
    switch (tabIndex) {
      case 0:
        return {
          title: translatedTextsObject.noContactsTitle,
          desc: translatedTextsObject.noContactsDesc
        }

      case 1:
        return {
          title: translatedTextsObject.noBulkUploadTitle,
          desc: translatedTextsObject.noBulkUploadDesc
        }
      case 2:
        return {
          title: translatedTextsObject.noPartyTemplateTitle,
          desc: translatedTextsObject.noPartyTemplateDesc
        }
    }
  }

  const switchButtonPanel = () => {
    switch (tabIndex) {
      case 0:
        return (
          <ShipperCompanyContactButtonComponent
            disableExport={true}
            handleAddContact={openDialog}
          />
        )
      case 1:
        return (
          <BulkUploadContactButtonComponent
            handleUploadBulkTemplate={openDialog}
            handleDownloadTemplate={handleDownloadTemplate}
          />
        )
      case 2:
        return (
          <PartyTemplateButtonComponent handleAddPartyTemplate={openDialog} />
        )
    }
  }

  return (
    <Card className='page-content'>
      <Box
        style={{
          display: pageError ? 'inline' : 'none'
        }}
      >
        <AddressBookPageError refreshAction={refreshPage} />
      </Box>
      <Box style={{ display: pageError ? 'none' : 'inline' }}>
        {renderAddressBook()}
      </Box>

      <EditContactDetailsDialog
        isDialogOpen={addContactDialogOpen}
        showNotification={showNotification}
        currentContact={selectedObject}
        setPreviousDialog={setPreviousDialog}
        closeDialog={closeDialog}
        role={role}
        partyId={partyId}
      />

      <ViewContactDetailsDialog
        isDialogOpen={viewContactDialogOpen}
        showNotification={showNotification}
        currentContact={selectedObject}
        setPreviousDialog={setPreviousDialog}
        closeDialog={closeDialog}
        editContact={editContact}
        openDialog={openDialog}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDeleteDialogOpen}
        closeDialog={() => {
          closeDialog(false, true)
          setPreviousDialog(null)
        }}
        confirmDialog={confirmDeleteItem}
        content={deleteDialogMessage}
        title={deleteDialogTitle}
        okMsg={calistaUiTranslatedTextsObject.yesDelete}
        cancelMsg={calistaUiTranslatedTextsObject.noTakeMeBack}
      />

      <ConfirmDialog
        isConfirmDialogOpen={confirmDiscardDialogOpen}
        closeDialog={closeDiscardDialog}
        confirmDialog={confirmDiscardBulkUpload}
        content={translatedTextsObject.discardBulkUploadMsg}
        title={translatedTextsObject.discardBulkUploadTitle}
        okMsg={calistaUiTranslatedTextsObject.yesDiscard}
        cancelMsg={calistaUiTranslatedTextsObject.noTakeMeBack}
      />

      <BulkUploadDialog
        isDialogOpen={bulkUploadDialogOpen}
        showNotification={showNotification}
        openDialog={openDialog}
        closeDialog={closeDialog}
        setPreviousDialog={setPreviousDialog}
        role={role}
        partyId={partyId}
      />
      <AddPartyTemplateDialog
        isDialogOpen={addPartyTemplateDialogOpen}
        showNotification={showNotification}
        currentTemplate={selectedObject}
        closeDialog={closeDialog}
        setPreviousDialog={setPreviousDialog}
        partyId={partyId}
      />
      <ViewPartyTemplateDialog
        isDialogOpen={viewPartyTemplateDialogOpen}
        showNotification={showNotification}
        currentTemplate={selectedObject}
        closeDialog={() => {
          closeDialog(false, false)
          setPreviousDialog(null)
        }}
        editPartyTemplate={editPartyTemplate}
        openDialog={openDialog}
        setPreviousDialog={setPreviousDialog}
        role={role}
        partyId={partyId}
      />
    </Card>
  )
}

const AddressBookNewForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default AddressBookNewForm
