import React, { useState } from 'react'
import { Paper, Box } from '@material-ui/core'

import { components } from 'cng-web-lib'

import EditContactDetailsContext from './EditContactDetailsContext'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import CompanyContactSelectionForm from './CompanyContactSelectionForm'

import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

const { CngDialog } = components

function ListCompanyContactDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  contactType,
  handleDialogSubmit,
  handleAddContact,
  refresh
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = CompanyPreferenceTranslationText()

  return (
    <EditContactDetailsContext.Provider
      value={{
        closeDialog,
        showNotification,
        handleDialogSubmit,
        contactType,
        handleAddContact,
        refresh,
        form: {
          isSubmitting,
          setSubmitting
        }
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <Box p={3}>
              <CompanyContactSelectionForm
                contactType={contactType}
                handleDialogSubmit={handleDialogSubmit}
                handleAddContact={handleAddContact}
                refresh={refresh}
              />
            </Box>
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.addressBook}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </EditContactDetailsContext.Provider>
  )
}

export default ListCompanyContactDialog
