import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import {
    Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, Grid
} from '@material-ui/core';
import { Edit, Trash } from 'react-feather';
import moment from 'moment';

import { useServices, components } from 'cng-web-lib';
import Utils from 'src/views/common/utils/Utils';

import CompanyPreferenceTranslationText from '../../../shared/CompanyPreferenceTranslationText';
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText';
import CompanyPreferenceApiUrls from '../../../../../apiUrls/CompanyPreferenceApiUrls';

import AddressBookDialog from '../../../shared/dialog';

import FilterNoResult from '../filters/FilterNoResult';
import FilterClearButtonComponent from '../filters/FilterClearButtonComponent';

const {
    table: { useDefaultNotification },
} = components;

function PartyTemplateTableComponent(props) {
    const observer = useRef()
    const translatedTextsObject = CompanyPreferenceTranslationText();
    const calistaUiTextObject = CalistaUiComponentTranslationText()

    const {
        error: showErrorNotification
    } = useDefaultNotification(props.showNotification);

    const { fetchPageableRecords } = useServices();

    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(0);

    const timeDateFormat = 'DD MMM YYYY HH:mm';

    const [templateList, setTemplateList] = useState([]);
    let searchCriteria = props.searchCriteria;

    useEffect(() => {
        setLoading(true);
        console.log('props.templateList', props.templateList);
        let temp = [...props.templateList];
        if (temp.length < pageSize) {
            setHasMore(false);
        }
        else {
            setHasMore(true);
        }
        setPageNumber(0);
        props.setCurrentCount(props.templateList.length);
        setTemplateList(props.templateList);
        setLoading(false);
    }, [props.templateList]);

    function loadMoreTemplates(page) {
        console.log('loadMoreTemplates', page)

        fetchPageableRecords.execute(
            CompanyPreferenceApiUrls.PARTY_TEMPLATE_SEARCH,
            {
                filters: [],
                sorts: [],
                page: pageNumber,
                pageSize: pageSize,
                customData: searchCriteria

            },
            (data) => {
                console.log("Loading More Templates");
                if (data.content.length < pageNumber) {
                    setHasMore(false);
                }
                else {
                    setHasMore(true);
                }

                let temp3 = [...templateList];
                let temp4 = temp3.concat(data.content);

                props.setCurrentCount(temp4.length);
                setTemplateList(temp4);
                setLoading(false);
            },
            (error) => {
                showErrorNotification(error);
            }
        )
    }

    const lastContactElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setLoading(true);
                setPageNumber(pageNumber + 1);
                loadMoreTemplates(pageNumber + 1);
            }
        })
        if (node) observer.current.observe(node)
    },
        [loading, hasMore]
    );

    function loadRows() {
        if (props.loading) {
            return (
                <TableRow>
                    <TableCell colspan={3}>
                        <object
                            data={process.env.PUBLIC_URL + '/static/images/shippingInstruction/Loading_RowTextSection.svg'}
                            alt='Tabs Loading...'
                        />
                    </TableCell>
                </TableRow>
            );
        }
        else {
            return (
                templateList.map(function (c, index) {
                    let createdDate = moment(c.creationDate);
                    createdDate = createdDate.format(timeDateFormat);
                    return (
                        <TableRow
                            key={index}
                            hover
                            onClick={(e) => props.handleOpenDialog(AddressBookDialog.VIEW_PARTY_TEMPLATE_DIALOG, e, c)}
                        >
                            <TableCell>
                                <div key={index}
                                    ref={lastContactElementRef}>
                                    {Utils.trimString(c.tmpltName,50)}</div>
                            </TableCell>
                            <TableCell>
                                {createdDate}
                            </TableCell>
                            <TableCell>
                                <Tooltip title='Delete' placement='top' arrow>
                                    <IconButton color={'default'}
                                        onClick={(e) => props.handleOpenDialog(AddressBookDialog.DELETE_PARTY_TEMPLATE_CONFIRM, e, c)}>
                                        <Trash size='16px' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Manage' placement='top' arrow>
                                    <IconButton color={'default'}
                                        onClick={(e) => props.handleOpenDialog(AddressBookDialog.ADD_PARTY_TEMPLATE_DIALOG, e, c)}>
                                        <Edit size='16px' />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    )
                })
            );
        }
    }

    if (!props.loading && (templateList == undefined || templateList.length == 0)) {
        return (
            <FilterNoResult
                title={translatedTextsObject.noResultsFilterTitle}
                desc={translatedTextsObject.noResultsFilterDesc}
                buttonPanel={
                    <FilterClearButtonComponent
                        handleResetFilter={props.handleResetFilter}
                    />
                }
            />
        );

    }
    else {
        return (
            <Card>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#F5F5FA65' }}>
                                <TableCell className={'vs-table-cell-padding'}>
                                    <div className={'rs-grey'}>
                                        {calistaUiTextObject.templateName.toUpperCase()}
                                    </div>
                                </TableCell>
                                <TableCell className={'vs-table-cell-padding'}>
                                    <div className={'rs-grey'}>
                                        {calistaUiTextObject.creationDate.toUpperCase()}
                                    </div>
                                </TableCell>
                                <TableCell className={'vs-table-cell-padding'}>
                                    <div className={'rs-grey'}>
                                        {calistaUiTextObject.action.toUpperCase()}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loadRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        )
    }


}

export default PartyTemplateTableComponent