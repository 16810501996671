import { Card, CardContent, Grid } from '@material-ui/core'
import React from 'react'
import FilterHeaderComponent from './FilterHeaderComponent';
import CategoryFilterComponent from './CategoryFilterComponent'
import ContactStatusFilterComponent from './ContactStatusFilterComponent'

function ContactFilterComponent(props) {
  return (
    <Grid md={12}>
      <Card>
        <CardContent>
          <FilterHeaderComponent
            handleClearAllButtonClick={props.handleResetFilter}
          />
          {props.role =='SHIPPER' ? 
          <CategoryFilterComponent
            category={props.category}
            onCheckBoxChange={props.onCategoryCheckBoxChange}
            fieldName="categoryCode"
          />
          : <> </> }
          <ContactStatusFilterComponent
            status={props.status}
            onCheckBoxChange={props.onStatusCheckBoxChange}
            fieldName="statusCode"
          />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ContactFilterComponent
