import { Box, Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import ShipperCompanyContactButtonComponent from './addressbook/ShipperCompanyContactButtonComponent';
import BulkUploadContactButtonComponent from './bulkupload/BulkUploadContactButtonComponent';
import PartyTemplateButtonComponent from './partytemplate/PartyTemplateButtonComponent';

const {
  CngGridItem,
} = components

const FormBody = (props) => {

  function buttonSwitcher() {
    if (props.loading) {
      return (
        <Grid container xs={12} sm={12} justify='flex-end'>
          {props.tabIndex <= 1 ?
            <Box pr={2}>
              <object
                style={{ height: '2.5em' }}
                data={process.env.PUBLIC_URL + '/static/images/shippingInstruction/Loading_ExportBtn.svg'}
                alt='Button Loading...'
              />
            </Box>
            : <Box></Box>}
          <Box pr={2}>
            <object
              style={{ height: '2.5em' }}
              data={process.env.PUBLIC_URL + '/static/images/shippingInstruction/Loading_AddNewBtn.svg'}
              alt='Button Loading...'
            />
          </Box>
        </Grid>
      );
    }
    else {
      if (props.totalCount === 0) {
        return (
          <Grid container xs={12} sm={12} justify='flex-end'>

          </Grid>
        );
      }
      else {
          switch (props.tabIndex) {
            case 0:
              return (
                <ShipperCompanyContactButtonComponent
                  disableExport={false}
                  handleAddContact={props.handleOpenDialog}
                  handleExportContact={props.handleExportContact}
                />
              );
            case 1:
              return (
                <BulkUploadContactButtonComponent
                  handleDownloadTemplate={props.handleDownloadTemplate}
                  handleUploadBulkTemplate={props.handleOpenDialog}
                />
              );
            case 2:
              return (
                <PartyTemplateButtonComponent
                  handleAddPartyTemplate={props.handleOpenDialog}
                  handleTestDialog={props.handleOpenDialog}
                />
              );
          }
      }
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem item xs={4} sm={4} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        {props.totalCount > 0 ?
          <ShowCountComponent
            loading={props.loading}
            shownCount={props.filteredCount}
            totalCount={props.totalCount}
          />
          : <br />}
      </CngGridItem>
      <CngGridItem item xs={8} sm={8}>
        {buttonSwitcher()}
      </CngGridItem>
    </Grid>
  )
}

function ShowCountComponent(props) {
  if (props.loading) {
    return (
      <Box>

      </Box>
    );
  }
  else {
    return (
      <Box>
        Showing {props.shownCount} out of {props.totalCount} results
      </Box>
    );
  }
}

const AddressBookFunctionButtonComponent = Object.freeze({
  FormBody: FormBody
})

export default AddressBookFunctionButtonComponent
