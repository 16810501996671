import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import EditContactDetailsContext from './EditContactDetailsContext.js'
import ViewContactForm from './ViewContactForm.js'
import { components } from 'cng-web-lib'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

const { CngDialog } = components

function ViewContactDetailsDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  currentContact,
  editContact,
  openDialog,
  setPreviousDialog
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = CompanyPreferenceTranslationText()

  return (
    <EditContactDetailsContext.Provider
      value={{
        closeDialog,
        showNotification,
        currentContact,
        editContact,
        openDialog,
        setPreviousDialog,
        form: {
          isSubmitting,
          setSubmitting
        }
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow: 'hidden' }}>
              <ViewContactForm
                openDialog={openDialog}
                editContact={editContact}
                setPreviousDialog={setPreviousDialog}
              />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.viewContact}</b>
            <CloseDialogIconButton
              onClick={() => {
                setPreviousDialog(null)
                closeDialog(false)
              }}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </EditContactDetailsContext.Provider>
  )
}

export default ViewContactDetailsDialog
