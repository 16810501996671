import {
    Card,
    Grid,
    CardContent
} from '@material-ui/core'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { components } from 'cng-web-lib'

const {
    form: {
        field: {
            CngTextField
        },
    },
    CngGridItem,
    button: { CngPrimaryButton },
} = components



const FormBody = (props) => {
    const { helpdesk } = props
    const placeHolder = "Search"
    const HelpdeskPlaceHolder = "Search for reference #, companies, etc"

    return (
        <Card>
            <CardContent>
                {helpdesk ?
                    <Grid container spacing={1}>
                        <CngGridItem item xs={11} sm={10}>
                            <CngTextField
                                type='text'
                                name='filterText'
                                value={props.filterText}
                                placeholder={HelpdeskPlaceHolder}
                                onChange={e => props.handleInputChange(e)}
                            />
                        </CngGridItem>

                        <CngGridItem style={{
                            borderWidth: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 65,
                            height: 65,
                            borderRadius: 50,
                        }}>
                            <CngPrimaryButton
                                fontSize='50'
                                name='search'
                                // type='submit'
                                startIcon={<SearchIcon />}
                                className={'search-button'}
                                onClick={props.handleFreeTextSearch}
                            >
                            </CngPrimaryButton>
                        </CngGridItem>
                    </Grid>

                    :

                    <Grid container spacing={1}>
                        <CngGridItem item xs={9} sm={9}>
                            <CngTextField
                                type='text'
                                name='filterText'
                                value={props.filterText}
                                placeholder={placeHolder}
                                //inputProps={{ placeHolder: placeHolder }}
                                onKeyUp={e => { if (e.keyCode == 13) { props.handleFreeTextSearch(); } }}
                                onChange={e => props.handleInputChange(e)} />

                        </CngGridItem>
                        <CngGridItem item xs={2} sm={2}>
                            <CngPrimaryButton
                                name='search'
                                // type='submit'
                                startIcon={<SearchIcon />}
                                className={'search-button'}
                                onClick={props.handleFreeTextSearch}
                            >

                            </CngPrimaryButton>
                        </CngGridItem>
                    </Grid>
                }
            </CardContent>
        </Card >
    )
}

const FreeTextSearchComponent = Object.freeze({
    FormBody: FormBody,

})

export default FreeTextSearchComponent