import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography
} from '@material-ui/core'

import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

const {
    form: {
        field: {
            CngTextField,
            CngSelectField,
            CngDateField,
            CngCheckboxField
        },
    },
    CngGridItem,
} = components

const {
    CngPackageUomAutocompleteField,
} = components.form.field


const StatusCheckboxGroup = (props) => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        setOptions(props.checkBoxList)
    })
    return (
        <Grid item sm={12}>
            <FormControl component='fieldset'>
                <FormGroup row={true}>
                    {options
                        .filter((option) => option.desc === 'ALL')
                        // .slice(0)
                        .map((option, index) => {
                            return (
                                <CngGridItem xs={12} sm={6}>
                                    <FormControlLabel
                                        key={options.length - 1}
                                        control={
                                            <CngCheckboxField
                                                key={options.length - 1}
                                                checked={
                                                    options.filter((option) => option.checked === true)
                                                        .length >= (options.length - 1)
                                                }
                                                indeterminate={
                                                    options.filter((option) => option.checked === true)
                                                        .length > 0 &&
                                                    options.filter((option) => option.checked === true)
                                                        .length < (options.length - 1)
                                                }
                                                onClick={props.onChange(options.length - 1)}
                                                inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                                            />
                                        }
                                        label={`All ${props.filterName} `}
                                    />
                                </CngGridItem>);
                        }
                        )}
                    {options
                        .filter((option) => option.desc !== 'ALL')
                        .map((option, index) => {
                            return (
                                <CngGridItem xs={6} sm={6}>
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <CngCheckboxField
                                                value={option.code}
                                                name={`${props.fieldName}[${index}]`}

                                                checked={option.checked}
                                                onClick={props.onChange(index)}
                                            />
                                        }

                                        label={`${option.desc}`}

                                    />
                                </CngGridItem>
                            )
                        })}
                </FormGroup>
            </FormControl>
        </Grid>
    )
}


export default StatusCheckboxGroup