import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import Paper from '@material-ui/core/Paper'

import EditPartyTemplateContext from './EditPartyTemplateContext.js'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import AddPartyTemplateForm from './AddPartyTemplateForm'

import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

const {
  CngDialog
} = components

function AddPartyTemplateDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  currentTemplate,
  setPreviousDialog,
  partyId
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = CompanyPreferenceTranslationText()

  return (
    <EditPartyTemplateContext.Provider
      value={{
        closeDialog,
        showNotification,
        currentTemplate,
        setPreviousDialog,
        form: {
          isSubmitting,
          setSubmitting
        }
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow: 'hidden' }}>
              <AddPartyTemplateForm
                partyId={partyId}
                setPreviousDialog={setPreviousDialog}
              />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <b>
              {currentTemplate
                ? translatedTextsObject.manageTemplate
                : translatedTextsObject.addNewTemplate}
            </b>
            <CloseDialogIconButton
              onClick={() => {
                closeDialog(false, false)
                setPreviousDialog(null)
              }}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </EditPartyTemplateContext.Provider>
  )
}

export default AddPartyTemplateDialog
