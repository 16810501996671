import { Grid, Tabs, Tab } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

const {
  CngGridItem,
} = components

const FormBody = (props) => {
  const translatedTextsObject = CompanyPreferenceTranslationText();
  let tabInfo = [
    {
      tabText: translatedTextsObject.companyContact,
      tabCount: props.companyContactCount,
      tabClicked: props.tabIndex == 0,
    },
    {
      tabText: translatedTextsObject.bulkUploadContact,
      tabCount: props.bulkUploadCount,
      tabClicked: props.tabIndex == 1,
    },
    {
      tabText: translatedTextsObject.partyTemplate,
      tabCount: props.partyTemplateCount,
      tabClicked: props.tabIndex == 2,
    }
  ];

  function capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function a11yProps(index) {
    return {
      id: `address-book-tab-${index}`,
      "aria-controls": `address-book-tabpanel-${index}`
    }
  }

  if (props.role == 'CARRIER') {
    tabInfo = tabInfo.filter((e) => e.tabText != translatedTextsObject.partyTemplate)
  }

  function tabsLoading() {
    if (props.loading) {
      return (
        <object
          style={{ height: '2.5em' }}
          data={process.env.PUBLIC_URL + '/static/images/shippingInstruction/Loading_UpperTabsSection.svg'}
          alt='Button Loading...'
        />
      )
    }
    else {
      return (
        <Tabs value={props.tabIndex} onChange={props.handleTabComponent} indicatorColor='primary'>
          {tabInfo.map((elem, i) => {
            let title = capitalizeString(elem.tabText + " (" + elem.tabCount + ")");
            return (
              <Tab label={title} {...a11yProps(i)} style={{ textTransform: 'capitalize' }} />
            );
          })
          }
        </Tabs>
      )
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem item xs={12} sm={12}>
        {tabsLoading()}
      </CngGridItem>
    </Grid>
  );
}

const AddressBookTabButtonComponent = Object.freeze({
  FormBody: FormBody
})

export default AddressBookTabButtonComponent
