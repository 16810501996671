
import React from 'react'
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib';
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText';
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText';
import StatusCheckboxGroup from './StatusCheckboxGroup';


const ContactStatusFilterComponent = (props) => {
  const translatedTextsObject = CompanyPreferenceTranslationText();
  const calistaUiTextObject = CalistaUiComponentTranslationText();

  const {
    CngGridItem
  } = components;

  return (
    <Grid container='true' container item className={'rs-filter-card'}>
      <Grid className={'rs-bg-grey'} item md={12}>
          <CngGridItem md={6}>
            <Typography className={'rs-filter-subheader'}>
              {calistaUiTextObject.status}
            </Typography>
          </CngGridItem>
        </Grid>

      <StatusCheckboxGroup
        checkBoxList={props.status}
        onChange={props.onCheckBoxChange}
        filterName={""}
        fieldName={props.fieldName}
      />
    </Grid>
  )
}

export default ContactStatusFilterComponent
